import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { FileCopy } from '@material-ui/icons';

import Button from '../Button/Button';

const useStylesCustomTooltip = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: "12px",
    fontWeight: "400",
    letterSpacing: "1px",
    maxWidth: 'none',
  },
}));

export default function RegularTooltip(props) {
  const classes = useStylesCustomTooltip();
  const tProps = {...props, interactive: true, title: (props.copy && props.copy=== true) ? 
    <>
      <Button simple color="transparent" justIcon round onClick={() => {navigator.clipboard.writeText(props.title)}}>
        <FileCopy />
      </Button>{props.title}
    </>
    : props.title};
  return (<Tooltip TransitionComponent={Zoom} arrow classes={classes} {...tProps} />);
}

RegularTooltip.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.bool
};
