import React, { Component } from 'react'
import moment from 'moment';
import { withRouter } from "react-router-dom";
import _ from 'lodash';

import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '../../../components/Custom/Button/Button';
import Typography from '@mui/material/Typography';
import InvoiceGenerateDetails from './InvoiceGenerateDetails';
import InvoiceGenerateProduct from './InvoiceGenerateProduct';
import InvoiceGenerateCustomerDetails from './InvoiceGenerateCustomerDetails';
import InvoiceGenerateConfigurationDetails from './InvoiceGenerateConfigurationDetails';

import { showToast, Status } from "../../ApiService/ApiService";
import ApiService from '../../ApiService/ApiService';
import {
	INVOICE_GET_BY_ID, INVOICE_CONFIG_PRODUCT_GET_ALL, 
	INVOICE_CONFIG_UNIT_GET_ALL, INVOICE_CONFIG_GET, INVOICE_ADD
} from '../../ApiService/URLs';
import ObjectUtils from '../../../util/objectUtil';
import InvoiceGenerateReview from './InvoiceGenerateReview';

const steps = ['Invoice Details', 'Customer Details', 'Invoice Configuration', 'Review & Submit' ];

const newInvoice = {
	terminals: [],
	totalAmount: "0.0",
	taxAmount: "0.0",
	currency: "",
	description: "",
	customerName: "",
	customerEmail: "",
	customerAddress: "",
	customerPhone: "",
	customerCountry: "",
	customerCity: "",
	customerState: "",
	dueDate: "",
	dueDateAmount: "0.0",
	products: []	
};

class InvoiceGenerate extends Component {
	constructor(props) {
        super(props);
		//console.log("props", props);
		const propsInvoice = props.location && props.location.state ? props.location.state.invoice || {} : {};
		const editMode = props.location && props.location.state ? props.location.state.editMode : false;
		this.state = {
  			activeStep: 0,
  			completed: {},
			loading: editMode, 
			// invoice: propsInvoice || {...newInvoice},
			invoice: {...newInvoice},
			refData: {
				terminalList: [],
				productList: [],
				unitList: [],
				invoiceConfig: []
			}
		};
		
		this.completedSteps = this.completedSteps.bind(this);
		this.isLastStep = this.isLastStep.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.handleStep = this.handleStep.bind(this);
		this.handleComplete = this.handleComplete.bind(this);
		this.handleReset = this.handleReset.bind(this);		
		this.onFieldChange = this.onFieldChange.bind(this);
	}
	
	componentDidMount(){
		this.getTerminal();
		this.getProducts();
		this.getUnits();
		this.getInvoiceConfig();
		this.getInvoice();
	}

	getInvoice() {
		// console.log("InvoiceGenerate Invoice ", this.state.invoice.id);
		if (this.state.invoice.id != null) {
			// console.log("InvoiceGenerate Invoice ", this.state.invoice.id);
			ApiService.postAPI(INVOICE_GET_BY_ID, {id: this.state.invoice.id}).then((res)=> {
				if (res != null) {
					const invoice = res.data;
					if (invoice.products && invoice.products.length > 0) {
						invoice.products.map(item => {
							delete item.product;
							delete item.unit;
						})
					}
					// console.log("getInvoice: ", invoice);
					this.setState({loading: false, invoice: invoice})
				}
			});
		}
	}

	getTerminal() {
		ApiService.fetchMerchantTerminals().then((res) => {
			if (res != null) {	
				const newRefData = {...this.state.refData};
				newRefData.terminalList = res.data;
				this.setState({refData: newRefData});
			}
		});
	}

	getProducts() {
		ApiService.getAPI(INVOICE_CONFIG_PRODUCT_GET_ALL, null, false).then((res)=> {
			if (res != null) {
				const newRefData = {...this.state.refData};
				newRefData.productList = res.data;
				this.setState({refData: newRefData});
			}
		});
	}

	getUnits() {
		ApiService.getAPI(INVOICE_CONFIG_UNIT_GET_ALL, null, false).then((res)=> {
			if (res != null) { 
				const newRefData = {...this.state.refData};
				newRefData.unitList = res.data;
				this.setState({refData: newRefData});
			}
		});
	}

	getInvoiceConfig() {
		ApiService.getAPI(INVOICE_CONFIG_GET, null, false).then((res)=> {
			if (res != null) { 
				const newRefData = {...this.state.refData};
				newRefData.invoiceConfig = res.data;
				this.setState({refData: newRefData});
				if (newRefData.invoiceConfig && newRefData.invoiceConfig.dueDays) {
					const dueDays = "" + newRefData.invoiceConfig.dueDays;
					if (ObjectUtils.isNumber(dueDays))
						this.onFieldChange("dueDate", 
							moment(new Date()).add(ObjectUtils.NumVal(dueDays), 'days').format('YYYY-MM-DDTHH:mm:ss'));
				}
			}
		});
	}

	onSubmit() {
		const invoice = this.state.invoice;
		let errorMessage = null;

		if (ObjectUtils.isNullorEmpty(invoice.description)) errorMessage = "Description is required";
		else if (ObjectUtils.isNullorEmpty(invoice.currency)) errorMessage = "Currency is required";
		else if (invoice.terminals == null || invoice.terminals.length == 0) errorMessage = "Terminal is required";
		else if (ObjectUtils.isNullorEmpty(invoice.customerName)) errorMessage = "Customer Name is required";
		else if (ObjectUtils.isNullorEmpty(invoice.customerEmail)) errorMessage = "Customer Email is required";
		else if (ObjectUtils.isNullorEmpty(invoice.customerAddress)) errorMessage = "Customer Address is required";
		else if (ObjectUtils.isNullorEmpty(invoice.customerPhone)) errorMessage = "Customer Phone is required";
		else if (ObjectUtils.isNullorEmpty(invoice.customerCountry)) errorMessage = "Customer Country is required";
		else if (ObjectUtils.isNullorEmpty(invoice.customerState)) errorMessage = "Customer State is required";
		else if (ObjectUtils.isNullorEmpty(invoice.customerCity)) errorMessage = "Customer City is required";
		else if (!invoice.products || invoice.products.length === 0) errorMessage = "Atleast one product is required";
		else if (invoice.products) {
			invoice.products.map((product, idx) => {
				const validObj = this.validateProduct(idx, product);
				if (validObj != null)	{ errorMessage = validObj.helperText; return null; }
			});
		}
		else if (ObjectUtils.isNullorEmpty(invoice.totalAmount)) errorMessage = "Invoice Amount is required";
		else if (!ObjectUtils.isNumber(invoice.totalAmount)) errorMessage = "Invalid Invoice Amount";

		if (errorMessage !== null) {
			showToast({ type: Status.ERROR, title: "Error", description: errorMessage });
			return;
		}

		//console.log("onSubmit", this.state.invoice);
		ApiService.postAPI(INVOICE_ADD, this.state.invoice).then((res)=> {
			if (res != null) {
				showToast({ type: Status.SUCCESS, title: "Status", description: "Invoice generated successfully!" }); 
				this.props.history.push('/app/invoice-history');
			}
		})
	}

	validateProduct (fieldName, product)	{		
		if (ObjectUtils.isNullorEmpty(product.merchantProductsId))
			return { ['COMP' + fieldName] : { merchantProductsId: { helperType: Status.ERROR, helperText: "Product is required"} } };
		else if (ObjectUtils.isNullorEmpty(product.unitId))
			return { ['COMP' + fieldName] : { unitId: { helperType: Status.ERROR, helperText: "Unit is required"} } };
		else if (ObjectUtils.isNullorEmpty(product.totalAmount))
			return { ['COMP' + fieldName] : { totalAmount: { helperType: Status.ERROR, helperText: "Amount is required"} } };
		else if (!ObjectUtils.isNumber(product.totalAmount))
			return { ['COMP' + fieldName] : { totalAmount: { helperType: Status.ERROR, helperText: "Invalid Amount value"} } };
		else if (ObjectUtils.isNullorEmpty(product.quantity))
			return { ['COMP' + fieldName] : { quantity: { helperType: Status.ERROR, helperText: "Quantity is required"} } };
		else if (!ObjectUtils.isNumber(product.quantity))
			return { ['COMP' + fieldName] : { quantity: { helperType: Status.ERROR, helperText: "Invalid Quantity value"} } };
		else if (!ObjectUtils.isNullorEmpty(product.tax) && !ObjectUtils.isNumber(product.tax))
			return { ['COMP' + fieldName] : { tax: { helperType: Status.ERROR, helperText: "Invalid Tax value"} } };
		else return null;
	}

  	totalSteps () { return steps.length; };
	completedSteps () { return Object.keys(this.state.completed).length; };
  	isLastStep () { return this.state.activeStep === this.totalSteps() - 1; };
  	allStepsCompleted () { return this.completedSteps() === this.totalSteps(); };
	handleNext () {
		//console.log(_.findIndex(this.state.completed, (c) => { return c === false; }, 0));
		const newActiveStep =
      	this.isLastStep() && !this.allStepsCompleted()
        	? // Find non-completed Step if on last step
			_.findIndex(this.state.completed, (c) => { return c === false; }, 0)
        	: this.state.activeStep + 1;
			this.setState({ activeStep: newActiveStep});
  	};

  	handleBack () { this.setState({ activeStep: this.state.activeStep - 1}); };
  	handleStep (step) { this.setState({ activeStep: step}); }
	handleComplete () {
    	const newCompleted = {...this.state.completed};
    	newCompleted[this.state.activeStep] = true;
		this.setState({ completed: newCompleted });
    	this.handleNext();
  	};

  	handleReset () {
		this.setState({ activeStep: 0, completed : {}});
  	};

	onFieldListChange(fieldValue) {
		//console.log("fieldValue", fieldValue);
		const invoice = this.state.invoice;
		Object.keys(fieldValue).forEach(field => invoice[field] = fieldValue[field]);
		//console.log("Invoice Change ", invoice);
		this.setState({invoice}, () => {
			//console.log(this.state.invoice, '<----- invoice');
		  });
	}

	onFieldChange(field, value) {
		//console.log("field, value", field, value);
		const invoice = this.state.invoice;
		invoice[field] = value;
		//console.log("Invoice Change ", newInvoice);
		this.setState({invoice});
	}

	onTerminalChange(field, value) {
		//console.log("field, value", field, value);
		const invoice = this.state.invoice;
		invoice[field] = typeof value === 'string' ? value.split(',') : value;
		//console.log("Invoice Change ", newInvoice);
		this.setState({invoice});
	}

	onProductChange(index, product) {
		var newInvoice = {...this.state.invoice};
		newInvoice.products[index] = product;
		newInvoice.products.splice(index, 1, newInvoice.products[index]);
		// console.log("onProductChange: ", newInvoice);
		this.setState({invoice: newInvoice});
	}

	onAddProduct (product) {
		const newInvoice = {...this.state.invoice};
		if (newInvoice.products == null) newInvoice.products = [];
		newInvoice.products.push(product);
		// console.log("onAddProduct: ", newInvoice);
		this.setState({invoice: newInvoice});
	}

	onDeleteProduct (index) {
		const newInvoice = {...this.state.invoice};
		if (newInvoice.products == null) newInvoice.products = [];
		newInvoice.products.splice(index, 1);
		//console.log("Invoice Change ", newInvoice);
		this.setState({invoice: newInvoice});		
	}

	render() {
		const {loading, invoice, refData, activeStep, completed } = this.state;
		// console.log("InvoiceGenerate Invoice ", invoice);
		//console.log("Product ", invoice ? invoice.products : {});
		return (loading ? (<><Typography variant="body1" weight="light">Loading...</Typography></>) :
		(<GridContainer>
			<GridItem>
				<Stepper nonLinear activeStep={activeStep}>
					{steps.map((item, index) => (
					<Step key={index} completed={completed[index]}>
						<StepButton color="inherit" onClick={() => this.handleStep(index)}>{item}</StepButton>
					</Step>
					))}
				</Stepper>
				<div style={{padding:5}}>
					<div style={{float:"left"}}>
						<Button color="primary" size="sm" disabled={activeStep === 0} onClick={this.handleBack}
							toolTipTitle="Back">Back</Button>
					</div>
					<div style={{float:"right"}}>
						<Button color="primary" size="sm" onClick={this.handleNext} toolTipTitle="Next">Next</Button>
							{/* {activeStep !== steps.length &&
								(completed[activeStep] ? 
									(<Typography variant="caption" sx={{ display: 'inline-block' }}>
										Step {activeStep + 1} already completed
									</Typography>)
									: (<Button color="primary" size="sm" onClick={this.handleComplete} toolTipTitle="Save">
											<ReceiptOutlined />
											{this.completedSteps() === this.totalSteps() - 1 ? 'Finish' : 'Complete Step'}
										</Button>)
								)
							} */}
					</div>
				</div>
			</GridItem>
			<GridItem>
				{/* {this.allStepsCompleted() ? (
				<React.Fragment>
					<Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
					<GridContainer>
						<GridItem>
							<Button onClick={this.handleReset}>Reset</Button>
						</GridItem>
					</GridContainer>
				</React.Fragment>
				) : ( */}
				<GridContainer>
					<GridItem>
						{activeStep === 0 ? 
							<>
								<InvoiceGenerateDetails invoice={invoice} refData={refData} 
									onChange={(field, value) => this.onFieldChange(field, value)}
									onTerminalChange={(field, value) => this.onTerminalChange(field, value)}
								/>
								<InvoiceGenerateProduct invoice={invoice} refData={refData} 
									onProductValidation = {(fieldName, product) => this.validateProduct(fieldName, product)}
									onChange={(fieldValue) => this.onFieldListChange(fieldValue)} 
									onDeleteProduct={(index) => this.onDeleteProduct(index)}
									onProductChange={(index, product) => this.onProductChange(index, product)}
									onAddProduct={(product) => this.onAddProduct(product)}
								/>
							</>
						: activeStep === 1 
							? <InvoiceGenerateCustomerDetails invoice={invoice} refData={refData} 
								onChange = {(field, value) => this.onFieldChange(field, value)} /> 
						: activeStep === 2 
							? <InvoiceGenerateConfigurationDetails invoice={invoice} refData={refData} 
								onChange = {(field, value) => this.onFieldChange(field, value)} /> 
							: <InvoiceGenerateReview invoice={invoice} refData={refData} onSubmit = {() => this.onSubmit()} /> 
						}
					</GridItem>
					{/* <GridItem>
						<Typography sx={{ mt: 2, mb: 1, py: 1 }}>Step {activeStep + 1}</Typography>
					</GridItem> */}
				</GridContainer>
				{/* )} */}
			</GridItem>				
		</GridContainer>
		));
	}
}

export default withRouter(InvoiceGenerate);