import React, { Component } from 'react'
import { Formik } from 'formik'
import { object, ref, string } from 'yup'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Paper from '@material-ui/core/Paper'
import { withStyles } from "@material-ui/styles";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ApiService from '../ApiService/ApiService';
import Spinner from '../../components/Spinner'
import Alert from '@material-ui/lab/Alert';
import PageTitle from "../../components/PageTitle/PageTitle";
import Button from '../../components/Custom/Button/Button';
import GridContainer from '../../components/Custom/Grid/GridContainer'
import GridItem from '../../components/Custom/Grid/GridItem'

const useStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  tablewraptext: {
	textOverflow: 'ellipsis', 
    overflow: 'hidden', 
    whiteSpace: 'nowrap',
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(4),
  },
  medium: {
    width: '45px',
    height: '15px', 
  },
 searchbar: {
	margin: theme.spacing(2),
  },
 form: {

  width: 60,
},
formWrapper: {
  margin: theme.spacing(4),
  width: 200,
},
formError: {
  width: 200,
},
});

class ChangePasswordComponent extends Component {
  constructor(props) {
        super(props)
		 this.state = {
		    passChangeSuccess: false,
			responsemessage: null,
			hasLoginFailed: false,
		}
  }
  
  
  _handleSubmit = ({
    currentPass,
    newPass,
    confirmPass,
    setSubmitting,
    resetForm,
    passType,
  }) => {
	
	ApiService.changePassword(currentPass, newPass, confirmPass, passType)
		.then((res) => {
      if (res != null) {
				if(res.data.status == 'OK') {
					 setSubmitting(false)
					 this.setState(() => ({
				        passChangeSuccess: true,
						hasLoginFailed: false,
				      }))
					resetForm()
				} else {
					this.setState(() => ({
				        passChangeSuccess: false,
						hasLoginFailed: true,
						responsemessage: res.data.responseMessage
				      }))
					setSubmitting(false)
					resetForm()
				}
      }
		}).catch((e) => {
			this.setState(() => ({
				        passChangeSuccess: false,
						hasLoginFailed: true,
						responsemessage: 'Error changing password',
				      }))
			setSubmitting(false)
			resetForm()
	})
  }

  render() {
		const { classes } = this.props;
		const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

    return (
	<Paper style={{margin: '1em', padding: '1em'}}>
	   <PageTitle title="Change Password" />
	  <Divider light className={classes.searchbar}/>
	  {this.state.hasLoginFailed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage} </Alert> }
	  {this.state.passChangeSuccess &&  <Alert severity="success" elevation="0"> Password changed successfully ! </Alert> }
	  <GridContainer style={{ margin: '.5em', padding: '.5em' }}>
	  {/* <Grid  item xs={4}>
      <Formik
        initialValues={{
          currentPass: '',
          newPass: '',
          confirmPass: '',
		  passType: 'API',
        }}
        validationSchema={object().shape({
          currentPass: string().required('Current password is required'),
          newPass: string().required('New password is required').matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    	),
          confirmPass: string()
            .oneOf([ref('newPass')], 'Passwords do not match')
            .required('Password is required'),
        })}
        onSubmit={(
          { currentPass, newPass, confirmPass,passType},
          { setSubmitting, resetForm }
        ) =>
          this._handleSubmit({
            currentPass,
            newPass,
            confirmPass,
            setSubmitting,
            resetForm,
			passType,
          })
        }
        render={props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          } = props
          return isSubmitting ? (
            <Spinner />
          ) : (
			
            <Paper elevation={3}>
				
              <form onSubmit={handleSubmit} style={{margin: '2em', padding: '2em'}}>
				<Typography variant="subtitle1" align="center" color="secondary">Change API Secret</Typography>
                <FormControl margin="dense" fullWidth size="small">
                  <InputLabel
                    htmlFor="password-current"
                    error={Boolean(touched.currentPass && errors.currentPass)}
                  >
                    {'Current Password'}
                  </InputLabel>
                  <Input 
                    id="password-current"
                    name="currentPass"
                    type="password"
                    value={values.currentPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.currentPass && errors.currentPass)}
                  />
                  <FormHelperText
                    error={Boolean(touched.currentPass && errors.currentPass)}
                  >
                    {touched.currentPass && errors.currentPass
                      ? errors.currentPass
                      : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={Boolean(touched.newPass && errors.newPass)}
                >
                  <InputLabel
                    htmlFor="password-new"
                    error={Boolean(touched.newPass && errors.newPass)}
                  >
                    {'New Password'}
                  </InputLabel>
                  <Input
                    id="password-new"
                    name="newPass"
                    type="password"
                    value={values.newPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.newPass && errors.newPass)}
                  />
                  <FormHelperText
                    error={Boolean(touched.newPass && errors.newPass)}
                  >
                    {touched.newPass && errors.newPass ? errors.newPass : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={Boolean(touched.confirmPass && errors.confirmPass)}
                >
                  <InputLabel
                    htmlFor="password-confirm"
                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                  >
                    {'Confirm Password'}
                  </InputLabel>
                  <Input
                    id="password-confirm"
                    name="confirmPass"
                    type="password"
                    value={values.confirmPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                  />
                  <FormHelperText
                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                  >
                    {touched.confirmPass && errors.confirmPass
                      ? errors.confirmPass
                      : ''}
                  </FormHelperText>
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={Boolean(!isValid || isSubmitting)} fullWidth
				  style={{marginTop: '2em'}}
                >
                  {'Reset Password'}
                </Button> 
              </form>
            </Paper>

          )
        }}
      />
	 </Grid>*/}
	 <GridItem xs={12} sm={12} md={6} lg={4}>
      <Formik
        initialValues={{
          currentPass: '',
          newPass: '',
          confirmPass: '',
		  passType: 'PORTAL',
        }}
        validationSchema={object().shape({
          currentPass: string().required('Current password is required'),
          newPass: string().required('New password is required').matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character, Cant re-use password from last 4 times."
    	),
          confirmPass: string()
            .oneOf([ref('newPass')], 'Passwords do not match')
            .required('Password is required'),
        })}
        onSubmit={(
          { currentPass, newPass, confirmPass, passType },
          { setSubmitting, resetForm }
        ) =>
          this._handleSubmit({
            currentPass,
            newPass,
            confirmPass,
            setSubmitting,
            resetForm,
			passType,
          })
        }
        render={props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          } = props
          return isSubmitting ? (
           <Spinner />
          ) : (
			
            <Paper elevation={3}>
				
              <form onSubmit={handleSubmit} style={{margin: '2em', padding: '2em'}}>
				<Typography variant="subtitle1" align="center" color="secondary">Change password</Typography>
                <FormControl margin="dense" fullWidth size="small">
                  <InputLabel
                    htmlFor="password-current"
                    error={Boolean(touched.currentPass && errors.currentPass)}
                  >
                    {'Current Password'}
                  </InputLabel>
                  <Input 
                    id="password-current"
                    name="currentPass"
                    type="password"
                    value={values.currentPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.currentPass && errors.currentPass)}
                  />
                  <FormHelperText
                    error={Boolean(touched.currentPass && errors.currentPass)}
                  >
                    {touched.currentPass && errors.currentPass
                      ? errors.currentPass
                      : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={Boolean(touched.newPass && errors.newPass)}
                >
                  <InputLabel
                    htmlFor="password-new"
                    error={Boolean(touched.newPass && errors.newPass)}
                  >
                    {'New Password'}
                  </InputLabel>
                  <Input
                    id="password-new"
                    name="newPass"
                    type="password"
                    value={values.newPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.newPass && errors.newPass)}
                  />
                  <FormHelperText
                    error={Boolean(touched.newPass && errors.newPass)}
                  >
                    {touched.newPass && errors.newPass ? errors.newPass : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={Boolean(touched.confirmPass && errors.confirmPass)}
                >
                  <InputLabel
                    htmlFor="password-confirm"
                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                  >
                    {'Confirm Password'}
                  </InputLabel>
                  <Input
                    id="password-confirm"
                    name="confirmPass"
                    type="password"
                    value={values.confirmPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                  />
                  <FormHelperText
                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                  >
                    {touched.confirmPass && errors.confirmPass
                      ? errors.confirmPass
                      : ''}
                  </FormHelperText>
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={Boolean(!isValid || isSubmitting)} fullWidth
				  style={{marginTop: '2em'}}
                >
                  {'Reset Password'}
                </Button>
              </form>
            </Paper>

          )
        }}
      />
	 </GridItem>
	 </GridContainer>
	</Paper>
    )
  }
} 
export default withStyles(useStyles)(ChangePasswordComponent);
