import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography';
import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'

import PageTitle from "../../../components/PageTitle/PageTitle";
import Button from '../../../components/Custom/Button/Button';
import { Save } from "@material-ui/icons";
import InvoiceConfigDetails from './InvoiceConfigDetails';
import InvoiceConfigUnit from './InvoiceConfigUnit';
import InvoiceConfigProduct from './InvoiceConfigProduct';
import InvoiceConfigCommunication from './InvoiceConfigCommunication';
import InvoiceConfigPaymentTerms from './InvoiceConfigPaymentTerms';

import { showToast, Status } from "../../ApiService/ApiService";
import ApiService from '../../ApiService/ApiService';

import ObjectUtils from '../../../util/objectUtil';

import { INVOICE_CONFIG_GET, INVOICE_CONFIG_UPDATE } from '../../ApiService/URLs';

const newConfiguration = {  	
    "id":"",
    "redirectUrl":"",
    "expirationPeriodDays": "",
    "tax": "0.0",
    "termnsAndConditions": "",
    "sendEmail": "false",
    "sendSms": "false",
	"lateFee" : "",
	"lateFeeType": "",
	"dueDays": "",
	"lateFeeApplicable": "false"
};

class InvoiceConfiguration extends Component {
  	constructor(props) {
        super(props)
		this.state = {
			config: null
		}
		this.handleConfigChange = this.handleConfigChange.bind(this);
  	}

	componentDidMount()	{
		this.getInvoiceConfigData();	
	}

	getInvoiceConfigData() {
		ApiService.getAPI(INVOICE_CONFIG_GET, null, false).then((res)=> { 
			if (res != null) this.setState({config: res.data || newConfiguration}) 
		});
	}
	
	onSaveClick() {
		const validObj = this.validate(this.state.config);
		//console.log("validObj", this.state.config, validObj);
		if (validObj != null)	{
			showToast({ type: validObj.helperType, title: "Error", description: validObj.helperText });
			return;
		}

		ApiService.postAPI(INVOICE_CONFIG_UPDATE, this.state.config).then((res)=> { 
			if (res != null) {
				this.getInvoiceConfigData();
				showToast({ type: Status.SUCCESS, title: "Status", description: "Invoice configuration saved successfully!" });
			}
		})
	}

	validate (config)	{
		if (!ObjectUtils.isNullorEmpty(config.expirationPeriodDays) && !ObjectUtils.isNumber(config.expirationPeriodDays))
			return { helperType: Status.ERROR, helperText: "Invalid Expiry Period Days value"};
		else if (!ObjectUtils.isNullorEmpty(config.tax) && !ObjectUtils.isNumber(config.tax))
			return { helperType: Status.ERROR, helperText: "Invalid Tax value"};
		else if (!ObjectUtils.isNullorEmpty(config.lateFee) && !ObjectUtils.isNumber(config.lateFee))
			return { helperType: Status.ERROR, helperText: "Invalid Late Fee value"};
		else if (!ObjectUtils.isNullorEmpty(config.dueDays) && !ObjectUtils.isNumber(config.dueDays))
			return { helperType: Status.ERROR, helperText: "Invalid Due Days value"};
		else return null;
	}	

	handleConfigChange (name, value) {
		//console.log("Value Changes", name, ":", value);
		const newConfig = {...this.state.config};
		newConfig[name] = value;
		this.setState({config: newConfig});
	};

  	render() {
		const { classes } = this.props;		
		const { config } = this.state;

		return !config ? (<><Typography variant="body1" weight="light">Loading...</Typography></>) :
		(<GridContainer spacing={0} style={{backgroundColor:"#FFF"}}>
			<GridItem item xs={12} sm={12} md={12} lg={12}>
				<PageTitle title="Invoice Configuration" />
			</GridItem>
			<GridItem item xs={12} sm={12} md={12} lg={12} style={{marginTop: "-20px"}}>
				<InvoiceConfigDetails config={config} onChange={(name, value) => this.handleConfigChange(name, value)} />
			</GridItem>
			<GridItem item xs={12} sm={12} md={12} lg={12} style={{marginTop: "-20px"}}>
				<InvoiceConfigUnit />
			</GridItem>
			<GridItem item xs={12} sm={12} md={12} lg={12} style={{marginTop: "-20px"}}>
				<InvoiceConfigProduct />
			</GridItem>	
			<GridItem item xs={12} sm={12} md={12} lg={12} style={{marginTop: "-20px"}}>
				<InvoiceConfigCommunication config={config} onChange={(name, value) => this.handleConfigChange(name, value)} />
			</GridItem>
			<GridItem item xs={12} sm={12} md={12} lg={12} style={{marginTop: "-20px"}}>
				<InvoiceConfigPaymentTerms config={config} onChange={(name, value) => this.handleConfigChange(name, value)} />
			</GridItem>
			<GridItem item xs={12} sm={12} md={12} lg={12} style={{direction: "column", justify:"center"}} >
				<Button color="primary" onClick={() => this.onSaveClick()} toolTipTitle="Save Changes">
					<Save /> Save Changes
				</Button>
			</GridItem>
			{/* <GridItem item xs={12} md={12} lg={12}>
				<Card color="white">
					<CardHeader color="primary">
						<GridContainer>
							<GridItem xs={12} sm={12} md={5} lg={5}>
								<Typography variant="caption">Invoice Configuration</Typography>
							</GridItem>
							<GridItem xs={12} sm={12} md={7} lg={7} />
						</GridContainer>
					</CardHeader>
					<CardBody>
						<Typography  variant="caption">Test 2 ssasa</Typography>						
					</CardBody>
				</Card>
			</GridItem> */}		
		</GridContainer>
    )	
  }
} 
export default InvoiceConfiguration;
