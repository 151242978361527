import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography';
import InputAdornment from "@material-ui/core/InputAdornment";
import { LanguageOutlined } from "@material-ui/icons";

import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'

import CustomInput from '../../../components/Custom/CustomInput/CustomInput';
import Card from '../../../components/Custom/Card/Card'
import CardHeader from '../../../components/Custom/Card/CardHeader'
import CardBody from '../../../components/Custom/Card/CardBody'
import { Switch } from '@material-ui/core';

class InvoiceConfigCommunication extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {
		const { config, onChange } = this.props;
		// console.log ("InvoiceConfigCommunication config --->", config);

		return <Card color="white">
				<CardBody>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<Typography variant="h5">Communication</Typography>
						</GridItem>
						<GridItem xs={4} sm={4} md={2} lg={1}>
							<Switch
								color="primary"
								edge="end"
								name="sendEmail"
								value={config.sendEmail}
								onChange={(e) => onChange(e.target.name, e.target.checked) }
								checked={config.sendEmail === true}
							/>
						</GridItem>
						<GridItem xs={8} sm={8} md={4} lg={2} style={{marginTop: "8px"}}>
							<Typography variant="body1">Email</Typography>							
						</GridItem>
						<GridItem xs={4} sm={4} md={3} lg={1}>
							<Switch
								color="primary"
								edge="end"
								name="sendSms"
								value={config.sendSms}
								onChange={(e) => onChange(e.target.name, e.target.checked) }
								checked={config.sendSms === true}
							/>
						</GridItem>
						<GridItem xs={8} sm={8} md={4} lg={2} style={{marginTop: "8px"}}>
							<Typography variant="body1">SMS</Typography>		
						</GridItem>						
					</GridContainer>
				</CardBody>
			</Card>
		}
}

export default InvoiceConfigCommunication;