import React, { useState, Component } from 'react';
// import { ThemeProvider} from '@material-ui/styles';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

class AppTheme extends Component {
    constructor(props) {
        super(props);
	  	
	  	this.state = {
            module: null,
            component: this.props.component
        }
    }

    componentDidMount() {
        //console.log("process.env.REACT_APP_CLIENT", process.env.REACT_APP_CLIENT);
        import(`./${process.env.REACT_APP_CLIENT}`)
        .then(module => this.setState({ module: module.default }));
    }
   
    render() {
        const {module, component} = this.state;
        console.log("module", module);
        return module == null ? null : <ThemeProvider theme={module.default}>{component}</ThemeProvider>
    }
}

export default AppTheme;