import React from 'react'
import axios from 'axios';

import { Close } from "@mui/icons-material";
import {saveAs} from 'file-saver';
import AppContext from '../../context/AppContext';
import Button from '../../components/Custom/Button/Button';
import ObjectUtils from '../../util/objectUtil';
import { 
	AUTH_TOKEN,
	MERCHANT_TXN_REFUND,
	ACCESS_MENU_ACTION, TXN_SUMMARY_STATUS_GET, TXN_SUMMARY_MERCHANT_MONTHS_GET, USER_MENU_MAPPINGS,
	QUICK_PAYMENT_FETCH_ALL, QUICK_PAYMENT_ADD, QUICK_PAYMENT_REMOVE, QUICK_PAYMENT_CHECKOUT, QUICK_PAYMENT_TRANSACTION_GET,
	MERCHANT_URL_GET_ALL, MERCHANT_URL_ADD, MERCHANT_URL_REMOVE,
	MERCHANT_IP_GET_ALL, MERCHANT_IP_ADD, MERCHANT_IP_REMOVE, 
	EDIT_TERMINAL } from './URLs';

const API_URL = process.env.REACT_APP_HOST_URL;
const USER_API_BASE_URL = API_URL + '/reports/';

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const MERCHANT_SESSION_ATTRIBUTE_NAME = 'loggedInMerchant'

export const Status = {
    SUCCESS: "success",
    ERROR: "error",
    INFO: "primary",
    NONE: undefined,
    NOTIFY: "default",
    DANGER: "danger",
};

export const setToast = (showSnackbar, closeSnackbar) => {
    AppContext.toast = { showSnackbar, closeSnackbar};
}

export const showToast = (msgObj) => {
    if (AppContext.toast && AppContext.toast.showSnackbar) {
		//console.log("Before msgObj - ", msgObj);
        AppContext.toast.showSnackbar(msgObj.title + " - " + msgObj.description, {
          variant: msgObj.type ? msgObj.type : "info",
          autoHideDuration: 3500,
          action: (key) => (<Button simple onClick={() => AppContext.toast.closeSnackbar(key)}><Close /></Button>),
          // preventDuplicate: true,
        });
		//console.log("After msgObj - ", msgObj);
    }
}

class ApiService {

	//Start - API Methods
	getAPI(path, dataMapArr, isUrlEncode) {
		if (dataMapArr == null) 
			return axios.get(path)
				.then(response => {	return response; })
				.catch(error => showToast({ type: Status.ERROR, title: "Error", description: "Error has occured!"}));		
		else if (isUrlEncode === true) {
			const paramArr = Object.keys(dataMapArr).map(key =>paramArr.push(key + "=" + dataMapArr[key]));
			return axios.get(path + (paramArr.length > 0 ? "?" + paramArr.join("&") : ""))
				.then(response => {	return response; })
				.catch(error => showToast({ type: Status.ERROR, title: "Error", description: "Error has occured!" }));
		}
		else {
			return axios.get(path + (dataMapArr.length > 0 ? "/" + dataMapArr.join("/") : ""))
				.then(response => {	return response; })
				.catch(error => showToast({ type: Status.ERROR, title: "Error", description: "Error has occured!" }));
		}
	}
	
	postAPI(path, data) {
		if (data == null) data = {};
		return axios.post(path, data)
			.then(response => {return response; })
			.catch(error => showToast({ type: Status.ERROR, title: "Error", description: "Error has occured!" }));
	}

	downloadPdf(path, reqData, options) {
		axios({
			method: 'post',
			url: path,
			responseType: 'blob',			
			data: reqData
		})
		.then(response => {
			saveAs(new Blob(
				[response.data], 
				{type: 'application/pdf'}), (options.fileName ? options.fileName : ObjectUtils.generateUUID()) + ".pdf");
			showToast({ type: Status.SUCCESS, title: "Status", description: "File successfully downloaded !" });
		})
		.catch(error => {
			console.log(error);
		}); 
	}
	//End - API Methods

    fetchMerchants() {
        return this.getAPI(USER_API_BASE_URL + 'merchants');
    }

    fetchMerchantById(merchantId) {
        return this.getAPI(USER_API_BASE_URL + 'merchant?merchantId=' + merchantId);
    }

	fetchMerchantByName(merchantName) {
        return this.getAPI(USER_API_BASE_URL + 'merchantByName?merchantName=' + merchantName);
    }

	fetchFraudInfoForTxn(txnId) {
		return this.postAPI(API_URL + '/admfns/fetchFraudInfoForTxn', {
			 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			 "txn_id" : txnId,
		})
	}

	fetchLoggedInMerchant() {
        return this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME);
    }

	registerLoggedInMerchant(merchant) {
		this.setInSessionStorage(MERCHANT_SESSION_ATTRIBUTE_NAME, merchant)
	}

	createUser(formData) {
	   return this.postAPI(API_URL + '/user/createUser',  formData);
	}
	
	updateUser(userObj) {
		 return this.postAPI(API_URL + '/user/updateUser',  { "request": userObj });
	}

	updateUserMerchant(userMerObj) {
		 return this.postAPI(API_URL + '/user/updateUserMerchant', { "merchant": userMerObj });
	}
	
	getCurrentUser() {
		return this.getAPI(API_URL + '/user/getCurrentUser');
	}

	getUserDocuments() {
		return this.getAPI(API_URL + '/user/getUserDocuments');
	}
	
	onbboardBusinessDetails(merchant) {
		return axios({method: 'post', url: API_URL + '/user/onbboardBusinessStep', data : merchant});
	}	
	
	getUserMerchant() {
		return this.getAPI(API_URL + '/user/getUserMerchant');
	}
	
	getMerchantDocumentConfig() {
		return this.getAPI(API_URL + '/user/getMerchantDocumentConfig');
	}
	
	getUserMerchantDocuments(formData) {
		return this.getAPI(API_URL + '/user/getMerchantDocumentConfig', formData);
	}
	
	uploadOnboardingDocs(formData) {
		return this.postAPI(API_URL + '/user/uploadOnboardingDocs', formData);
	}
	updateMerchantSecret(merchantId, val) {
		return this.postAPI(API_URL + '/user/updateMerchantSecret', { "id" : merchantId, "password": val });
	} 
	updateMerchantSignkey(merchantId, val) {
		return this.postAPI(API_URL + '/user/updateMerchantSignkey', { "id" : merchantId, "signKey": val });
	} 
	fetchTransactionsXL(fromDate, toDate, statuses, orderNo, email, allChbk, allDispute) {
		axios({
			method: 'post',
			url: API_URL + '/reports/fetchTransactionXL',
			responseType: 'blob',
			data: { 
				 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  		 "fromDate" : fromDate,
		  		 "toDate" : toDate,
		  		 "statuses": statuses,
	 		  	 "orderNo" : orderNo,
				 "email" : email,
				 "allChbk" : allChbk,
			}
		})
		.then(response => {
		//Create a Blob from the XLS Stream
		    saveAs(new Blob(
		      [response.data], 
		      {type: 'application/vnd.ms-excel'}),'transactions.xlsx');
		//Build a URL from the file
		 //   const fileURL = URL.createObjectURL(file);
		//Open the URL on new Windowupl
		  //  window.open(fileURL + ".xlsx");
		})
		.catch(error => {
		    console.log(error);
		}); 
	}
	
	fetchMerchantTransactions(fromDate, toDate, statuses, orderNo, email, pageNumber, numberRowPerPage, allChbk, allDispute) {
		return this.postAPI(API_URL + '/reports/merchantTransactions', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
          "fromDate" : fromDate,
		  "toDate" : toDate,
		  "statuses": statuses,
		  "orderNo" : orderNo,
		  "email" : email,
		  "pageNumber" : pageNumber,
		  "numberRowPerPage" : numberRowPerPage,
		  "allChbk" : allChbk,
      })
	}

	fetchMerchantTerminals(){
		return this.postAPI(API_URL + '/wire/fetchMerchantTerminals', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}

	fetchFraudRulesByMerchantId(merId) {
		return this.postAPI(API_URL + '/wire/fetchFraudRulesById', { "merchantId":merId });
	}

	fetchFraudRules() {
		return this.postAPI( API_URL + '/wire/fetchFraudRules', null)
	}	

	addFraduRule(fraudName,merId,fraudValue) {
		return this.postAPI(API_URL + '/wire/addFraudRulesById', { 
			"merchantId":merId, "fraudName":fraudName, "fraudValue":fraudValue })
	}

	//Terminals
	fetchTerminals(merId,location){
		//console.log(this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME));
		return this.postAPI(API_URL + '/reports/fetchTerminals', { "merchantId" : merId, "terminalLocation" : location });
	}

	//fetch Stores
	fetchStores(merchantId){
		return this.postAPI( API_URL + '/reports/fetchStores', { "merchantId" : merchantId });
	}

	//insert store
	addNewStore(merchantId,storeName, storeLocation) {
		return this.postAPI(API_URL + '/reports/addNewStore', {
			"merchantId" : merchantId, "storeName": storeName, "storeLocation":storeLocation,
		});
	} 


	fetchAcquirerAccounts(){
		return this.postAPI(API_URL + '/wire/fetchAcquirerAccounts', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}

	fetchMasterCharges(){
		return this.postAPI(API_URL + '/wire/fetchMasterCharges', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
        })
	}
	fetchEntityChargeDetails(fromDate, toDate, terminalId) {
		return this.postAPI(API_URL + '/wire/fetchEntityChargeDetails', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
          "validFrom" : fromDate,
		  "validTo" : toDate,
		  "enitityId": terminalId,
		  "entityType" : "MERCHANT",
      })
	}
	
	acquirer023Notification(status, order_id, transactionId, message, errorCode) {
		return this.postAPI( API_URL + '/res/ACQ023Notification', {
          "status" : status,
          "orderId" : order_id,
		  "transactionId" : transactionId,
		  "message" : message,
		  "errorCode": errorCode,
      })
	}
	
	fetchWires(fromDate, toDate, terminalId, accountId) {
		return this.postAPI( API_URL + '/wire/fetchWires', {
          "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
          "fromDate" : fromDate,
		  "toDate" : toDate,
		  "enitityId": terminalId,
		  "entityType" : "MERCHANT",
      })
	}


	changePassword(currentPass, newPass, confirmPass, passType) {
		return this.postAPI( API_URL + '/reports/changePassword', {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"currentPass" : currentPass,
			"newPass" : newPass,
			"confirmPass" : confirmPass,
			"passType": passType,
		})
	}
	
	getFromSessionStorate(key) {
		return sessionStorage.getItem(key);
	}
	setInSessionStorage(key, value) {
		sessionStorage.setItem(key, value);	
	}
	removeFromSessionStorage(key) {
		sessionStorage.removeItem(key);
	}

    executeJwtAuthenticationService(username, password) {
      return this.postAPI(AUTH_TOKEN , { "username" : username, "secret" : password, "source" : "dashboard"	});
  	}
  
  registerSuccessfulLogin(username, password) {
        this.setInSessionStorage(USER_NAME_SESSION_ATTRIBUTE_NAME, username);		
        this.setupAxiosInterceptors(this.createBasicAuthToken(username, password));
    }

    registerSuccessfulLoginForJwt(username, token) {
        this.setInSessionStorage(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
        this.setupAxiosInterceptors(this.createJWTToken(token))
    }

    createJWTToken(token) {
        return token
    }


    logout() {
        this.removeFromSessionStorage(USER_NAME_SESSION_ATTRIBUTE_NAME);
		this.removeFromSessionStorage(MERCHANT_SESSION_ATTRIBUTE_NAME);
    }

    isUserLoggedIn() {
        let user = this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return false
        return true
    }

    getLoggedInUserName() {
        let user = this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }

	refundMerchantTransaction(transactionId, reason, refundAmount) {
		 return this.postAPI(MERCHANT_TXN_REFUND, {
			"merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
			"merchantTransactionId" : transactionId,
			"reason" : reason,
			"refundAmount" : refundAmount,			
		})
	}
	
    setupAxiosInterceptors(token) {
        axios.interceptors.request.use(
            (config) => {
                if (this.isUserLoggedIn()) {
                    config.headers.authorization = token
                }
                return config
            }
        )
    }

	fetchWirePdf(wireId) {
		axios({
			method: 'post',
			url: API_URL + '/wire/fetchWirePdf',
			responseType: 'blob',
			data: { 
				 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  		 "wireId":wireId,
			}
		})
		.then(response => {
		//Create a Blob from the PDF Stream
		    const file = new Blob(
		      [response.data], 
		      {type: 'application/pdf'});
		//Build a URL from the file
		    const fileURL = URL.createObjectURL(file);
		//Open the URL on new Windowupl
		    window.open(fileURL);
		})
		.catch(error => {
		    console.log(error);
		}); 
	}
	
	fetchWireXls(wireId) {
		axios({
			method: 'post',
			url: API_URL + '/wire/fetchWireXls',
			responseType: 'blob',
			data: { 
				 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME),
		  		 "wireId":wireId,
			}
		})
		.then(response => {
			//Create a Blob from the PDF Stream
		    const file = new Blob(
		    	[response.data], 
		      	{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			//Build a URL from the file
		    const fileURL = URL.createObjectURL(file);
			//Open the URL on new Windowupl
		    window.open(fileURL);
		})
		.catch(error => {
		    console.log(error);
		}); 
		
	}

	checkAccessByMenuAndAction(menuText, action) { 
		return this.postAPI(ACCESS_MENU_ACTION, {
			 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME), "menuText" : menuText, "action" : action
		})
	}

	fetchMerchantTxnStatusSummary() {
		return this.postAPI(TXN_SUMMARY_STATUS_GET, { "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME) })
	}
	
	fetchMonthsMerchantTxnSummary() {
		return this.postAPI(TXN_SUMMARY_MERCHANT_MONTHS_GET, { "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME) })
	}
	
	userMenuMappings(userId) {
		return this.postAPI(USER_MENU_MAPPINGS, {
			 "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME), "userId" : userId,
		})
	}
	
	fetchQuickPayments() {
		//console.log(this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME));
		return this.postAPI(QUICK_PAYMENT_FETCH_ALL, { "merchantId" : this.getFromSessionStorate(USER_NAME_SESSION_ATTRIBUTE_NAME) });
	}
	
	createQuickPayment(terminalId, amount, remarks) {
		return this.postAPI(QUICK_PAYMENT_ADD, { "terminalId" : terminalId, "amount": amount, "remarks" : remarks });
	}
	
	cancelQuickPayment(qpId) {
		return this.postAPI(QUICK_PAYMENT_REMOVE, { "qpId" : qpId });
	}
	
	quickPaymentCheckout(data) {
		return this.postAPI(QUICK_PAYMENT_CHECKOUT,  data);
	}
	
	retrieveQuickPayTransaction(qpId) {
		return this.postAPI(QUICK_PAYMENT_TRANSACTION_GET, { "qpId" : qpId });
	}
	
	countryList(){
		return this.getAPI("https://restcountries.com/v2/all");
	}

	addMerchantUrl(merchantId, url) {
		return this.postAPI(MERCHANT_URL_ADD, { "merchant" :  merchantId, "url" : url })
	}
	fetchMerchantUrls(merchantId){
		return this.postAPI(MERCHANT_URL_GET_ALL, { "merchant" : merchantId })
	}
	removeMerchantUrl(merchantId, id) {
		return this.postAPI(MERCHANT_URL_REMOVE, { "merchant" : merchantId, "id" : id })
	}
	
	addMerchantIp(merchantId, ip) {
		return this.postAPI(MERCHANT_IP_ADD, { "merchant" : merchantId, "ip" : ip })
	}
	fetchMerchantIps(merchantId){
		return this.postAPI(MERCHANT_IP_GET_ALL, { "merchant" : merchantId })
	}
	removeMerchantIp(merchantId, id) {
		return this.postAPI(MERCHANT_IP_REMOVE, { "merchant" : merchantId, "id" : id })
	}
	
	editTerminal(merchantId, formData) {		
		return this.postAPI(EDIT_TERMINAL, { "terminal": formData, "merchant" : merchantId })
	}
}

export default new ApiService();
