import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  cardHeader: {
    borderRadius: "5px",
    padding: "1rem 15px",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "-30px",
    border: "0",  
    marginBottom: "0"
  },
  cardHeaderPlain: {
    marginLeft: "0px",
    marginRight: "0px"
  },
  plainCardHeader: theme.palette.card.plainCardHeader,
  primaryCardHeader: theme.palette.card.primaryCardHeader,
  infoCardHeader: theme.palette.card.infoCardHeader,
  successCardHeader: theme.palette.card.successCardHeader,
  warningCardHeader: theme.palette.card.warningCardHeader,
  dangerCardHeader: theme.palette.card.dangerCardHeader,
  simpleCardHeader: theme.palette.card.simpleCardHeader,
}));
