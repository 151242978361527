import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography';
import InputAdornment from "@material-ui/core/InputAdornment";
import { AddOutlined, Save, Delete } from "@material-ui/icons";

import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'

import CustomInput from '../../../components/Custom/CustomInput/CustomInput';
import Card from '../../../components/Custom/Card/Card'
import CardHeader from '../../../components/Custom/Card/CardHeader'
import CardBody from '../../../components/Custom/Card/CardBody'
import Button from '../../../components/Custom/Button/Button';
import { Divider } from '@material-ui/core';

import ObjectUtils from '../../../util/objectUtil';

import { showToast, Status } from "../../ApiService/ApiService";
import ApiService from '../../ApiService/ApiService';
import { INVOICE_CONFIG_UNIT_GET_ALL, INVOICE_CONFIG_UNIT_ADD, INVOICE_CONFIG_UNIT_DELETE } from '../../ApiService/URLs';

class InvoiceConfigUnit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			unitList: [],
			unitNameToAdd: "",			
			unitValidation: {},
		};
		this.onAddClick = this.onAddClick.bind(this);
		this.onSaveClick = this.onSaveClick.bind(this);
		this.onNewTextChange = this.onNewTextChange.bind(this);
		this.onIndexTextChange = this.onIndexTextChange.bind(this);
	}

	componentDidMount(){
		this.onUnitRefresh();	
	}
	
	onUnitRefresh () {
		//console.log("Unit Refresh");
		this.setState({unitNameToAdd: ""});
		ApiService.getAPI(INVOICE_CONFIG_UNIT_GET_ALL, null, false).then((res)=> {
			if (res != null) this.setState({unitList: res.data}) 
		})
	};	

	onAddClick() {
		const validObj = this.validate("_1", this.state.unitNameToAdd);
		//console.log("validObj", validObj);
		if (validObj != null)	{
			this.setState({unitValidation: validObj});
			return;
		}

		const data = {unitName: this.state.unitNameToAdd};
		//console.log("this.state.unitNameToAdd", data);
		ApiService.postAPI(INVOICE_CONFIG_UNIT_ADD, data).then((res)=> { 
			if (res != null) {
				showToast({ type: Status.SUCCESS, title: "Status", description: "Unit added successfully!" });
				this.setState({unitNameToAdd: "", unitValidation: {}}, () => this.onUnitRefresh());
			}
		})
	}

	onSaveClick(index) {
		const validObj = this.validate(index, this.state.unitList[index].unitName);
		//console.log("validObj", validObj);
		if (validObj != null)	{
			this.setState({unitValidation: validObj});
			return;
		}
		
		const data = {
			id: this.state.unitList[index].id,
			unitName: this.state.unitList[index].unitName
		};
		//console.log("onSaveClick", index, data);
		ApiService.postAPI(INVOICE_CONFIG_UNIT_ADD, data).then((res)=> { 
			if (res != null) {
				showToast({ type: Status.SUCCESS, title: "Status", description: "Unit saved successfully!" });
				this.setState({unitValidation: {}}, () => this.onUnitRefresh());
			}
		})
	}
	
	onDeleteClick(index) {
		const data = { id: this.state.unitList[index].id	};
		ApiService.postAPI(INVOICE_CONFIG_UNIT_DELETE, data).then((res)=> { 
			if (res != null) {
				showToast({ type: Status.SUCCESS, title: "Status", description: "Unit deleted successfully!" });
				this.onUnitRefresh();
			}
		})
	}
	
	validate (fieldName, unit)	{
		if (ObjectUtils.isNullorEmpty(unit)) 
			return {
				['COMP' + fieldName] : {
					helperType: Status.ERROR, 
					helperText: "Unit name is required"} 
			};
		else return null;
	}

	getHelperText(index, helperField) {
		return this.state.unitValidation[`COMP${index}`] ? this.state.unitValidation[`COMP${index}`][helperField] : ""
	}

	onNewTextChange(value) {
		//console.log(value);
		this.setState({unitNameToAdd: value, unitValidation: {}});
	}

	onIndexTextChange(e, index) {
		var newUnitList = [...this.state.unitList];
		newUnitList[index].unitName = e.target.value;
		newUnitList.splice(index, 1, newUnitList[index]);
		this.setState({unitList: newUnitList});
	}

	render() {
		const { unitNameToAdd, unitList } = this.state;
		//console.log ("Validation, unitNameToAdd - ", unitValidation, unitNameToAdd);

		return <Card color="white">
				<CardBody>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<Typography variant="h5">Unit Configuration</Typography>
						</GridItem>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<GridContainer>
								<GridItem xs={8} sm={8} md={4} lg={3}>
									<CustomInput
										title="Name"
										field="unitName"
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: unitNameToAdd,
											name: "unitName",
											id: "unitName",
											onChange: (e) => this.onNewTextChange(e.target.value),
										}}
										helperType={this.getHelperText("_1", "helperType")}
										helperText={this.getHelperText("_1", "helperText")}
									/>
								</GridItem>
								<GridItem xs={4} sm={4} md={3} lg={3} style={{marginTop: "18px"}} >
									<Button color="primary" size="sm" onClick={() => this.onAddClick()} toolTipTitle="Add Unit">
										<AddOutlined /> Add
									</Button>
								</GridItem>
							</GridContainer>
						</GridItem>
						{!ObjectUtils.isNullObject(unitList) > 0 && <GridItem><Divider light/></GridItem>}
						{unitList.map((unitItem, idx) => (
							<GridItem xs={12} sm={12} md={6} lg={6} key={unitItem.id}>
								<GridContainer>
									<GridItem xs={6} sm={6} md={6} lg={6}>
										<CustomInput
											title="Name"
											field="unitName"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												defaultValue: unitItem.unitName,
												name: "unitName",
												id: "unitName",
												onChange: (e) => this.onIndexTextChange(e, idx),
											}}
											helperType={this.getHelperText(idx, "helperType")}
											helperText={this.getHelperText(idx, "helperText")}
										/>
									</GridItem>
									<GridItem xs={3} sm={3} md={2} lg={2}>
										<Button color="white" size="lg" justIcon round onClick={() => this.onSaveClick(idx)} toolTipTitle="Save">
											<Save style={{color: "#9AAFFB"}} />
										</Button>
									</GridItem>
									<GridItem xs={3} sm={3} md={2} lg={2}>
										<Button color="white" size="lg" justIcon round onClick={() => this.onDeleteClick(idx)} toolTipTitle="Delete">
											<Delete style={{color: "#FF0000"}}/>
										</Button>
									</GridItem>
								</GridContainer>
							</GridItem>
						))}	
					</GridContainer>
				</CardBody>
			</Card>
		}
}

export default InvoiceConfigUnit;