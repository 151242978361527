import React from "react";
import ReactDOM from "react-dom";
import { CssBaseline } from "@material-ui/core";
import { Slide } from "@mui/material";

import App from "./App";
import Login from "./pages/login/Login";

import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { SnackbarProvider } from "notistack";
import AppTheme from "./environment/client/themes/AppTheme";

ReactDOM.render(
  <LayoutProvider>
    <AppTheme component={
      <>
        <UserProvider>
          <CssBaseline />
          <SnackbarProvider
          // iconVariant={{ success: "✅", error: "✖️", warning: "⚠️", info: "ℹ️" }}
          // dense={false} preventDuplicate hideIconVariant={false}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
          maxSnack={10}
        >
          <App/>
          </SnackbarProvider>
        </UserProvider>
      </>
      }
      />
  </LayoutProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
