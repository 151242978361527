import React, { useState, Component } from 'react';
import { withStyles } from "@material-ui/styles";

import { Container, FormControlLabel, Grid, Typography, Box, Paper } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import {DropzoneDialog} from 'material-ui-dropzone'
import CheckIcon from '@material-ui/icons/CheckCircle';
import ApiService from '../../../ApiService/ApiService';
import Button from '../../../../components/Custom/Button/Button';

const useStyles = theme => ({
	gridSpace: {
		padding: theme.spacing(2),
	  },
	paper: {
		backgroundColor: '#F6F6F6',
		margin: theme.spacing(2),
	  },
	check: {
		color: 'green',
	},
	 root: {
		height: 500,
    	width:  200,
	},
	button: {
	    margin: theme.spacing(1),
	  },
 	formControl: { 
    marginLeft: 20,
    minWidth: 120,
    maxWidth: 300,
  },
});

class Document extends Component {
	constructor(props) {
        super()
        this.state = {
            merchantId: null,
			merchant: null,
			activeStep: 0,
			agreed: false,
			open: false,
			docType: 0,
			docConfig: null,
			files: [],
			doc1Uploaded: false,
			doc2Uploaded: false,
			doc3Uploaded: false,
			uploadedDocs: [],
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.handlePrevious = this.handlePrevious.bind(this);		
		this.updateDocuments = this.updateDocuments.bind(this);
		this.loadDocumentConfig = this.loadDocumentConfig.bind(this);
		this.loadExistingDocuments = this.loadExistingDocuments.bind(this);
	}
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }

	componentDidMount() {
		this.loadDocumentConfig();
		this.loadExistingDocuments();
		const request = async () => { 
			const res = await ApiService.getUserMerchant();
			const data = await res.data;
			this.setState({merchant: data});
		}
		request();	
	}
	 
		
	loadDocumentConfig() {
		ApiService.getMerchantDocumentConfig().then((res)=> {			
			if (res != null) this.setState({docConfig: res.data})
		})
	}
	loadExistingDocuments() {
		ApiService.getUserDocuments().then((res) => {			
			if (res != null) this.setState({uploadedDocs: res.data})
		})
	}
	updateDocuments(configId) {
		this.setState({docType: configId, open: true});
	}
	handleNext() {
		 
			this.props.handleNext();
		 
	}
	handleSave(files) {

        //Saving files to state for further use and closing Modal.
        files.map((file)=> {
			const formData = new FormData();
        	formData.append('files', file);
 			formData.append('fileTypeId', this.state.docType);
			formData.append('fileType', file.type);
			formData.append('merchantIdUUID', this.state.merchant.id);
			ApiService.uploadOnboardingDocs(formData).then((res)=> {
				if (res != null) this.setState({agreed: true});
			})
		}) 
		this.handleClose();
    }
	handleClose() {
        this.setState({
            open: false
        });
    }
	handleOpen() {
        this.setState({
            open: true,
        });
    }
 
	handlePrevious() {
	 	this.props.handlePrevious();
	}
	
	render(){
		const { classes } = this.props;
		return ( 
			<Container component="main" maxWidth="md">
				{this.state.docConfig && this.state.docConfig.map((config) => 
				 <Paper className={classes.paper}>
					<Grid container direction="row" justify="space-between"  alignItems="flex-start" className={classes.gridSpace}>
						<Grid item xs={10}>
							<Typography variant="subtitle1"> {config.docDescription}</Typography>
						</Grid>
						<Grid item xs={2}>						
							<Button variant="contained" color="primary" size="sm" onClick={ () =>  this.updateDocuments(config.id) }>
			                  Select
			                </Button>
						</Grid>
						<Grid item xs={10}>
							{this.state.uploadedDocs && this.state.uploadedDocs.map((doc) => 								
								<Typography variant="caption">
									{doc.documentConfig.id === config.id && <><CheckIcon/>{doc.fileName}</>}
								</Typography>							
							)}
						</Grid>
					</Grid>
				</Paper>
			)}			 
			 	 
			<DropzoneDialog
				open={this.state.open}
				onSave={this.handleSave.bind(this)}
				acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
				showPreviews={true}
				filesLimit={1}
				maxFileSize={5000000}
				onClose={this.handleClose.bind(this)}
			/>
			<Box align="center" style={{paddingTop: 2,}}>
				<Button variant="contained" color="primary" onClick={this.handlePrevious} style={{marginRight: 100}}> Previous </Button>
				<Button variant="contained" color="primary" onClick={this.handleNext} > Next </Button>   
			</Box>
		</Container>
		);
	}
} export default withStyles(useStyles)(Document);