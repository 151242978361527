const API_URL = process.env.REACT_APP_HOST_URL;

export const INVOICE_CONFIG_GET = API_URL + '/inv/merchantInvoiceConfig';
export const INVOICE_CONFIG_UPDATE = API_URL + '/inv/updateMerchantInvoiceConfig';

export const INVOICE_CONFIG_PRODUCT_GET_ALL = API_URL + '/inv/merchantProducts';
export const INVOICE_CONFIG_PRODUCT_ADD = API_URL + '/inv/createMerchantProduct';
export const INVOICE_CONFIG_PRODUCT_DELETE = API_URL + '/inv/deleteMerchantProduct';

export const INVOICE_CONFIG_UNIT_GET_ALL = API_URL + '/inv/merchantInvoiceConfigUnits';
export const INVOICE_CONFIG_UNIT_ADD = API_URL + '/inv/createInvoiceConfigUnits';
export const INVOICE_CONFIG_UNIT_DELETE = API_URL + '/inv/deleteMerchantInvoiceConfigUnits';

export const INVOICE_GET_ALL = API_URL + '/inv/invoices';
export const INVOICE_GET_BY_ID = API_URL + '/inv/invoiceById';
export const INVOICE_ADD = API_URL + '/inv/createMerchantInvoice';
export const INVOICE_DOWNLOAD = API_URL + '/inv/getInvoicePdf';
export const INVOICE_DELETE = API_URL + '/inv/cancelMerchantInvoice';

export const BLOCK_CARD_GET_ALL = API_URL + '/merc/fetchBlockCards';
export const BLOCK_CARD_ADD = API_URL + '/merc/addBlockCard';
export const BLOCK_CARD_REMOVE = API_URL + '/merc/removeBlockCards';

export const BLOCK_EMAIL_GET_ALL = API_URL + '/merc/fetchBlockEmails';
export const BLOCK_EMAIL_ADD = API_URL + '/merc/addBlockEmail';
export const BLOCK_EMAIL_REMOVE = API_URL + '/merc/removeBlockEmail';

export const BLOCK_IPS_GET_ALL = API_URL + '/merc/fetchBlockIps';
export const BLOCK_IP_ADD = API_URL + '/merc/addBlockIp';
export const BLOCK_IP_REMOVE = API_URL + '/merc/removeBlockIp';

export const BLOCK_COUNTRY_GET_ALL = API_URL + '/merc/fetchBlockCountries';
export const BLOCK_COUNTRY_ADD = API_URL + '/merc/addBlockCountry';
export const BLOCK_COUNTRY_REMOVE = API_URL + '/merc/removeBlockCountry';

export const MERCHANT_URL_GET_ALL = API_URL + '/admfns/fetchMerchantUrls';
export const MERCHANT_URL_ADD = API_URL + '/admfns/addMerchantUrl';
export const MERCHANT_URL_REMOVE = API_URL + '/admfns/removeMerchantUrl';

export const MERCHANT_IP_GET_ALL = API_URL + '/admfns/fetchMerchantIps';
export const MERCHANT_IP_ADD = API_URL + '/admfns/addMerchantIp';
export const MERCHANT_IP_REMOVE = API_URL + '/admfns/removeMerchantIp';


export const QUICK_PAYMENT_FETCH_ALL = API_URL + '/qpay/fetchQuickPayments';
export const QUICK_PAYMENT_ADD = API_URL + '/qpay/createQuickPayment';
export const QUICK_PAYMENT_REMOVE = API_URL + '/qpay/cancelQuickPayment';
export const QUICK_PAYMENT_CHECKOUT = API_URL + '/res/quickPaymentCheckout';
export const QUICK_PAYMENT_TRANSACTION_GET = API_URL + '/res/retrieveQPTransaction';

export const ACCESS_MENU_ACTION = API_URL + '/admfns/checkAccessByMenuAndAction';
export const TXN_SUMMARY_STATUS_GET = API_URL + '/admfns/fetchMerchantTxnStatusSummary';
export const TXN_SUMMARY_MERCHANT_MONTHS_GET = API_URL + '/admfns/fetchMonthsMerchantTxnSummary';
export const USER_MENU_MAPPINGS = API_URL + '/admfns/userMenuMappings';

export const MERCHANT_TXN_REFUND = API_URL + '/reports/refundMerchantTransaction';
export const AUTH_TOKEN = API_URL + '/txn/AuthToken';
export const EDIT_TERMINAL = API_URL + "/admfns/editTerminal";
