import React, { Component } from 'react';
import ApiService from '../ApiService/ApiService';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow  from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { withStyles, styled } from '@material-ui/styles';

const useStyles = theme => ({
	topCard: {
		marginTop: theme.spacing(1),
		minHeight: '100vh',
	},
	large: {
		width: theme.spacing(10),
		height: theme.spacing(10),
		marginBottom: 10,
	},
	tableHeader: {
		...theme.palette.card.background, fontSize: 18, 
		fontFamily: theme.palette.fontFamily, 
		fontWeight: 500
	},
	tableRowDark: {
		backgroundColor: theme.palette.action.hover,
		color: theme.palette.common.white,
	},
});

class TerminalDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	componentDidMount() {
	}

	render() {
		const { classes, terminals } = this.props; 
		const StyledTableCell = styled(TableCell)(({ theme }) => ({ [`&`]: { fontSize: 14 } }));
		const StyledTableRow = styled(TableRow)(({ theme }) => ({
			"&:nth-of-type(odd)": { backgroundColor: theme.palette.action.hover },
			// hide last border
			"&:last-child td, &:last-child th": { border: 0 },
		}));

		return (<Table>
			<TableHead>
				<TableRow >
					<TableCell align="left" colspan="4" 
					className={classes.tableHeader} style={{backgroundColor: "#FFF"}}>
						Terminal Details</TableCell>
					</TableRow >
				</TableHead>
				<TableBody>
				<StyledTableRow >
					<StyledTableCell align="left">Terminal ID</StyledTableCell>
					<StyledTableCell align="left">Currency </StyledTableCell>
					<StyledTableCell align="left">Card type </StyledTableCell> 
					<StyledTableCell align="left">Live or Test</StyledTableCell> 
				</StyledTableRow >
				{terminals && terminals.map((terminal) => (
					<StyledTableRow >
						<StyledTableCell align="left">{terminal.terminalId}</StyledTableCell>
						<StyledTableCell align="left">{terminal.terminalCurrency} </StyledTableCell>
						<StyledTableCell align="left">{terminal.terminalCardType} </StyledTableCell> 
						<StyledTableCell align="left">
							{terminal.testTerminal? (<>Test</>):(<>Live</>)}					
						</StyledTableCell>
					</StyledTableRow>
				))}                    
			</TableBody>
		</Table>);
	}
}
export default withStyles(useStyles)(TerminalDetails)