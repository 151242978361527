import React, { Component, Fragment } from 'react'
import { withStyles } from "@material-ui/styles";
import { Link, withRouter } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box'; 
import MaterialTable, { MTableToolbar } from 'material-table';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import PageTitle from '../../../components/PageTitle/PageTitle';
import GridContainer from '../../../components/Custom/Grid/GridContainer';
import GridItem from '../../../components/Custom/Grid/GridItem';
import TableIconsComponent from '../../../components/TableLogo/TableIconsComponent';
import ApiService, { showToast, Status } from '../../ApiService/ApiService';
import ObjectUtils from '../../../util/objectUtil';
import CustomInput from '../../../components/Custom/CustomInput/CustomInput';
import Button from '../../../components/Custom/Button/Button';

const useStyles = theme => ({
  theme: theme,
  searchbar: {
	margin: theme.spacing(2),
  },
  formControl: {
    minWidth: 200,
  },

});

class MerchantIPsWhiteListComponent extends Component {

	constructor(props) {
		 super(props)
		 this.state = {
			merchantIps : [],
			responsemessage: null,
			ip: null,
			ipValidation: {}
		}
	 	this.handleChange = this.handleChange.bind(this);
		this.reloadData = this.reloadData.bind(this);
		this.addMerchantIp = this.addMerchantIp.bind(this);
	}

	handleChange(event) {
        this.setState({[event.target.name]: event.target.value, critChanged: true })
    }

	componentDidMount() {
		this.reloadData();
	}

	reloadData() {			
		ApiService.fetchMerchantIps(this.props.merchantId).then((res) => {
			//alert("re.data" + res.data);
			if (res != null) this.setState({merchantIps: res.data});
		});
	}
	
	addMerchantIp() {
		if (ObjectUtils.isNullorEmpty(this.state.ip)) {
			this.setState ({ipValidation : {helperType : Status.ERROR, helperText: "IP is required"}});
			return;
		}
		
		const ipSection = this.state.ip.split(".");
		if (ipSection.length !== 4)	{
			this.setState ({ipValidation : {helperType : Status.ERROR, helperText: "IP is invalid"}});
			return;
		}
		else	{
			let validRange = true;
			ipSection.map ((is) => {
				if (ObjectUtils.NumVal(is) < 0 || ObjectUtils.NumVal(is) > 255) { validRange = false; return; }
			});
			if (!validRange) {
				this.setState ({ipValidation : {helperType : Status.ERROR, helperText: "IP is invalid"}});
				return;		
			}
		}
		
		ApiService.addMerchantIp(this.props.merchantId, this.state.ip).then((res) => {
			if (res != null) {
				if(res.data.status == "OK") {
					showToast({ type: Status.SUCCESS, title: "Add", description: res.data.responseMessage });
					this.setState ({ip: "", ipValidation : {}}, () => this.reloadData());
				} else {
					showToast({ type: Status.ERROR, title: "Error", description: res.data.responseMessage });
				}
			}
		}).catch((e) => {
			showToast({ type: Status.ERROR, title: "Error", description: 1 });
		});
	}

	removeMerchantIp(data) {
		ApiService.removeMerchantIp(this.props.merchantId, data.id).then((res) => {
			if (res != null) {
				this.reloadData();
				showToast({ type: Status.SUCCESS, title: "Delete", description: res.data.responseMessage });
			}
		}).catch((e) => {
			showToast({ type: Status.ERROR, title: "Error", description: e });
	    });
	}

    render() {
        const { classes } = this.props; 
		const { ip, ipValidation } = this.state; 
	 
        return (<>
				<GridContainer>
					<GridItem xs={8} sm={9} md={5} lg={4}>
						<CustomInput title="Merchant IP" field="ip"
							formControlProps={{ fullWidth: true }}
							inputProps={{
								value: ip,
								name: "ip",
								onChange: this.handleChange
							}}
							helperType={ipValidation.helperType}
							helperText={ipValidation.helperText}
						/>
					</GridItem>
					<GridItem xs={4} sm={3} md={7} lg={8}>
						<Button variant="contained" className={classes.searchbar} color="primary" size="sm" onClick={this.addMerchantIp}>
							Add IP
						</Button>
					</GridItem>
				</GridContainer>
				<Divider/>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<>
							<MaterialTable 
							title="Whitelisted IPs"
							icons={TableIconsComponent.icons}
							columns={[ 
								{ field: 'ip',numeric: false, title: 'IP'},
							]}
							data={this.state.merchantIps}
							actions={[						
								{
									tooltip: 'Delete Ip',
									icon: (props)=> <DeleteIcon {...props} />,
									onClick: (evt, data) => this.removeMerchantIp(data),
								}
							]}
							options={{
								headerStyle: { 
									whiteSpace: 'nowrap', backgroundColor: "#fff", 
									color: "#000", fontSize: 12
								},
								search:false,
								exportButton: false,
								pageSize: 10,
								pageSizeOptions: [10,20],
								padding: 'dense',
								showEmptyDataSourceMessage: true,
								emptyRowsWhenPaging: false,
							}}				     
							/>
						</>
				 	</GridItem>
				</GridContainer>
			 </>)
    }
}

export default withStyles(useStyles)(MerchantIPsWhiteListComponent)
