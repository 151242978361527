import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  cardBodyPlain: {
    padding: "0",
    flex: "1 1 auto"
  },
  cardBody: {
    padding: "0.9375rem 1.875rem",
    flex: "1 1 auto"
  }
}));