import React, { Component, Fragment } from 'react'
import { withStyles } from "@material-ui/styles";
import { Link, withRouter } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box'; 
import MaterialTable, { MTableToolbar } from 'material-table';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import PageTitle from '../../../components/PageTitle/PageTitle';
import GridContainer from '../../../components/Custom/Grid/GridContainer';
import GridItem from '../../../components/Custom/Grid/GridItem';
import TableIconsComponent from '../../../components/TableLogo/TableIconsComponent';
import ApiService, { showToast, Status } from '../../ApiService/ApiService';
import { BLOCK_COUNTRY_GET_ALL, BLOCK_COUNTRY_ADD, BLOCK_COUNTRY_REMOVE } from '../../ApiService/URLs';
import ObjectUtils from '../../../util/objectUtil';
import CustomInput from '../../../components/Custom/CustomInput/CustomInput';
import Button from '../../../components/Custom/Button/Button';


const useStyles = theme => ({
	theme: theme,
  searchbar: {
	margin: theme.spacing(2),
  },
  formControl: {
    minWidth: 200,
  },

});

class CountryBlockComponent extends Component {

	constructor(props) {
		 super(props)
		 this.state = {
			blockCountries : [],
			responsemessage: null,
			country: null,
			blockCountryValidation: {}
		}
	 	this.handleChange = this.handleChange.bind(this);
		this.reloadData = this.reloadData.bind(this);
		this.blockCountry = this.blockCountry.bind(this);
	}

	handleChange(event) {
        this.setState({[event.target.name]: event.target.value, critChanged: true })
    }

	componentDidMount() {
		this.reloadData();
	}

	reloadData() {
		ApiService.postAPI(BLOCK_COUNTRY_GET_ALL, {merchantId : this.props.merchantId}).then((res) => {
			if (res != null) {
				if(res.data.status === "success") {
					this.setState({blockCountries: res.data.data});
				} else {
					showToast({ type: Status.ERROR, title: "Error", description: res.data.statusText });
				}
			}
		});
	}
	
	blockCountry() {
		if (ObjectUtils.isNullorEmpty(this.state.country)) {
			this.setState ({blockCountryValidation : {helperType : Status.ERROR, helperText: "Country is required"}});
			return;
		}
	
		ApiService.postAPI(BLOCK_COUNTRY_ADD, { merchantId : this.props.merchantId, countryCode : this.state.country }).then((res) => {
			if (res != null) {
				if(res.data.status === "success") {
					showToast({ type: Status.SUCCESS, title: "Add", description: res.data.message });
					this.setState ({country: "", blockCountryValidation : {}}, () => this.reloadData());
				} else {
					showToast({ type: Status.ERROR, title: "Error", description: res.data.message });
				}
			}
		});
	}

	unblockCountry(data) {
		const reqObj = { merchantId : this.props.merchantId, id: data.id };
		ApiService.postAPI(BLOCK_COUNTRY_REMOVE, reqObj).then((res) => {
			if (res != null) {
				if(res.data.status === "success") {
					this.reloadData();
					showToast({ type: Status.SUCCESS, title: "Delete", description: res.data.message });
				} else {
					showToast({ type: Status.ERROR, title: "Error", description: res.data.message });
				}
			}
		});
	}

    render() {
        const { classes } = this.props; 
		const { country, blockCountryValidation } = this.state; 
	 
        return (<>
				<GridContainer>
					<GridItem xs={8} sm={9} md={5} lg={4}>
						<CustomInput title="Country code" field="country"
							formControlProps={{ fullWidth: true }}
							inputProps={{
								value: country,
								name: "country",
								onChange: this.handleChange
							}}
							helperType={blockCountryValidation.helperType}
							helperText={blockCountryValidation.helperText}
						/>
					</GridItem>
					<GridItem xs={4} sm={3} md={7} lg={8}>
						<Button variant="contained" className={classes.searchbar} color="primary" size="sm" onClick={this.blockCountry}>
							Block Country
						</Button>
					</GridItem>
				</GridContainer>
				<Divider/>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<>
							<MaterialTable 
							title="Blocked Countries"
							icons={TableIconsComponent.icons}
							columns={[ 
								{ field: 'countryCode',numeric: false, title: 'Country Code'},
							]}
							data={this.state.blockCountries}
							actions={[						
								{
									tooltip: 'Unblock Country',
									icon: (props)=> <DeleteIcon {...props} />,
									onClick: (evt, data) => this.unblockCountry(data),
								}
							]}
							options={{
								headerStyle: { 
									whiteSpace: 'nowrap', backgroundColor: "#fff", 
									color: "#000", fontSize: 12
								},
								search:false,
								exportButton: false,
								pageSize: 10,
								pageSizeOptions: [10,20],
								padding: 'dense',
								showEmptyDataSourceMessage: true,
								emptyRowsWhenPaging: false,
							}}
							/>
						</>
				 	</GridItem>
				</GridContainer>
			 </>)
    }
}

export default withStyles(useStyles)(CountryBlockComponent)
