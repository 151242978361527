import React, { Component } from 'react'

import { createMuiTheme, TableRow, TableCell } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import InputAdornment from "@material-ui/core/InputAdornment";

import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'

import CustomInput from '../../../components/Custom/CustomInput/CustomInput';
import Card from '../../../components/Custom/Card/Card'
import CardHeader from '../../../components/Custom/Card/CardHeader'
import CardBody from '../../../components/Custom/Card/CardBody'
import Button from '../../../components/Custom/Button/Button';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

const theme = createMuiTheme({overrides: { MUIDataTableToolbar: { root: { display: 'none' } } }})

const Row = (title, value) => {
	return <GridItem xs={12} sm={12} md={4} lg={4} style={{marginTop: 10, marginBottom: 10}} >
		<Typography variant="h6">{title}</Typography>
		<Typography variant="body1" style={{color: "#777"}}>{value}</Typography>
	</GridItem>
};

const columns = [
    { label: "Product", name: "merchantProductsId", options: { filter: true, sort: true, } },
	{ label: "Unit", name: "unitId", options: { filter: true, sort: true, } },
	{ label: "Amount", name: "totalAmount", options: { filter: true, sort: true, } },
	{ label: "Quantity", name: "quantity", options: { filter: true, sort: true, } },
	{ label: "Tax (%)", name: "tax", options: { filter: true, sort: true, } },
	{ label: "Sub Total", name: "grandTotalAmount", options: { filter: true, sort: true, } },
];

const getProductName = (productList, productId) => {
	const productIdx = _.findIndex(productList, (c) => { return c.id === productId; }, 0);
	return (productIdx > -1) ? productList[productIdx].productName : null;
}

const getUnitName = (unitList, unitId) => {
	//console.log("getUnitName-", unitList);
	const unitIdx = _.findIndex(unitList, (c) => { return c.id === unitId; }, 0);
	return (unitIdx > -1) ? unitList[unitIdx].unitName : null;
}

class InvoiceGenerateReview extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	render() {
		const { invoice, onChange, refData } = this.props;

		// console.log("invoice ", invoice, columns, invoice.products);

		const terminalNames = [];
		const selectedItems = "," + invoice.terminals.join(",") + ",";
		refData.terminalList.map((terminal, idx) => {
			if (selectedItems.indexOf(terminal.terminalId) > -1) 
				terminalNames.push(terminal.label);
		});

		return <Card color="white">
				<CardBody>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: 20, marginBottom: 20}} >
							<Typography variant="h3">Invoice Confirmation</Typography>
							<Button color="primary" style={{float:"right"}} 
								onClick={() => this.props.onSubmit()} toolTipTitle="Submit">Submit</Button>
						</GridItem>
						{Row("Order Description", invoice.description)}
						{Row("Currency", invoice.currency)}
						{Row("Terminal", terminalNames.join(', '))}
						{Row("Order Amount", invoice.totalAmount)}
						{Row("Total Tax", invoice.taxAmount)}
						{Row("Customer Name", invoice.customerName)}
						{Row("Customer Email", invoice.customerEmail)}
						{Row("Your Invoice will expired after", refData.invoiceConfig.expirationPeriodDays + " days")}
						<GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: 20, marginBottom: 20}} >
							<MUIDataTable
								title={"Product Details"}
								data={invoice.products}
								columns={columns}
								//options={{filterType: 'checkbox'}}
								options={{
										selectableRows: false,
										customRowRender: (data, dataIndex, rowIndex) => {
											//console.log("data, dataIndex, rowIndex", data, dataIndex, rowIndex);
											return <TableRow key={rowIndex}>
											{columns.map((col, idx) => {
												let lookupName = null;
												if (col.name === "merchantProductsId")
													lookupName = getProductName(refData.productList, data[idx]);
												else if (col.name === "unitId") 
													lookupName = getUnitName(refData.unitList, data[idx]);

												return <TableCell key={rowIndex + idx}>{lookupName ? lookupName : data[idx]}</TableCell>
												// <TableCell align="right">{cvc}</TableCell>
											})} 
											</TableRow>;
										}
									}}
							/>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		}
}

export default InvoiceGenerateReview;