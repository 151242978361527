import React, { Component, Fragment } from 'react'
import { withStyles } from "@material-ui/styles";
import { Link, withRouter } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box'; 
import MaterialTable, { MTableToolbar } from 'material-table';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import PageTitle from '../../../components/PageTitle/PageTitle';
import GridContainer from '../../../components/Custom/Grid/GridContainer';
import GridItem from '../../../components/Custom/Grid/GridItem';
import TableIconsComponent from '../../../components/TableLogo/TableIconsComponent';
import ApiService, { showToast, Status } from '../../ApiService/ApiService';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ObjectUtils from '../../../util/objectUtil';
import LimitFormComponent from './LimitFormComponent';

const useStyles = theme => ({
 
  searchbar: {
	margin: theme.spacing(2),
  },
  formControl: {
    minWidth: 200,
  },

});

class LimitManagementComponent extends Component {

	constructor(props) {
		 super(props)
		 this.state = {
			terminals : [],
			selectedTerminal: null,
			selectedTerminalLimit: {},
			responsemessage: null
		}
	 	this.handleChange = this.handleChange.bind(this);
		this.reloadTerminals = this.reloadTerminals.bind(this);
	}

	handleChange(event) {
        this.setState({[event.target.name]: event.target.value })
    }

	componentDidMount() {
		this.reloadTerminals();
	}

	reloadTerminals() {			
		ApiService.fetchMerchantTerminals().then((res) => {
			if (res != null) this.setState({ terminals: res.data });
		});
	}
	
    render() {
        const { classes, merchantId } = this.props; 
		const { terminals, selectedTerminal, selectedTerminalLimit, responsemessage } = this.state;

		// console.log ("terminals", terminals, "selectedTerminal", selectedTerminal, 
		// 	"selectedTerminalLimit", selectedTerminalLimit, "responsemessage", responsemessage);
	 
        return (
             <>
				<GridContainer>
					<GridItem xs={12} sm={12} md={8} lg={6}>
						<Autocomplete
							id="terminal-select-demo"
							sx={{ width: 300, padding: "10px" }}
							options={terminals}
							value={selectedTerminal}
							autoHighlight
							getOptionLabel={(option) => ObjectUtils.isNullObject(option) ? "" : option.label}
							onChange={(event, value) => {
								this.setState({selectedTerminal: value});							
							}}
							renderOption={(props, option) => (
								<Box component="li" sx={{}} {...props}>{option.label}</Box>
							)}
							renderInput={(params) => (
								<TextField
								{...params}
								label="Choose a Terminal"
								inputProps={{
									...params.inputProps,
									autoComplete: 'new-password', // disable autocomplete and autofill
								}}
								/>
							)}
						/>
					</GridItem>
				</GridContainer>
				<Divider/>
				<GridContainer>
					<GridItem xs={12} sm={12} md={8} lg={6}>
						<LimitFormComponent selectedTerminal={selectedTerminal} merchantId={merchantId}
							reloadTerminals={this.reloadTerminals}/>
				 	</GridItem>
				</GridContainer>
			 </>
        )
    }
}

export default withStyles(useStyles)(LimitManagementComponent)
