import React, { Component } from 'react'
import { withStyles } from "@material-ui/styles";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ApiService, { showToast, Status } from '../../ApiService/ApiService'
import Spinner from '../../../components/Spinner'

import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'
import { Switch } from '@material-ui/core';

import ObjectUtils from '../../../util/objectUtil';

const sectionList = {
	"databaseVerification" : "Database Verifications",
	"dataSourceVerification" : "Data Source Verifications",
	"profileBasedVerification" : "Profile Based Verification",
	"transactionalBasedDataVerification" : "Transactional based Data Verification"
};

const optionList = {	
	"whiteListDatabase" : "Whitelist Database",
	"negativeDatabase" : "Negative Database",
	"declinedReasonDatabase" : "Declined Reason Database",
	"highRiskDatabase" : "High Risk Database",
	"geoLocationVerification" : "Geo-location Verification",
	"devideIdAndFingerprint" : "Devide Id and Fingerprint",
	"emailVerification" : "Email Verification",
	"binDatabase" : "Bin Database",
	"addressVerification" : "Address verification",
	"emailDomainVerification" : "Email / Domain verification",
	"phoneIdVerification" : "Phone ID verification",	
	"amlWatchlistOFAC" : "AML Watchlist OFAC",
	"amlWatchlistUN" : "AML Watchlist UN",
	"amlWatchlistEU" : "AML Watchlist EU",	
	"amlWatchlistDFAT" : "AML Watchlist DFAT",
	"amlWatchlistPEPS" : "AML Watchlist PEPS",
	"amlWatchlistHMTreasury" : "AML Watchlist HM Treasury",
	"idVerification" : "ID Verification",
	"documentVerification" : "Document Verification",
	"livenessVerification" : "Liveness Verification",
	"phoneVerification" : "Phone Verify/ 4 digits PIN Auth",
	"trafficRulesEngineMonitoring" : "Traffic Rules Engine Monitoring"
};

const data = {
	enableFraudCheck: true,
	sections: {
		databaseVerification : [
			{ rule: "whiteListDatabase", provider: "AcuityTec", status: true },
			{ rule: "negativeDatabase", provider: "AcuityTec", status: true },
			{ rule: "declinedReasonDatabase", provider: "AcuityTec", status: true },
			{ rule: "highRiskDatabase", provider: "AcuityTec", status: true },
		],
		dataSourceVerification : [
			{ rule: "geoLocationVerification", provider: "AcuityTec", status: true },
			{ rule: "devideIdAndFingerprint", provider: "AcuityTec", status: true },
			{ rule: "emailVerification", provider: "AcuityTec", status: true },
			{ rule: "binDatabase", provider: "AcuityTec", status: true },
		],
		profileBasedVerification : [
			{ rule: "addressVerification", provider: "AcuityTec", status: true },
			{ rule: "emailDomainVerification", provider: "AcuityTec", status: true },
			{ rule: "phoneIdVerification", provider: "AcuityTec", status: true },
			{ rule: "amlWatchlistOFAC", provider: "AcuityTec", status: true },
			{ rule: "amlWatchlistUN", provider: "AcuityTec", status: true },
			{ rule: "amlWatchlistEU", provider: "AcuityTec", status: true },
			{ rule: "amlWatchlistDFAT", provider: "AcuityTec", status: true },
			{ rule: "amlWatchlistPEPS", provider: "AcuityTec", status: true },
			{ rule: "amlWatchlistHMTreasury", provider: "AcuityTec", status: true },
			{ rule: "idVerification", provider: "SumSub", status: false },
			{ rule: "documentVerification", provider: "SumSub", status: false }	
		],
		transactionalBasedDataVerification : [
			{ rule: "livenessVerification", provider: "SumSub", status: false },
			{ rule: "phoneVerification", provider: "SumSub", status: false },
			{ rule: "trafficRulesEngineMonitoring", provider: "AcuityTec", status: true }		
		]
	}
};

class FraudFormComponent extends Component {
    constructor(props) {
        super(props)
		 this.state = {
			hasTxnFailed: false,
			acquirerAccounts: [],
			fraudData: ObjectUtils.DeepClone(data)
		}
		this.reloadData = this.reloadData.bind(this);
	}

	reloadData() {	 
	}
 	
	renderAccordion (key, sectionKey, sectionName) {
		const fraudData = this.state.fraudData;

		return  <Accordion defaultExpanded={true} key={key}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} 
				aria-controls="rule-content" id="rule-header" sx={{ backgroundColor: "#eee", borderRadius: "6px" }} >
				<Typography>{sectionName}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{fraudData.sections[sectionKey].map((item, key) => {
					return <GridContainer>
						<GridItem xs={12} sm={12} md={7} lg={8}>
							<Typography>{optionList[item.rule]}</Typography>					
						</GridItem>
						<GridItem xs={8} sm={7} md={3} lg={2}>
							<Typography>{item.provider}</Typography>					
						</GridItem>
						<GridItem xs={4} sm={5} md={2} lg={2}>
							<Switch
								color="primary"
								edge="end"
								name="status"
								value={item.status}
								onChange={(e) => {} }
								checked={item.status === true}
							/>
						</GridItem>
					</GridContainer>
					})
				}				
			</AccordionDetails>
		</Accordion>;
	}

	render() {
		const { classes, selectedTerminal } = this.props;
	    return (!selectedTerminal ? <></> 
			: <GridContainer>
				{Object.keys(sectionList).map((sectionKey, key) => {
					return <GridItem xs={12} sm={12} md={12} lg={12}>
						{this.renderAccordion(key, sectionKey, sectionList[sectionKey])}
					</GridItem>
				})}
			</GridContainer>
		);
	}
}
export default FraudFormComponent;