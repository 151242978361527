import React, { Component } from 'react'

import { createMuiTheme, TableRow, TableCell } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Typography from '@material-ui/core/Typography';

import { ArrowBackIos } from "@material-ui/icons";
import ApiService from '../../ApiService/ApiService';

import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'

import Card from '../../../components/Custom/Card/Card'
import CardHeader from '../../../components/Custom/Card/CardHeader'
import CardBody from '../../../components/Custom/Card/CardBody'
import Button from '../../../components/Custom/Button/Button';

const Row = (title, value) => {
	return <GridItem xs={12} sm={12} md={4} lg={4} style={{marginTop: 10, marginBottom: 10}} >
		<Typography variant="h6" weight="bold">{title}</Typography>
		<Typography variant="body1" weight="light">{value}</Typography>
	</GridItem>
};

const columns = [
    { label: "Product", name: "merchantProductsId", options: { filter: true, sort: true, } },
	{ label: "Unit", name: "unitId", options: { filter: true, sort: true, } },
	{ label: "Amount", name: "totalAmount", options: { filter: true, sort: true, } },
	{ label: "Quantity", name: "quantity", options: { filter: true, sort: true, } },
	{ label: "Tax (%)", name: "tax", options: { filter: true, sort: true, } },
	{ label: "Sub Total", name: "grandTotalAmount", options: { filter: true, sort: true, } },
];

class InvoiceView extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			terminalList: []
		}
	}

	componentDidMount(){
		this.getTerminal();	
	}

	getTerminal() {
		ApiService.fetchMerchantTerminals().then((res) => {	
			this.setState({terminalList: res.data});
		});
	}

    render() {
		const { invoice, onBack } = this.props;
		const { terminalList } = this.state;

		// console.log("invoice - ", invoice);

		const terminalNames = [];
		if (invoice.terminals != null) {
			const selectedTerminals = [];
			invoice.terminals.map(terminal => selectedTerminals.push(terminal.terminalId));

			const selectedItems = "," + selectedTerminals.join(",") + ",";
			terminalList.map((terminal, idx) => {
				if (selectedItems.indexOf(terminal.terminalId) > -1) 
					terminalNames.push(terminal.label);
			});
		}

		//console.log("invoice ", invoice, columns, invoice.products);
		return <Card color="white">
				<CardBody>
					<GridContainer>
						<GridItem xs={3} sm={2} md={1} lg={1} >
							<Button simple color="transparent" size="lg" justIcon round 
									toolTipTitle={"Back to Invoice History"} onClick={() => onBack()}><ArrowBackIos /></Button>
						</GridItem>
						<GridItem xs={9} sm={10} md={11} lg={11} style={{marginTop: 20, marginBottom: 20}} >
							<Typography variant="h3">Invoice</Typography>
						</GridItem>
						{Row("Invoice ID", invoice.id)}
						{Row("Order Description", invoice.description)}
						{Row("Currency", invoice.currency)}
						{Row("Terminal", terminalNames.join(', '))}
						{Row("Order Amount", invoice.totalAmount)}
						{Row("Total Tax", invoice.taxAmount)}
						{Row("Customer Name", invoice.customerName)}
						{Row("Customer Email", invoice.customerEmail)}
						{/* {Row("Your Invoice will expired after", refData.invoiceConfig.expirationPeriodDays + " days")} */}
						<GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: 20, marginBottom: 20}} >
							<MUIDataTable
								title={"Product Details"}
								data={invoice.products}
								columns={columns}
								//options={{filterType: 'checkbox'}}
								options={{
										selectableRows: false,
										customRowRender: (data, dataIndex, rowIndex) => {
											//console.log("data, dataIndex, rowIndex", data, dataIndex, rowIndex);
											return <TableRow key={rowIndex}>
											{columns.map((col, idx) => {
												let lookupName = null;
												if (col.name === "merchantProductsId")
													lookupName = invoice.products[rowIndex].product.productName;
												else if (col.name === "unitId") 
													lookupName = invoice.products[rowIndex].unit.unitName;

												return <TableCell key={rowIndex + idx}>{lookupName ? lookupName : data[idx]}</TableCell>
												// <TableCell align="right">{cvc}</TableCell>
											})} 
											</TableRow>;
										}
									}}
							/>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		}
}

export default InvoiceView;