import React, { useState } from "react";
import { TextField, Button , Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import { useForm } from "react-hook-form";

// styles
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";

//components

//pages
import ApiService from '../ApiService/ApiService';


export default function StoreManagement(props) {
  var classes = useStyles();
  var theme = useTheme();

  const [merchant, setMerchant] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [storeLocation, setstoreLocation] = useState("");
  const [message, setMessage] = useState("");

  const { register, handleSubmit, formState: { errors } } = useForm();

  React.useEffect(() => {
    fetchBusinessDetails();          
  },[]);


  const fetchBusinessDetails = (() => {    

    ApiService.getUserMerchant().then((res) => {
      if (res != null) setMerchant(res.data);               
    });        

  });

  const addNewStore = (() => {
    ApiService.addNewStore(merchant.id,storeName, storeLocation).then((res) => {
      if (res != null) setMessage("Success");
      else setMessage("Failure");
    }).catch(error => {
      setMessage("Failure");
    });
  });

  return (
    <>
      
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}        
        autoComplete="off"
      >
        <TextField name="storeName" type="text" label="Store Name" variant="outlined" 
          className="input-field"
          {...register('storeName', { required: true })}
          onChange={(e) => {setStoreName(e.target.value); setMessage("");  }}
        />
         {errors.storeName && errors.storeName.message}
      

        <TextField name="storeLocation" id="outlined-basic" label="Store Location" variant="outlined"
         type="text"
         className="input-field"
         {...register('storeLocation', { required: true })}
         onChange={(e) => {
              setstoreLocation(e.target.value);
              setMessage("");
        }}/>     
        {errors.storeLocation && errors.storeLocation.storeLocation}         
     
        <Button variant="contained" color="primary" onClick={addNewStore} style={{width:300, height:42, marginLeft:10 ,marginTop: '1em', marginRight: '1em'}}> Add Store</Button>
        <Typography weight="medium" style={{color:"#009933"}}>{message}</Typography>
      </Box>
   

    </>
  );
}
