import { makeStyles } from "@material-ui/styles";
import { isMobile } from "react-device-detect";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
    fontFamily: 'Roboto',  
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(isMobile ? 0 : 2),
    // width: isMobile ? `calc(100vw - 50px)` : `calc(100vw - 240px)`,
    minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    '&:not(:first-child)': {
      paddingLeft: 15
    }
  }
}));
