import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,  
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@mui/material";
import classnames from "classnames";
import { withRouter, Link, BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useSnackbar } from "notistack";
import { setToast } from "../ApiService/ApiService";
import { isMobile } from "react-device-detect";

// styles
import useStyles from "./styles";

// logo
//import logo from "./logo.svg";
import google from "../../images/google.svg";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";
import Button from '../../components/Custom/Button/Button';
import { AppBar, Toolbar } from "@material-ui/core";

let logo = require ('../../environment/client/logo/' + process.env.REACT_APP_CLIENT + '-logo.svg').default;
let innerLogo = require ('../../environment/client/logo/' + process.env.REACT_APP_CLIENT + '-logo.png').default;

const logoWidth = process.env.REACT_APP_LOGO_WIDTH ? process.env.REACT_APP_LOGO_WIDTH : "60";

function Login(props) {
  var classes = useStyles();

  const { enqueueSnackbar: showSnackbar, closeSnackbar } = useSnackbar();
  
  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  const loginSubmit = () => {
    setToast(showSnackbar, closeSnackbar);
    
    loginUser(
      userDispatch,
      loginValue,
      passwordValue,
      props.history,
      setIsLoading,
      setError,
    )
  }

  const bannerPercent = process.env.REACT_APP_BANNER_WIDTH ? `${process.env.REACT_APP_BANNER_WIDTH}%` : "80%";

  return (
    <Grid container className={classes.container} style={{overflow: "hidden", backgroundColor: "#FFF"}}>
      {isMobile ?
        <AppBar position="fixed" color="transparent" sx={{
          backgroundColor: '#FFF',
          width: "100vw",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          transition: (theme) => theme.transitions.create(["margin"], {
            easing: (theme) => theme.transitions.easing.sharp,
            duration: (theme) => theme.transitions.duration.leavingScreen,
          }),
        }}>
          <Toolbar className={classes.toolbar}>        
            <img src={innerLogo} alt={process.env.REACT_APP_CLIENT} width={logoWidth}/>
            <div className={classes.grow} />        
          </Toolbar>
        </AppBar>
        :
        <div className={classes.logotypeContainer}>
          <img src={logo} alt={process.env.REACT_APP_CLIENT} style={{width: bannerPercent}}/>
          {/* <Typography className={classes.logotypeText}>Payment Gateway</Typography>        */}
        </div>
      }
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} sx={{backgroundColor: "#FFF"}}/>
            <Tab label="New User" classes={{ root: classes.tab }} sx={{backgroundColor: "#FFF"}} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>                             
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password
                </Typography>
              </Fade>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Username"
                type="text"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() => loginSubmit()}
                    variant="contained"
                    color="primary"
                  >
                    Login
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.forgetButton}
                >
                  Forget Password
                </Button>
              </div>
            </React.Fragment>
          )}
          {activeTabId === 1 && (
            <React.Fragment>
              <Typography variant="h3" className={classes.greeting}>
                Welcome!
              </Typography>
              <Typography variant="h5" className={classes.subGreeting}>
                Create your account
              </Typography>
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password
                </Typography>
              </Fade>
              <TextField
                id="name"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={nameValue}
                onChange={e => setNameValue(e.target.value)}
                margin="normal"
                placeholder="Full Name"
                type="text"
                fullWidth
              />
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                    onClick={() => loginSubmit()}
                    disabled={
                      loginValue.length === 0 ||
                      passwordValue.length === 0 ||
                      nameValue.length === 0
                    }
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    Create your account
                  </Button>
                )}
              </div>
              <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography className={classes.formDividerWord}>or</Typography>
                <div className={classes.formDivider} />
              </div>
              <Button
                className={classnames(
                  classes.googleButton,
                  classes.googleButtonCreating,
                )}
              >
                <img src={google} alt="google" className={classes.googleIcon} />
                &nbsp;Sign in with Google
              </Button>
            </React.Fragment>
          )}
        </div>
        <Typography className={classes.copyright} variant="body2" color="textSecondary" align="center">
          {'Copyright ©'}
          <Link color="inherit" to={`https://${process.env.REACT_APP_CLIENT}.com/`}>
            {process.env.REACT_APP_CLIENT}.com
                </Link>{' '}
          {2020}{'.'}
		    </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
