import React, { Component } from 'react';
import { isMobile } from "react-device-detect";
import ApiService from '../ApiService/ApiService';
import Documents from './merchant/onboarding/Documents';
import Divider from '@material-ui/core/Divider';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import AmendUserForm from './AmendUserForm';
import BusinessUserForm from './BusinessUserForm';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { withStyles, styled  } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'; 
import Grid from '@material-ui/core/Grid'; 
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow  from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Button from '../../components/Custom/Button/Button';

import { 
  Person as PersonIcon,
  Business as BusinessIcon,
  ContentCopy as ContentCopyIcon,
  DeviceHub as DeviceHubIcon,
  Webhook as WebhookIcon
} from "@mui/icons-material";

import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import 'react-tabs/style/react-tabs.css';
import apipdf from './api.pdf';
import PageTitle from "../../components/PageTitle/PageTitle";
import TerminalDetails from './TerminalDetails';
import CustomTabPanel from '../../components/Custom/CustomTabs/CustomTabPanel';

const useStyles = theme => ({
	button: {
		margin: theme.spacing(1),
	},
  tableHeader: {
		...theme.palette.card.background, fontSize: 18, 
		fontFamily: theme.palette.fontFamily, 
		fontWeight: 500
	},
	testMode: {
		backgroundColor: '#ffd67d',
	},	
	liveMode: {
		backgroundColor: '#a4cc89',
	},
	tabs: { "& .MuiTabs-indicator": { backgroundColor: theme.palette.primary.main }, },
	tab: { "&.MuiButtonBase-root.MuiTab-root": { backgroundColor: "#FFF", color:"#000" }, }
});
  
  function TabPanel(tabPanelProps) {
  	const { children, value, index, ...other } = tabPanelProps;
  	return (
      <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      >
      {value === index && (
        <Box sx={{ p: 3 }}>
         <Typography>{children}</Typography>
        </Box>
      )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  } 
  

class Home extends Component {
  constructor(props) {
    super();
    this.state = {
      merchant: [],
      tabIndex: 0,
      signKey: null,
      newSecret: null,
      failed: false,
      success: false,
      responsemessage: null,
      terminals: [],
      canModify: false,
      merchOnboarded: false,
      editingMerchant: false,
      editingUser: false,
      currUser: null,
      usrFirstName: "",
      numPages: null,
      pageNumber: 1,
    };

    this.handleChange = this.handleChange.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.generateSecret = this.generateSecret.bind(this);
    this.updateSignkey = this.updateSignkey.bind(this);
    this.updateSecretOnServer = this.updateSecretOnServer.bind(this);
    this.generateNewSignkey = this.generateNewSignkey.bind(this);
    this.fetchTerminals = this.fetchTerminals.bind(this);
    this.fetchUserAccess = this.fetchUserAccess.bind(this);
    this.fetchOnboarded = this.fetchOnboarded.bind(this);
    this.fetchBusinessDetails = this.fetchBusinessDetails.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
	  this.handleTabChange = this.handleTabChange.bind(this);
	  this.handleTabChangeIndex = this.handleTabChangeIndex.bind(this);
  }

  handleTabChange(event, newValue) {
    this.setState({ tabIndex: newValue });
  }

  handleTabChangeIndex(index) {
    this.setState({ tabIndex: index });
  }

  componentDidMount() {
    this.fetchBusinessDetails();
    this.fetchOnboarded();
    this.fetchTerminals();
    this.fetchUserAccess();
  }

  fetchBusinessDetails() {
    ApiService.getUserMerchant().then((res) => {
      if (res != null) this.setState({ merchant: res.data, signkey: res.data.signkey });
      //console.log(res.data);
    });
  }

  fetchOnboarded() {
    ApiService.getCurrentUser().then((res) => {
      if (res != null) this.setState({ currUser: res.data, merchOnboarded: res.data.onboarded });
    });
  }

  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages });
  }

  reloadData() {}

  fetchTerminals() {
    ApiService.fetchMerchantTerminals().then((res) => {
      this.setState({ terminals: res.data });
    });
  }

  fetchUserAccess() {
    ApiService.checkAccessByMenuAndAction("Home", "AMEND").then((res) => {
      if (res && res.data != null && res.data.id != null) {
        this.setState({ canModify: true });
      }
    });
  }

  generateNewSignkey() {
    const skey = this.generateSignkey();
    this.setState({ signKey: skey });
  }

  updateSignkey() {
    if (this.state.signKey && this.state.signKey != "") {
      ApiService.updateMerchantSignkey(
        this.state.merchant.id,
        this.state.signKey,
      ).then((res) => {
        if (res != null) {
          if (res.data.status === "OK") {
            this.setState({
              success: true,
              failed: false,
              responsemessage: res.data.responseMessage,
            });
          } else {
            this.setState({
              success: false,
              failed: true,
              responsemessage: res.data.responseMessage,
            });
          }
        }
      });
    }
  }
  generateSecret() {
    const pass = this.generatePassword();
    this.setState({ newSecret: pass });
  }
  updateSecretOnServer() {
    if (this.state.newSecret && this.state.newSecret != "") {
      ApiService.updateMerchantSecret(
        this.state.merchant.id,
        this.state.newSecret,
      ).then((res) => {
        if (res != null) {
          if (res.data.status === "OK") {
            this.setState({
              success: true,
              failed: false,
              responsemessage: res.data.responseMessage,
            });
          } else {
            this.setState({
              success: false,
              failed: true,
              responsemessage: res.data.responseMessage,
            });
          }
        }
      });
    }
  }

  generatePassword = (
    length = 20,
    wishlist = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@-#$",
  ) =>
    Array(length)
      .fill("")
      .map(
        () =>
          wishlist[
            Math.floor(
              (crypto.getRandomValues(new Uint32Array(1))[0] /
                (0xffffffff + 1)) *
                wishlist.length,
            )
          ],
      )
      .join("");

  generateSignkey = (
    length = 12,
    wishlist = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
  ) =>
    Array(length)
      .fill("")
      .map(
        () =>
          wishlist[
            Math.floor(
              (crypto.getRandomValues(new Uint32Array(1))[0] /
                (0xffffffff + 1)) *
                wishlist.length,
            )
          ],
      )
      .join("");

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  render() {
    const { classes } = this.props;
    const { tabIndex } = this.state;
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <Paper style={{ margin: "1em", padding: "1em", backgroundColor: "#FFF" }}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={6}>
            <PageTitle title="Welcome" />
          </Grid>
          <Grid item xs={4}>
            {this.state.merchOnboarded ? (
              <Chip
                label="Onboarding status : Live"
                className={classes.liveMode}
              />
            ) : (
              <>
                <Chip
                  label="Onboarding status : Pending"
                  className={classes.testMode}
                />
                <Tooltip title="Please complete merchant profile and upload documents to get approval, once you are approved and Live you can start processing payments">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Grid>
        </Grid>
        <Divider light className={classes.searchbar} />
        {this.state.failed && (
          <Alert severity="error" elevation="0">
            {" "}
            {this.state.responsemessage}
          </Alert>
        )}
        {this.state.success && (
          <Alert severity="success" elevation="0">
            {this.state.responsemessage}
          </Alert>
        )}
        {this.state.merchant && (
          <Box sx={{ bgcolor: "background.paper" }}>
            <AppBar position="static" style={{backgroundColor: "#FFF"}}>
              <Tabs
                value={tabIndex}
                onChange={this.handleTabChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="home tabs"
				        className={classes.tabs}
              >
                <Tab icon={isMobile ? null : <PersonIcon />} iconPosition="start" label="User Details" {...a11yProps(0)} className={classes.tab}/>
                <Tab icon={isMobile ? null : <BusinessIcon />} iconPosition="start" label="Business Details" {...a11yProps(1)} className={classes.tab} />
                <Tab icon={isMobile ? null : <ContentCopyIcon />} iconPosition="start" label="Merchant Documents" {...a11yProps(2)} className={classes.tab} />
                <Tab icon={isMobile ? null : <DeviceHubIcon />} iconPosition="start" label="Terminal Details" {...a11yProps(3)} className={classes.tab} />
                <Tab icon={isMobile ? null : <WebhookIcon />} iconPosition="start" label="API Access Details" {...a11yProps(4)} className={classes.tab} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={"x"}
              index={tabIndex}
              onChangeIndex={this.handleTabChangeIndex}
            >
              <CustomTabPanel value={tabIndex} index={0}>
                {this.state.currUser && (
                  <AmendUserForm
                    user={this.state.currUser}
                    handler={this.fetchOnboarded}
                  />
                )}
              </CustomTabPanel>
              <CustomTabPanel value={tabIndex} index={1}>
                {this.state.merchant && (
                  <BusinessUserForm
                    user={this.state.merchant}
                    handler={this.fetchBusinessDetails}
                  />
                )}
              </CustomTabPanel>
              <CustomTabPanel value={tabIndex} index={2}>
                <Documents />
              </CustomTabPanel>
			        <CustomTabPanel value={tabIndex} index={3}>
                <TerminalDetails terminals={this.state.terminals} />
              </CustomTabPanel>
              <CustomTabPanel value={tabIndex} index={4}>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="left" colspan="2"
                        className={classes.tableHeader} style={{backgroundColor: "#FFF"}}
                      >
                        API Access details
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell align="left" width="50%">
                        Merchant Id
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {this.state.merchant.id}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell align="left">
                        Signkey (Keep secure)
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Box align="left">
                          {this.state.canModify ? (
                            <>
                              <Typography> {this.state.signkey} </Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                size="sm" 
                                onClick={this.generateNewSignkey}
                                style={{ marginTop: "1em", marginRight: "1em" }}
                              >
                                Generate
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                size="sm" 
                                onClick={this.updateSignkey}
                                style={{ marginTop: "1em" }}
                              >
                                Update
                              </Button>
                            </>
                          ) : (
                            <Typography>
                              {" "}
                              You do not have permission to view this{" "}
                            </Typography>
                          )}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell align="left">
                        API secret (Please note down and secure the generated
                        value; If you log out or browser is closed the value
                        will dissapear and you need to generate again, the user
                        readable value is not persisted for security reasons.)
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Box align="left">
                          <Typography> {this.state.newSecret} </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            size="sm" 
                            onClick={this.generateSecret}
                            style={{ marginTop: "1em", marginRight: "1em" }}
                          >
                            Generate
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="sm" 
                            onClick={this.updateSecretOnServer}
                            style={{ marginTop: "1em" }}
                          >
                            Update
                          </Button>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell align="left">
                        Merchant Integration API Manual
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <Button
                          href={apipdf}
                          target="_blank"
                          variant="contained"
                          color="primary"
                          size="sm" 
                          style={{ marginTop: "1em", marginRight: "1em" }}
                        >
                          View
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </CustomTabPanel>
            </SwipeableViews>
          </Box>
        )}
      </Paper>
    );
  }
}

export default withStyles(useStyles)(Home)
