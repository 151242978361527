import React, { Component } from 'react';
import ApiService from '../ApiService/ApiService';
import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 

import { 
  TaskAlt as TaskAltIcon,
  Block as BlockIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  SettingsEthernet as SettingsEthernetIcon
 } from "@mui/icons-material";

import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import 'react-tabs/style/react-tabs.css';

import PageTitle from "../../components/PageTitle/PageTitle";
import CustomTabPanel from '../../components/Custom/CustomTabs/CustomTabPanel';
import LimitManagementComponent from './Limit/LimitManagementComponent';
import FraudManagementComponent from './Fraud/FraudManagementComponent';
import Spinner from '../../components/Spinner';
import WhitelistComponent from './Whitelist/WhitelistComponent';
import BlockComponent from './Block/BlockComponent';

const useStyles = theme => ({
	button: {
		margin: theme.spacing(1),
	},
	searchbar: {
		margin: theme.spacing(2),
	},
	testMode: {
		backgroundColor: '#ffd67d',
	},	
	liveMode: {
		backgroundColor: '#a4cc89',
	},
	tabs: { "& .MuiTabs-indicator": { backgroundColor: theme.palette.primary.main, height: 3 }, },
	tab: { "&.MuiButtonBase-root.MuiTab-root": { backgroundColor: "#FFF", color:"#000" }, }
});
  
function a11yProps(index) {
	return {
	  id: `full-width-tab-${index}`,
	  'aria-controls': `full-width-tabpanel-${index}`,
	};
  } 
  

class Settings extends Component {
  constructor(props) {
    super();
    this.state = {
      tabIndex: 0,
      merchantId: null
    };

	this.handleTabChange = this.handleTabChange.bind(this);
	this.handleTabChangeIndex = this.handleTabChangeIndex.bind(this);
  }

  handleTabChange(event, newValue) {
    this.setState({ tabIndex: newValue });
  }

  handleTabChangeIndex(index) {
    this.setState({ tabIndex: index });
  }

  componentDidMount() {
    const request = async () => { 
			const res = await ApiService.getUserMerchant();
			const data = await res.data;
			this.setState({merchantId: data.id});
		}
		request();
  }

  render() {
    const { classes } = this.props;
    const { tabIndex, merchantId } = this.state;

    return (
      <Paper  style={{ margin: "1em", padding: "1em" }} >
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={6}>
            <PageTitle title="Settings" />
          </Grid>          
        </Grid>
        <>
          <AppBar position="static" style={{backgroundColor: "#FFF"}}>
            <Tabs
              value={tabIndex}
              onChange={this.handleTabChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="settings tabs"
              className={classes.tabs}
            >
              <Tab icon={<TaskAltIcon />} iconPosition="start" label="Whitelisting" {...a11yProps(0)} className={classes.tab}/>
              <Tab icon={<BlockIcon />} iconPosition="start" label="Block" {...a11yProps(1)} className={classes.tab}/>              
              <Tab icon={<AdminPanelSettingsIcon />} iconPosition="start" label="Fraud Management" {...a11yProps(2)} className={classes.tab} />
              <Tab icon={<SettingsEthernetIcon />} iconPosition="start" label="Limits Management" {...a11yProps(3)} className={classes.tab} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={"x"}
            index={tabIndex}
            onChangeIndex={this.handleTabChangeIndex}
          >
            <CustomTabPanel value={tabIndex} index={0}>
              {merchantId == null ? <Spinner /> : <WhitelistComponent merchantId={merchantId} />}
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={1}>
              {merchantId == null ? <Spinner /> : <BlockComponent merchantId={merchantId} />}
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={2}>
              {merchantId == null ? <Spinner /> : <FraudManagementComponent merchantId={merchantId} />}
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={3}>
              {merchantId == null ? <Spinner /> : <LimitManagementComponent merchantId={merchantId} />}
            </CustomTabPanel>
          </SwipeableViews>
        </>
      </Paper>
    );
  }
}

export default withStyles(useStyles)(Settings)
