import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography';
import InputAdornment from "@material-ui/core/InputAdornment";
import { LanguageOutlined } from "@material-ui/icons";

import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'

import CustomInput from '../../../components/Custom/CustomInput/CustomInput';
import Card from '../../../components/Custom/Card/Card'
import CardHeader from '../../../components/Custom/Card/CardHeader'
import CardBody from '../../../components/Custom/Card/CardBody'

class InvoiceConfigDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			config: props.config,
			onChange: props.onChange
		}
	}

	render() {
		const { config, onChange } = this.state;

		//console.log("InvoiceConfigDetails", config);

		return <Card color="white">
				<CardBody>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<Typography variant="h5">Details</Typography>
						</GridItem>
						{/* <GridItem xs={12} sm={12} md={6} lg={6}>
							<CustomInput
								title="Redirect URL"
								field="redirectUrl"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									startAdornment: (
									<InputAdornment position="start">
										<LanguageOutlined style={{color: "#495057"}} />
									</InputAdornment>
									),
									defaultValue: config.redirectUrl,
									name: "redirectUrl",
									id: "redirectUrl",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"Redirect URL"}
							/>
						</GridItem> */}
						<GridItem xs={12} sm={12} md={3} lg={3}>
							<CustomInput
								title="Expiration Period (In Days)"
								field="expirationPeriodDays"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{										
									defaultValue: config.expirationPeriodDays,
									name: "expirationPeriodDays",
									id: "expirationPeriodDays",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"Expiration Period (In Days)"}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={3} lg={3}>
							<CustomInput
								title="GST/VAT (In %)"
								field="tax"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									defaultValue: config.tax,
									name: "tax",
									id: "tax",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"Redirect URL"}
							/>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		}
}

export default InvoiceConfigDetails;