import React, { Component } from 'react';
import { Box, FormControl, Input, FormControlLabel, Checkbox,TextField, FormHelperText } from '@material-ui/core';
import ApiService from '../ApiService/ApiService';
import Alert from '@material-ui/lab/Alert';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow  from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { withStyles, styled, tableCellClasses  } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import Button from '../../components/Custom/Button/Button';

const useStyles = theme => ({
	topCard: {
		marginTop: theme.spacing(1),
		minHeight: '100vh',
	},
	large: {
		width: theme.spacing(10),
		height: theme.spacing(10),
		marginBottom: 10,
	},
	tableHeader: {
		...theme.palette.card.background, fontSize: 18, 
		fontFamily: theme.palette.fontFamily, 
		fontWeight: 500
	},
  	tableRowDark: {
		backgroundColor: theme.palette.action.hover,
	 	color: theme.palette.common.white,
 	},
});

const validEmailRegex = RegExp(
	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const validateForm = errors => {
	let valid = true;
	Object.values(errors).forEach(val => val.length > 0 && (valid = false));
	return valid;
  };

class BusinessUserForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:'',
			merchantName: '',
            contactEmail: '',
            address: '',
            city: '',
			country: '',
			zip: '',
			telephone: '',
			fax: '',
			orgRegistrationNumber: '',
			industry: '',
			website: '',
			responseMessage: null,
			failed: false,
			success: false, 
			editingMerchant: false,
			errors: {
				contactEmail: '',
				telephone: '',
				country: '',
			}
		}
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this); 
		this.startEditMerchant = this.startEditMerchant.bind(this);
		this.stopEditMerchant = this.stopEditMerchant.bind(this);
		this.fetchUser = this.fetchUser.bind(this);
	}
	componentDidMount() {
		this.fetchUser();
	}	

	handleInputChange(event) {

		event.preventDefault();
		const { name, value } = event.target;
		let errors = this.state.errors;	

		switch (name) {
			case 'telephone': 
				errors.telephone = 
				isNaN(value) || value.length != 10
					? 'Phone number not valid!'
					: '';			
				break;
			case 'contactEmail': 
				errors.contactEmail = 
				validEmailRegex.test(value)
					? ''
					: 'Contact Email is not valid!';
				break;
			case 'country': 
				errors.country = 
				value.length < 2
					? 'Country should only have 2 chars'
					: '';
				break;
			default:
				break;
		}

		this.setState({errors, [name]: value});
		
	}
	   
	
	handleSubmit() {

		if(validateForm(this.state.errors)) {

			var request = Object.assign({}, this.state);
			ApiService.updateUserMerchant(request).then((res)=>{
				if (res != null) {
					if(res.data.status === 'OK') {				
						this.setState({success: true, failed: false, responseMessage: res.data.responseMessage});
						this.stopEditMerchant();				
					} else {
						this.setState({success: false, failed: true, responseMessage: res.data.responseMessage});
					}
				}
			}) 

		}else{

			this.setState({success: false, failed: true, responseMessage: 'Invalid Form'});
			
		}
	}
	
	fetchUser() {
		ApiService.getUserMerchant().then((res) => {
			if (res != null) {
				this.setState({
					id: res.data.id!=null?res.data.id:'',
					merchantName: res.data.merchantName!=null?res.data.merchantName:'',
					contactEmail: res.data.contactEmail!=null?res.data.contactEmail:'',
					address: res.data.address!=null?res.data.address:'',
					city: res.data.city!=null?res.data.city:'',
					country: res.data.country!=null?res.data.country:'',
					zip: res.data.zip!=null?res.data.zip:'',
					telephone: res.data.telephone!=null?res.data.telephone:'',
					fax: res.data.fax!=null?res.data.fax:'',
					orgRegistrationNumber: res.data.orgRegistrationNumber!=null?res.data.orgRegistrationNumber:'',
					industry: res.data.industry!=null?res.data.industry:'',
					website: res.data.website!=null?res.data.website:''
				})
			}
		})
	}
	startEditMerchant() {
		this.setState({editingMerchant: true});	
	}
	stopEditMerchant(){
		this.fetchUser();
		this.setState({editingMerchant: false});
	}
	render() {
		const { classes } = this.props; 
		const {errors} = this.state;
	 
		return (
			<>
		 	 {this.state.failed && <Alert severity="error" elevation="0"> {this.state.responseMessage}</Alert>}
						{this.state.success && <Alert severity="success" elevation="0">{this.state.responseMessage}</Alert>}
				<Table>
				<TableHead> 
					 	<TableRow>
							<TableCell width="30%" align="left" className={classes.tableHeader}>Business Details</TableCell>
							<TableCell align="right" className={classes.tableHeader}>
                                {this.state.editingMerchant?
									<Button variant="contained" onClick={this.stopEditMerchant} 
										color="primary" size="sm">Cancel</Button>
								:
									<Button variant="contained" onClick={this.startEditMerchant} 
										startIcon={<EditIcon />} color="primary" size="sm">Edit</Button>
								}
							</TableCell>
						</TableRow >
				 </TableHead>  
				 <TableBody>
						<TableRow>
							<TableCell align="left">Merchant Id</TableCell>
							<TableCell align="left"> 
                                {this.state.id}
							</TableCell>
						</TableRow >
						<TableRow  className={classes.tableRowDark}>
							<TableCell align="left">Business name</TableCell>
							<TableCell align="left">
                            {this.state.editingMerchant?												
                                <TextField value={this.state.merchantName} placeholder="Business name" name="merchantName" onChange={this.handleInputChange}/>                                
                            :
                                <>{this.state.merchantName}</>
                            }
							</TableCell>
						</TableRow >
						<TableRow >
							<TableCell align="left">Business Email</TableCell>
							<TableCell align="left">
                            {this.state.editingMerchant?                                
                                <TextField value={this.state.contactEmail} name="contactEmail" onChange={this.handleInputChange} placeholder="Contact email"/>                              
                            :
                                <>{this.state.contactEmail}</>
                            }
							{errors.contactEmail.length > 0 && 
                				<span className='error'>{errors.contactEmail}</span>}
							</TableCell>
						</TableRow >
						<TableRow  className={classes.tableRowDark}>
							<TableCell align="left">Business Address</TableCell>
							<TableCell align="left">
                            {this.state.editingMerchant?  
								<>
								<TextField value={this.state.address} name="address" onChange={this.handleInputChange} placeholder="Address"/><br/>
								<TextField value={this.state.city} name="city" onChange={this.handleInputChange} placeholder="City"/><br/>
								<TextField value={this.state.country} name="country" onChange={this.handleInputChange} placeholder="Country (Only 2 char code)"/><br/>
								<TextField value={this.state.zip} name="zip" onChange={this.handleInputChange} placeholder="Zip"/>
								</>                     
                            :
                                <>{this.state.address},{this.state.city},{this.state.state},{this.state.country}, {this.state.zip}</>
                            }
							{errors.country.length > 0 && 
                				<span className='error'>{errors.country}</span>}
								</TableCell>
						</TableRow >
						<TableRow >
							<TableCell align="left">Business Telephone</TableCell>
							<TableCell align="left">
                            {this.state.editingMerchant?                              									
								<TextField value={this.state.telephone} name="telephone" onChange={this.handleInputChange} placeholder="Business Telephone"/>                                                                  
                            :
                                <>{this.state.telephone}</>
                            }
							{errors.telephone.length > 0 && 
                				<span className='error'>{errors.telephone}</span>}
							</TableCell>
						</TableRow >
						<TableRow  className={classes.tableRowDark}>
							<TableCell align="left">Business Fax</TableCell>
							<TableCell align="left">
                            {this.state.editingMerchant?
                                
                                <TextField value={this.state.fax} name="fax" onChange={this.handleInputChange} placeholder="Business fax"/>
                                
                            :
                                <>{this.state.fax}</>
                            }
							</TableCell>							
						</TableRow >
						<TableRow >
							<TableCell align="left">Business Registration number</TableCell>
							<TableCell align="left">
                            {this.state.editingMerchant?
                                <TextField value={this.state.orgRegistrationNumber} name="orgRegistrationNumber" onChange={this.handleInputChange} placeholder="Business Registration number"/>
                            :
                                <>{this.state.orgRegistrationNumber}</>
                            }
							</TableCell>
						</TableRow >
						<TableRow  className={classes.tableRowDark}>
							<TableCell align="left">Business Industry</TableCell>
							<TableCell align="left">
							{this.state.editingMerchant?
                                <TextField value={this.state.industry} name="industry" onChange={this.handleInputChange} placeholder="Business Industry"/>
                            :
                                <>{this.state.industry}</>
                            }

							</TableCell>
						</TableRow >
						<TableRow >
							<TableCell align="left">Website</TableCell>	
							<TableCell align="left">						
							{this.state.editingMerchant?
                                <TextField value={this.state.website} onChange={this.handleInputChange} placeholder="Business website"/>
                            :
                                <>{this.state.website}</>
                            }                        
							</TableCell>        							 
						</TableRow >
						{this.state.editingMerchant &&
						<TableRow >
							<TableCell colSpan="2" align="center"><Button variant="contained" onClick={this.handleSubmit}  color="primary">Update</Button></TableCell>
						</TableRow>
						}
					</TableBody>
				 </Table>
				    
			  </>
		);
	}
}
export default withStyles(useStyles)(BusinessUserForm)