import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography';
import InputAdornment from "@material-ui/core/InputAdornment";
import { LanguageOutlined } from "@material-ui/icons";

import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'

import CustomInput from '../../../components/Custom/CustomInput/CustomInput';
import Card from '../../../components/Custom/Card/Card'
import CardHeader from '../../../components/Custom/Card/CardHeader'
import CardBody from '../../../components/Custom/Card/CardBody'
import { Switch, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import CustomTextArea from '../../../components/Custom/CustomTextArea/CustomTextArea';

class InvoiceConfigPaymentTerms extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {
		const { config, onChange } = this.props;

		return <Card color="white">
				<CardBody>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<Typography variant="h5">Payment Terms</Typography>
						</GridItem>
						<GridItem xs={8} sm={4} md={3} lg={2} style={{marginTop: "8px"}}>
							<Typography variant="body1">Is late fee applicable?</Typography>							
						</GridItem>
						<GridItem xs={4} sm={8} md={9} lg={10}>
							<Switch
								color="primary"
								edge="end"
								name="lateFeeApplicable"
								value={config.lateFeeApplicable}
								onChange={(e) => onChange(e.target.name, e.target.checked) }
								checked={config.lateFeeApplicable === true}
							/>
						</GridItem>
						<GridItem xs={6} sm={3} md={2} lg={2}>
							<CustomInput
								title="Due Days"
								field="dueDays"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: "dueDays",									
									defaultValue: config.dueDays,
									name: "dueDays",
									id: "dueDays",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"Due Days"}
							/>
						</GridItem>
						<GridItem xs={6} sm={4} md={4} lg={2}>
							<CustomInput
								title="Late Fee"
								field="lateFee"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{										
									defaultValue: config.lateFee,
									name: "lateFee",
									id: "lateFee",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"Late Fee"}
							/>
						</GridItem>
						<GridItem xs={12} sm={5} md={6} lg={2}>
							<FormControl fullWidth>
								<InputLabel id="unit-select-label">Unit</InputLabel>
								<Select style={{minWidth:"150px", marginTop: "30px", width: "100%"}}
									labelId="unit-select-label"
									id="unit-type-select"
									name="lateFeeType"
									value={config.lateFeeType}
									label="Unit"
									onChange={(e) => onChange(e.target.name, e.target.value)}
									>
									<MenuItem value="FLAT">Flat</MenuItem>
									<MenuItem value="PCNT">% Percentage</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs={12} sm={12} md={12} lg={12}>
						<CustomTextArea
							title="Terms & Conditions"
							field="termnsAndConditions"
							rows={6}
							formControlProps={{ fullWidth: true }}
							inputProps={{
								defaultValue: config.termnsAndConditions,
								name: "termnsAndConditions",
								onChange: (e) => onChange(e.target.name, e.target.value)}
							}
							helperText="Terms & Conditions"
							/>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		}
}

export default InvoiceConfigPaymentTerms;