import React, { Fragment, Component } from 'react';
import { withStyles } from "@material-ui/styles";
import moment from 'moment';

//components
import TableIcons from '../Table/TableLogoComponent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { InputLabel, 
	Select,
	MenuItem, 
	Box,  
	FormControl, 
	Input, 
	FormControlLabel, 
	Checkbox,
	TextField, 
	FormHelperText,
	Grid 
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { Delete, InfoOutlined, AddBox, Refresh } from '@material-ui/icons';
import QRCode from 'react-qr-code';
import { TableRow, TableCell } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import PageTitle from "../../components/PageTitle/PageTitle";
import CustomTooltip from "../../components/Custom/Tooltip/Tooltip";

//pages
import ApiService from '../ApiService/ApiService';
import Button from '../../components/Custom/Button/Button';
import GridContainer from '../../components/Custom/Grid/GridContainer';
import GridItem from '../../components/Custom/Grid/GridItem';

//style
//import useStyles from "./styles";

const useStyles = theme => ({
	root: {
		display: 'flex',
	},
});

const columns = [
	{ name:'qpId', label:'Qpid', options: { filter: true, sort: true, } },
	{ name:'terminalId', label: 'Terminal', options: { filter: true, sort: true, } },
	{ name:'amount', label: 'Amount', type: 'numeric', options: { filter: true, sort: true, } },
	{ name:'currency', label: 'Currency', type: 'numeric', options: { filter: true, sort: true, } },
	{ name:'remarks', label: 'Remarks', type: 'numeric', options: { filter: true, sort: true, } },
	{ name:'paymentLink', label:'Payment Link', },
	{ name:'qrCode', label:'QR Code', },
	{ name:'created', label:'Created', options: { filter: true, sort: true }},	
	{ name:'status', label:'Status', options: { filter: true, sort: true, filterList: ["SALE_STARTED"] } },
	{ name:' ', label:'' }
  ];

class QuickPaymentComponent extends Component {
	constructor(props) {
	  super();
	  let params = window.location.origin;
	  this.state = {
		payments: [],		
		failed: false,
		success: false,
		responsemessage: null,
		amount: null,
		terminalId: null,
		terminals: [],	
		showCreate: false,	
		currency: null,
		reamarks: null,
		location: params,
		qrvalue : null,
		showQRCodePanelVisible : false,
	  }
		this.createPayment = this.createPayment.bind(this);
		this.fetchPayments = this.fetchPayments.bind(this);
		this.cancelPayment = this.cancelPayment.bind(this);
		this.showCreateDialogue = this.showCreateDialogue.bind(this);
		this.hideCreateDialogue = this.hideCreateDialogue.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleTerminalChange = this.handleTerminalChange.bind(this);

		this.showQRCodePanel = this.showQRCodePanel.bind(this);
		this.hideQRCodePanel = this.hideQRCodePanel.bind(this);
	}

	showQRCodePanel(value) {
		this.setState({qrvalue: value ,showQRCodePanelVisible: true});
	}
	hideQRCodePanel() {
		this.setState({showQRCodePanelVisible: false})
	}

	componentDidMount(){
   		this.fetchPayments();
		this.fetchTerminals();
		
  	} 
	fetchPayments() {	
		ApiService.fetchQuickPayments().then((res) => {
			if (res != null) this.setState({payments: res.data!=null?res.data:[] })
		}).catch(error => {
			console.log(error);
		  });
		//console.log(this.state.payments);
	}
	fetchTerminals() {
		ApiService.fetchMerchantTerminals().then((res) => {
			if (res != null) this.setState({terminals: res.data})
		});
	}
	cancelPayment(qpId) {
		//console.log("qpId --- ", qpId);
		ApiService.cancelQuickPayment(qpId).then((res) => {
			if (res != null) {
				if(res.data.status === 'OK') {				
					this.setState({success: true, failed: false, responsemessage: res.data.responseMessage})
					this.fetchPayments();
				} else {
					this.setState({success: false, failed: true, responsemessage: res.data.responseMessage})
				}
			}
		})		
	}
	showCreateDialogue(){
		this.setState({showCreate: true});
	}
	hideCreateDialogue(){
		this.setState({showCreate: false});
	}
	createPayment() {
		ApiService.createQuickPayment(this.state.terminalId.terminalId, this.state.amount, this.state.remarks).then((res) => {
			if (res != null) {
				if(res.data.status=='OK') {
					this.setState({success: true, failed: false, responsemessage: res.data.responseMessage, showCreate: false})
					this.fetchPayments();
				} else {
					this.setState({success: false, failed: true, responsemessage: res.data.responseMessage, showCreate: false})
				}
			}
		})
		
	}
	handleInputChange(event) {
		const target = event.target;
		const inputName = target.name;
		const inputValue = target.value;
		this.setState({
			[inputName]: inputValue
		});
	}
	handleTerminalChange(event) {
		this.setState({ terminalId: event.target.value, currency: event.target.value.terminalCurrency });
		 
	}
	render() {
	const { classes } = this.props;	
	
	const CustomToolbar = (displayData => {
		return (
			<GridContainer style={{alignItems: "right"}}>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<Tooltip title={"Create payment link"} interactive>
						<Button color="primary" size="sm" startIcon={<AddBox />} onClick={() => this.showCreateDialogue()}>Create Payment</Button>
					</Tooltip>
					<Tooltip title={"Refresh table"} interactive>
						<Button color="primary" size="sm" startIcon={<Refresh />} onClick={(event) => this.fetchPayments()}>Refresh</Button>
					</Tooltip>
				</GridItem>
			</GridContainer>
		);
	});

    return (<React.Fragment>
			<Paper style={{padding: '1em'}}>
				<PageTitle title="Quick Pay" />
				{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
				{this.state.success && <Alert severity="success" elevation="0">{this.state.responsemessage}</Alert>}
				<MUIDataTable
					title="Quick Pay" 					 					  
					columns={columns}
					data={this.state.payments}					  
					options={{
						padding: 'dense',
						selectableRows: false, 
						SelectableRows: 'single',
						pageSize: 30,
						pageSizeOptions: [30,45,60],
						filterType: "checkbox",
						customToolbar: CustomToolbar,
						customRowRender: (data, dataIndex, rowIndex) => {
							//console.log("data, dataIndex, rowIndex", data, dataIndex, rowIndex);
							return <TableRow key={rowIndex}>
							{columns.map((col, idx) => {
								const cellValue = data[idx];
								const qpId = data[0];
								return <TableCell key={rowIndex + idx} style={{ verticalAlign: 'top' }} >
								{
								(col.name === "amount") ?
									<Typography variant="body2">{cellValue && cellValue.toFixed(2)}</Typography>
								: (col.name === "paymentLink") ?
									<a target="_blank" href={`${process.env.REACT_APP_CHECKOUT_URL}${qpId}`}>
										{process.env.REACT_APP_CHECKOUT_URL}{qpId}</a>
								: (col.name === "qrCode") ?
									<Button size="sm" color="primary" 
										onClick={() => this.showQRCodePanel(qpId)}>QRCode</Button>
								: (col.name === " ") ?
									<Button color="danger" size="sm" justIcon round toolTipTitle="Cancel payment"
										onClick={() => this.cancelPayment(qpId)}><Delete /></Button>
								: (col.name === "qpId" || col.name === "terminalId") ?
									<CustomTooltip title={cellValue} copy={true}>
										<Button simple color="transparent" size="lg" justIcon round><InfoOutlined /></Button>
									</CustomTooltip>
								: (col.name === "created") ? 
									moment(cellValue).format("YYYY-MM-DD")
								: cellValue
								}
								</TableCell>
							})} 
							</TableRow>;
						}
					}}				     
				/>

				<Dialog open={this.state.showQRCodePanelVisible} onClose={this.hideQRCodePanel}>
					<DialogTitle id="customized-dialog-title" onClose={this.hideQRCodePanel}>
						QR Code
					</DialogTitle>
					<DialogContent>
					<QRCode size={256}
						style={{ height: "auto", maxWidth: "100%", width: "100%" }} viewBox={`0 0 256 256`}
						value={`${process.env.REACT_APP_CHECKOUT_URL}${this.state.qrvalue}`} />
					</DialogContent>															
				</Dialog>

				<Dialog open={this.state.showCreate} onClose={this.hideCreateDialogue}>
				<DialogTitle id="customized-dialog-title" onClose={this.hideCreateDialogue}>
					Create Quick payment link
				</DialogTitle>
				<DialogContent>
						<form>
							<FormControl fullWidth margin="dense">
								<InputLabel id="terminalIdLable">Terminal</InputLabel>
								<Select value={this.state.terminalId} error={this.state.terminalId==null}
									onChange={this.handleTerminalChange} name="terminalId" 
									labelId="terminalIdLable" id="terminalId" aria-describedby="terminalId-helper-text" >
									<MenuItem key="" value="">&nbsp; </MenuItem>
									{this.state.terminals && this.state.terminals.map((mt) =>
										<MenuItem key={mt.terminalId} value={mt} dense>{mt.label + "-" + mt.terminalCardType}</MenuItem>
									)}
								</Select>
								{this.state.terminalId==null &&	<FormHelperText id="terminalId-helper-text" error>Value is required</FormHelperText>}
							</FormControl>
								<Grid container>
								{this.state.currency && 
								<Grid item xs={2}>
									<TextField readonly value={this.state.currency} placeholder="Currency"  margin="dense"/>
										
								</Grid>}
								<Grid item xs={this.state.currency?10:12 } >
									<TextField name="amount" placeholder="Amount" fullWidth  margin="dense" error={this.state.amount==null}
									value={this.state.amount} onChange={this.handleInputChange} required helperText={this.state.amount==null 
									&& "Value is required" }/>  
								</Grid>
									</Grid>
								<TextField name="remarks" placeholder="Remarks" fullWidth  margin="dense"  
								value={this.state.remarks} onChange={this.handleInputChange}  /> 
						</form>	
					<Box align="center">
						<Button onClick={this.createPayment} variant="contained" color="primary" >Create</Button>
					</Box>
				</DialogContent>
			</Dialog> 
		</Paper>
		</React.Fragment>
    );
  }
}

export default withStyles(useStyles)(QuickPaymentComponent);