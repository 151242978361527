import React, { useState , useEffect} from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

//components
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GoogleMapReact from 'google-map-react';
import Widget from "../../components/Widget";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { 
	Button, Grid, Typography
} from '@material-ui/core';

// styles
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";
import PageTitle from "../../components/PageTitle/PageTitle";

//pages
import ApiService from '../ApiService/ApiService';
import './marker.css';

//data

const Marker = (props) => {
    const { color, name, id } = props;
    return (
      <div className="marker"
        style={{ backgroundColor: color, cursor: 'pointer'}}
        title={name}
      />
    );
};

const defaultProps = {
    center: {
      lat: 19.200465586146503,
      lng: 72.8732298679999
    },
    zoom: 11
};

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function Terminal(props) {

    var classes = useStyles();
    var theme = useTheme();

    const [store, setStore] = React.useState('');
    const [status, setStatus] = React.useState('');

    
    const [value, setValue] = React.useState('1');
    const [lat, setLat] = React.useState('');
    const [long, setLong] = React.useState('');

    const [merchant, setMerchant] = React.useState([]);
    const [terminals, setTerminals] = React.useState([]);

    React.useEffect(() => {

        fetchBusinessDetails();         
       
    },[]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        //fetchTerminal(merchant.id);
    };

    const handleSearch = (event) => {       
        fetchTerminal(merchant.id);
    };
    
    const fetchTerminal = ((merId) => {
        //console.log(merId);
        ApiService.fetchTerminals(merId,store).then((res) => {
            if (res != null) setTerminals(res.data);        
        });
        
    })
    
    const fetchBusinessDetails = (() => {    

        ApiService.getUserMerchant().then((res) => {
            if (res != null) setMerchant(res.data);               
        });        
    
    });

    return (
        <>
        <FormControl  sx={{ m: 1, minWidth: 320 }}>
       
        <InputLabel id="demo-multiple-name-label">Stores</InputLabel>
        <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={store}
            label="Store"
            onChange={(event) => {
                setStore(event.target.value);
                switch(event.target.value){
                    case 'Mumbai':
                        setLat(19.224106339258487);
                        setLong(72.84993270396062);  
                        break;
                    case 'Delhi':
                        setLat(28.552630413067135);
                        setLong(77.18934366173805);  
                        break;
                    case 'Hyderabad':
                        setLat(17.420670233409698);
                        setLong(78.47254734224906);  
                        break;
                    default:
                        break;
                }
                         
            }}
        >
            <MenuItem value={'Mumbai'}>Mumbai</MenuItem>
            <MenuItem value={'Delhi'}>Delhi</MenuItem>
            <MenuItem value={'Hyderabad'}>Hyderabad</MenuItem>
        </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 320 }} >

        <InputLabel id="demo-multiple-name-label">Status</InputLabel>
        <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={status}
            label="Status"
            onChange={(event) => setStatus(event.target.value)}
        >
            <MenuItem value={'Active'}>Active</MenuItem>
            <MenuItem value={'InActive'}>InActive</MenuItem>
            <MenuItem value={'Online'}>Online</MenuItem>
            <MenuItem value={'Offline'}>Offline</MenuItem>
        </Select>

        </FormControl>

        <Button variant="contained" onClick={handleSearch} color="primary" style={{width:300, height:42, marginLeft:10 ,marginTop: '1em', marginRight: '1em'}}>Search</Button>

        <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Maps" value="1" />
                <Tab label="List" value="2" />
               
            </TabList>
            </Box>
            <TabPanel value="1">                
            <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyA16d9FJFh__vK04jU1P64vnEpPc3jenec" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >
                    <Marker
                        lat={lat}
                        lng={long}
                        name="My Marker"
                        color="blue"
                    />                
                </GoogleMapReact>
            </div>
            </TabPanel>

            <TabPanel value="2">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                {terminals.map((t) => ( 

                    <Grid item xs={6}>
                    <Widget
                        title={t.terminalName}
                        upperTitle
                        disableWidgetMenu={true}
                        className={classes.card}
                        bodyClass={classes.fullHeightBody}
                       style={{marginRight:10,marginLeft:10}}

                    >
                        <Typography className={classes.model}> {t.terminalModel}</Typography>

                        {t.terminalStatus=='Active' ? 
                            <>
                                <Stack direction="row" spacing={1}>
                                <Chip label={t.terminalStatus} color="success" variant="outlined" size="small"/>
                                </Stack>
                            </>                                                      
                            :
                            <>
                                <Stack direction="row" spacing={1}>
                                <Chip label={t.terminalStatus} color="primary" variant="outlined" size="small"/>
                                </Stack>
                            </>                            

                        }                    
                       <br/>

                       <Typography className={classes.model}> {t.terminalLocation} </Typography>
                        
                    </Widget>
                    </Grid>

                ))}                                
            </Grid>
            </TabPanel>
        </TabContext>
        </Box>

        </>
   
  );
}
