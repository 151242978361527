import React, {Component} from 'react';
import { withStyles } from "@material-ui/styles";
import { Link, Grid, Select, MenuItem, InputLabel, FormControl, Avatar, 
	CircularProgress, Table, TableBody,TableCell, TableHead, TableRow, TableSortLabel, TablePagination,
	Paper, Typography, Chip, Box, Divider, TextField, Input, Dialog, DialogTitle, DialogContent,
	FormControlLabel, Backdrop, Checkbox, Accordion, AccordionSummary, AccordionDetails,
	FormHelperText } from '@material-ui/core';
	
import ApiService from '../ApiService/ApiService';
import SearchIcon from '@material-ui/icons/Search';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportOutlined from '@material-ui/icons/ReportOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialTable from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import {subMonths, subDays, format} from 'date-fns';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ReverseIcon from '@material-ui/icons/SettingsBackupRestore';
import Alert from '@material-ui/lab/Alert';
import CustomTooltip from '../../components/Custom/Tooltip/Tooltip';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import PageTitle from "../../components/PageTitle/PageTitle";
import GridContainer from '../../components/Custom/Grid/GridContainer';
import GridItem from '../../components/Custom/Grid/GridItem';
import Button from '../../components/Custom/Button/Button';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


const useStyles = theme => ({
	theme: theme,
  button: {
    margin: theme.spacing(1),
  },
  tablewraptext: {
	textOverflow: 'ellipsis', 
    overflow: 'hidden', 
    whiteSpace: 'nowrap',
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(4),
  },
  medium: {
    width: '45px',
    height: '15px', 
  },
  chipRoot: {
	display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  searchbar: {
	margin: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
	marginTop: 8,
    marginLeft: -65,
  },
   crossError: {
    color: theme.palette.error.main,
  },
  formControl: { 
    marginLeft: 0,
    minWidth: "100%"
  },
  menuText: {
  	fontWeight: theme.typography.fontWeightMedium,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  accordHeader: {
	backgroundColor: theme.palette.gray.main,
	fontWeight: theme.typography.fontWeightMedium,
 },
 ruleGrid: {
 	border: '1px solid',
    borderColor: '#7e807f',
	padding: '1em',
 },
greenText: {
	color: 'green'
},
redText: {
	color: 'red'
},
ruleGridContainer: {
	border: '1px solid',
    borderColor: '#7e807f',
},
 middleGrid: {
 	borderLeft: '1px solid',
	borderRight: '1px solid',
    borderColor: '#7e807f',
	padding: '1em',
 },
});

class ChargebackReportComponent extends Component {
	
	constructor(props) {
        super()
        this.state = {
            transactions: [],
			result: [],
			merchantId: null,
            message: null,
			fromDate: subDays(new Date(),20),
			toDate: new Date(),
			order: 'desc',
			orderBy: 'Date',
			loading: false,
			statusCriteria:['ALL'],
			orderNoCriteria: null,
			email: null,
			refundReason: '',
			refundPanelVisible: false,
			refundAmount: 0.00,
			refundTxnId: null,
			success: false,
			numberRowPerPage: 20,
	        totalRow: 0,
		    pageNumber:0,
			rowsPerPageOptions: [100,200,300],
			allChbk: true,
			showDateMsg: true,
			showFraudCheckDialog: false,
			txnFraudInfo:null
        }
       this.reloadTransactions = this.reloadTransactions.bind(this); 
       this.handleStatusSelectChange = this.handleStatusSelectChange.bind(this); 
       this.handleOrderNoChange = this.handleOrderNoChange.bind(this);
       this.handleEmailChange = this.handleEmailChange.bind(this);
 	   this.refundTransaction = this.refundTransaction.bind(this);
	   this.hideRefundPanel = this.hideRefundPanel.bind(this);
	   this.handleClose = this.handleClose.bind(this);
	   this.handleChange = this.handleChange.bind(this);
 	   this.handleChangeRowPerPage = this.handleChangeRowPerPage.bind(this);
	   this.handleChangePage = this.handleChangePage.bind(this);
	   this.searchTransactions = this.searchTransactions.bind(this);
 		this.viewFraudInfo = this.viewFraudInfo.bind(this);
	   this.hideFraudCheckDialog = this.hideFraudCheckDialog.bind(this);
    }
	componentDidMount() {
        {/* this.reloadTransactions();*/}
    }
	searchTransactions() {
		this.setState({pageNumber: 0});
		this.reloadTransactions(0);	
	}
	reloadTransactions(pageNo) { 
		this.setState({loading: true});
        ApiService.fetchMerchantTransactions(format(this.state.fromDate,'yyyyMMdd'), 
        	format(this.state.toDate,'yyyyMMdd'), this.state.statusCriteria, this.state.orderNoCriteria, this.state.email, pageNo, this.state.numberRowPerPage, this.state.allChbk)
            .then((res) => {
				if (res != null) {
                	this.setState({totalRow: res.data.totalRow, result: res.data.transactions,merchantId: res.data.merchantId,loading: false})
				}
            }).catch((e) => {
				
			});
    }
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }
	handleChangeRowPerPage(data) {
		
	}
	
	handleChangePage(e, pageNo) {
	
		this.setState({pageNumber: pageNo});
		this.reloadTransactions(pageNo);
	}
 	handleStatusSelectChange(event) {
 		this.setState({statusCriteria: event.target.value})
 	}
 	handleOrderNoChange(event) {
 		this.setState({orderNoCriteria: event.target.value})
 	}
 	handleEmailChange(event) {
 		this.setState({email: event.target.value})
 	}

	handleClose() {
		this.setState({loading: false});
	}
	
	showRefundPanel(txn) {
		this.setState({refundPanelVisible: true, refundAmount: txn.amount, refundTxnId: txn.merchantTransactionId})
	}
	hideRefundPanel() {
		this.setState({refundPanelVisible: false});
	}
	refundTransaction() {
		this.setState({loading: true});
		ApiService.refundMerchantTransaction(this.state.refundTxnId, this.state.refundReason, this.state.refundAmount).then((res)=> {
			if (res != null) {
				if(res.data.status=='SUCCESS')
					this.setState({responsemessage: "Refund initiated successfully", success: true, failed: false})
				else
					this.setState({responsemessage: "Refund request failed", success: false, failed: true})

				this.reloadTransactions(0);
			}
		}).catch((e)=> {
			this.setState({responsemessage: "Error refunding transaction "+ e, success: false, failed: true})
		});
		this.setState({refundPanelVisible: false,refundAmount: 0.00, refundTxnId: null});	
	}
	viewFraudInfo(txn) { 
		ApiService.fetchFraudInfoForTxn(txn.merchantTransactionId).then((res) => {
			if (res != null && res.data != null) 
				this.setState({txnFraudInfo: res.data, showFraudCheckDialog: true});			
		})
	}
	hideFraudCheckDialog(){
		this.setState({showFraudCheckDialog: false});
	}
	
	
	headCells = [
	  { id: 'Terminal_Id', numeric: false, disablePadding: false, label: 'Terminal Id' },
	  { id: 'Date', numeric: false, disablePadding: false, label: 'Date' },
	  { id: 'Txn_Id', numeric: false, disablePadding: false, label: 'Txn Id' },
	  { id: 'Type', numeric: false, disablePadding: false, label: 'Type' },
	  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },	  
	  { id: 'Amount', numeric: true, disablePadding: false, label: 'Amount' },		  
	  { id: 'Currency', numeric: false, disablePadding: false, label: 'Currency' },
	];
	headCells2 = [
	  { id: 'Date', numeric: false, disablePadding: false, label: 'Date' }, 
	  { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
	  { id: 'baseCurrency', numeric: false, disablePadding: false, label: 'Base Currency' },
	  { id: 'baseAmount', numeric: true, disablePadding: false, label: 'Base Amount' },	  
	  { id: 'Captured', numeric: false, disablePadding: false, label: 'Captured amnt' },
 	  { id: 'ChargBck', numeric: false, disablePadding: false, label: 'Chargeback amnt' },
	  { id: 'gatewayResponse', numeric: false, disablePadding: false, label: 'Billing desc' }, 
	  { id: 'txnInfo', numeric: false, disablePadding: false, label: 'Txn Info' }, 
	];
	headCells3 =[
		{ id: 'Captured', numeric: false, disablePadding: false, label: 'Captured amnt' },
 	  	{ id: 'ChargBck', numeric: false, disablePadding: false, label: 'Chargeback amnt' },
		{ id: 'gatewayResponse', numeric: false, disablePadding: false, label: 'Billing desc' }, 
		{ id: 'gatewayResponse', numeric: false, disablePadding: false, label: 'TrackerId' }, 
		{ id: 'txnInfo', numeric: false, disablePadding: false, label: 'Txn Info' }, 
		{ id: 'gatewayResponse', numeric: false, disablePadding: false, label: 'SignInfo' }, 
	 
	];
	ITEM_HEIGHT = 50;
	ITEM_PADDING_TOP = 8;
 	MenuProps = {
	  PaperProps: {
	    style: {
	      maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
	      width: 250,
	    },
	  },
	};
 	tableIcons = {
	    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
	  };
	render(){
		const { classes } = this.props;
		return (
			<React.Fragment>
			  <Paper style={{padding: '1em'}}>
			  <PageTitle title="Chargeback Transactions" />
			   {this.state.failed &&  <Alert severity="error" elevation={0}> {this.state.responsemessage}</Alert> }
			   {this.state.success &&  <Alert severity="success" elevation={0}> {this.state.responsemessage}</Alert> }
				<Divider light className={classes.searchbar}/>
				<GridContainer>
					<GridItem xs={6} sm={6} md={3} lg={2}>
						<MuiPickersUtilsProvider utils={DateFnsUtils} >
							<DatePicker autoOk label="From" format="MM/dd/yyyy" value={this.state.fromDate} 
								onChange={(val)=> this.setState({fromDate: val})} />
						</MuiPickersUtilsProvider>
					</GridItem>
					<GridItem xs={6} sm={6} md={3} lg={2}>
						<MuiPickersUtilsProvider utils={DateFnsUtils} >
							<DatePicker autoOk label="To" format="MM/dd/yyyy" value={this.state.toDate}
								onChange={(val) =>  this.setState({toDate: val})} />
						</MuiPickersUtilsProvider>
					</GridItem>
					<GridItem xs={12} sm={12} md={3} lg={2}>
						<FormControl className={classes.formControl}>
							<InputLabel id="statusSelectLabel">Status</InputLabel>
							<Select multiple 
									value={this.state.statusCriteria}  MenuProps={this.MenuProps}
									onChange={this.handleStatusSelectChange} 
									labelId="statusSelectLabel" id="statusSelect" >
								<MenuItem key={"SALE_SUCCESSFUL"} value={"SALE_SUCCESSFUL"} dense>SALE_SUCCESSFUL</MenuItem>
								<MenuItem key={"SALE_FAILED"} value={"SALE_FAILED"} dense>SALE_FAILED</MenuItem>
								<MenuItem key={"SALE_PENDING"} value={"SALE_PENDING"} dense>SALE_PENDING</MenuItem>
								<MenuItem key={"REFUND_SUCCESSFUL"} value={"REFUND_SUCCESSFUL"} dense>REFUND_SUCCESSFUL</MenuItem>
								<MenuItem key={"REFUND_FAILED"} value={"REFUND_FAILED"} dense>REFUND_FAILED</MenuItem>
								{/* <MenuItem key={"CHARGEBACK"} value={"CHARGEBACK"} dense>CHARGEBACK</MenuItem>*/}
								<MenuItem key={"SETTLED"} value={"SETTLED"} dense>SETTLED</MenuItem>
								<MenuItem key={"ALL"} value={"ALL"} dense>ALL</MenuItem>
							</Select>
							
						</FormControl>
					</GridItem>
					<GridItem xs={6} sm={6} md={3} lg={2}>
						<TextField id="orderNo" label="Order Number" onChange={this.handleOrderNoChange} className={classes.formControl}/>
					</GridItem>
					<GridItem xs={6} sm={6} md={3} lg={2}>
						<TextField id="email" label="Customer email" onChange={this.handleEmailChange} className={classes.formControl}/>
					</GridItem>
					<GridItem xs={6} sm={5} md={3} lg={5} style={{ marginTop: '1em', marginBottom: '1em'}}>
					{ this.state.showDateMsg && 
						<Typography noWrap variant="caption">(Dates max 1 month apart)</Typography>
					}
					</GridItem>
					<GridItem xs={6} sm={6} md={3} lg={2} style={{ marginTop: '1em', marginBottom: '1em'}}>
						<Button
							variant="contained"
							color="primary"
							size="sm"
							className={classes.button}
							startIcon={<SearchIcon />}
							onClick={this.searchTransactions}
						>
							Search
						</Button>
						<Backdrop open={this.state.loading} className={classes.backdrop} onClick={this.handleClose}><CircularProgress color="inherit" /></Backdrop>
					</GridItem>
				</GridContainer>
				<>
		      	<MaterialTable
			      title=""
				  icons={this.tableIcons} 
				  components={{
						Pagination: props => (
	                      <TablePagination
	                           {...props}
	                          rowsPerPageOptions={[]}
                      		  rowsPerPage={this.state.numberRowPerPage}
	                          count={this.state.totalRow}
		                      page={this.state.pageNumber}
		                      onChangePage={(e, page) =>
		                        this.handleChangePage(e, page)
		                      }
		                      onChangeRowsPerPage={event => {
		                        //props.onChangeRowsPerPage(event);
		                        this.handleChangeRowPerPage(event.target.value);
		                      }}
	                    />
	                  ),
				  }}
			      columns={[
					{ field: 'terminalId', title: 'Terminal',render: rowData => <>
					  <CustomTooltip title={rowData.terminalId} copy={true}><InfoOutlinedIcon/></CustomTooltip>
					</>
				   },
				   { field: 'createdDisplay', title: 'Date', type:'date', render: rowData => <Typography noWrap variant="subtitle2">{rowData.createdDisplay}</Typography> },
				   { field: 'merchantTransactionId' , title: 'Txn Id', render: rowData => <>
				   		<CustomTooltip title={rowData.merchantTransactionId} copy={true}><InfoOutlinedIcon/></CustomTooltip>
					</>},
				   { field: 'merchantOrderNo' , title: 'Ord No', render: rowData => <>
						<CustomTooltip title={rowData.merchantOrderNo} copy={true}><InfoOutlinedIcon/></CustomTooltip>
					</>},
				   {field: 'cardType', title: 'Type',cellStyle: {fontSize: 12,fontFamily: "sans-serif",},},
				   {field: 'statuses[0].transactionStatus', title: 'Status', cellStyle: {fontSize: 12,fontFamily: "sans-serif",},},					
				   {field: 'amount', title: 'Amount', type: 'numeric', render: rowData=><Typography variant="subtitle2">{rowData.amount && rowData.amount.toFixed(2)}</Typography> },					
				   {field: 'currency', title: 'Currency', cellStyle: {fontSize: 12,fontFamily: "sans-serif",},},
				   {field: 'customerEmail', title: 'Cust Email', cellStyle: {fontSize: 12,fontFamily: "sans-serif",},},
					
			      ]}
			      data={this.state.result}  
				  localization={{
					toolbar: {
			            exportName: 'Export as XL'
			        },
				  }}
				  actions={[ 
					rowData => ({
						tooltip: 'Fraud Info',
						icon: (props)=> <Button variant="contained" color="primary" size="small" startIcon={<ReportOutlined {...props}/>} > <Typography variant="caption">Fraud info</Typography> </Button>,
						onClick: (evt, rowData) => this.viewFraudInfo(rowData),
						
					}),
					 rowData => ({
						tooltip: 'Refund transaction',
						icon: (props)=> <Button variant="contained" color="primary" size="small" startIcon={<ReverseIcon {...props}/>} disabled={!(rowData.statuses[0].transactionStatus === 'SALE_SUCCESSFUL' || rowData.statuses[0].transactionStatus === 'REFUND_FAILED')} > Refund </Button>,
						onClick: (evt, rowData) => this.showRefundPanel(rowData),
						disabled: !(rowData.statuses[0].transactionStatus === 'SALE_SUCCESSFUL' || rowData.statuses[0].transactionStatus === 'REFUND_FAILED'),
					})
				  ]}
					
				  options={{
					headerStyle: { 
						whiteSpace: 'nowrap', backgroundColor: "#fff", 
						color: "#000", fontSize: 12
					},
					search:true,
			        exportButton: true,
					exportCsv: (columns, data) => {
						ApiService.fetchTransactionsXL(format(this.state.fromDate,'yyyyMMdd'), 
        				format(this.state.toDate,'yyyyMMdd'), this.state.statusCriteria, this.state.orderNoCriteria, this.state.email, this.state.allChbk);
					},					
					padding: 'dense',
					selection: false,
					actionsColumnIndex: 10,
					emptyRowsWhenPaging: false,
					doubleHorizontalScroll: false,
					initialPage: 0,
					pageSize: this.state.numberRowPerPage, 
			      }}

				  detailPanel={[
			        {
			          tooltip: 'Show details',
			          render: rowData => {
			            return (
			               <Table size="small" >
							 <TableHead>
								<TableRow className={classes.detailhead}>
									{this.headCells2.map((head) => (
										<StyledTableCell
								            key={head.id}
								            align='left'  
								          > 
											<Typography noWrap variant="caption">{head.label}</Typography>
						         		 </StyledTableCell>
									))}									
									{
										rowData.baseCurrency != null &&
										<StyledTableCell align="left" numeric="false" disablePadding="false"><Typography noWrap variant="caption">Base Currency</Typography></StyledTableCell>
									}
									{
										rowData.baseAmount != null &&
										<StyledTableCell align="left" numeric="true" disablePadding="false"><Typography noWrap variant="caption">Base Amount</Typography></StyledTableCell>
									}{this.headCells3.map((head) => (
										<StyledTableCell
								            key={head.id}
								            align='left'  
								          > 
											<Typography noWrap variant="caption">{head.label}</Typography>
						         		 </StyledTableCell>
									))}																																	
								</TableRow>
							 </TableHead>
							<TableBody>
							{rowData.statuses.map((txn,index) => 
								<TableRow>
									<TableCell align="left"><Typography noWrap variant="caption">{format(new Date(txn.created),'dd MMM yyyy HH:mm:ss')}</Typography></TableCell>
									<TableCell align="left"><Typography noWrap variant="caption">{txn.transactionStatus}</Typography></TableCell>		
									{
										rowData.baseCurrency != null &&
										<TableCell align="left"><Typography noWrap variant="caption">{rowData.baseCurrency}</Typography></TableCell>													
									}
									{
										rowData.baseAmount != null &&
										<TableCell align="left"><Typography noWrap variant="caption">{rowData.baseAmount}</Typography></TableCell>													
									}							
									<TableCell align="left"><Typography noWrap variant="caption">{rowData.capturedAmount && rowData.capturedAmount}</Typography></TableCell>
									<TableCell align="left"><Typography noWrap variant="caption">{txn.transactionStatus=="CHARGEBACK" && rowData.chargeBackAmount && rowData.chargeBackAmount}</Typography></TableCell>
									<TableCell align="left"><Typography noWrap variant="caption">{txn.gatewayResponse[0] && txn.gatewayResponse[0].billingDescriptor}</Typography></TableCell>
									<TableCell align="left"><Typography noWrap variant="caption">{txn.gatewayResponse[0] && txn.gatewayResponse[0].gatewayResponseTrackerId}</Typography></TableCell>
									<TableCell align="left"><Typography noWrap variant="caption">{txn.gatewayResponse[0] && txn.gatewayResponse[0].txnInfo}</Typography></TableCell>
									<TableCell align="left"><Typography noWrap variant="caption">{txn.gatewayResponse[0] && txn.gatewayResponse[0].signInfo}</Typography></TableCell>
									 																											
								</TableRow> 
							)}								
							</TableBody>							
						   </Table>
			            )
			          },
			        },
				  ]}      
			    />
				</>
				
				<Dialog open={this.state.refundPanelVisible} onClose={this.hideRefundPanel}>
					<DialogTitle id="customized-dialog-title" onClose={this.hideRefundPanel}>
			          Refund Transaction
			        </DialogTitle>
					<DialogContent>
					 <Grid>
						<Grid xs={12}>
						 <FormControl>
							<Input name="refundReason"  placeholder="Refund reason" 
		                        value={this.state.refundReason} onChange={this.handleChange} />
						 </FormControl>
						</Grid>
						<Grid xs={12}>
						 <FormControl>
							<Input name="refundAmount"  placeholder="Refund amount" type="amount" 
		                        value={this.state.refundAmount} onChange={this.handleChange} />
						 </FormControl>
						</Grid>
						<Grid xs={12}>
						 <Box align="center" style={{marginTop: '1em'}}>  
							<Button onClick={this.refundTransaction} variant="contained" color="primary" >Refund </Button>
						</Box> 
						</Grid>
					</Grid>
					</DialogContent>
				</Dialog>
				<Dialog open={this.state.showFraudCheckDialog} onClose={this.hideFraudCheckDialog} fullWidth>
					<DialogTitle  onClose={this.hideFraudCheckDialog} >
						<Typography style={{fontWeight: 'bold'}} variant="subtitle1">Transaction Fraud Info</Typography>
					</DialogTitle>
					
					<DialogContent style={{marginBottom: '2em'}}>
					 {this.state.txnFraudInfo && 
					  <>
						<Grid container spacing={2} style={{paddingBottom: '2em'}}>
							<Grid item xs={4}>
								<Typography variant="body1">Fraud check result</Typography>
							</Grid>
							<Grid item xs={8}>
							   {this.state.txnFraudInfo.rec === 'Approve' &&
								<Typography variant="body1" className={classes.greenText}>{this.state.txnFraudInfo.rec}</Typography>
								}
								{this.state.txnFraudInfo.rec !== 'Approve' &&
								<Typography variant="body1" className={classes.redText}>{this.state.txnFraudInfo.rec}</Typography>
								}
							</Grid>
							<Grid item xs={4}>
								<Typography variant="body1">Fraud score</Typography>
							</Grid>
							<Grid item xs={8}>
								{this.state.txnFraudInfo.score}
							</Grid>
						</Grid>
						{this.state.txnFraudInfo.third_party.geo_check_ad && 
						<Accordion>
							<AccordionSummary className={classes.accordHeader} expandIcon={<ExpandMoreIcon />}><Typography style={{fontWeight: 'bold'}} variant="subtitle2">Geo checks</Typography></AccordionSummary>
							<AccordionDetails>
								<Grid container className={classes.ruleGridContainer}>
									<Grid item xs={4} className={classes.ruleGrid}>
										IP Address
									</Grid>
									<Grid item xs={8} className={classes.ruleGrid}>
										{this.state.txnFraudInfo.third_party.geo_check_ad.ip_address_traits_ip_address}
									</Grid>
									<Grid item xs={4} className={classes.ruleGrid}>
										IP risk score
									</Grid>
									<Grid item xs={8} className={classes.ruleGrid}>
										{this.state.txnFraudInfo.third_party.geo_check_ad.risk_score}
									</Grid>
									<Grid item xs={4} className={classes.ruleGrid}>
										IP country
									</Grid>
									<Grid item xs={8} className={classes.ruleGrid}>
										{this.state.txnFraudInfo.third_party.geo_check_ad.ip_address_registered_country_names}
									</Grid>
									
								</Grid>
							</AccordionDetails>
						</Accordion>
						}
						
						{this.state.txnFraudInfo.rules_triggered && 
						<Accordion  style={{paddingTop: '1em'}}>
							<AccordionSummary className={classes.accordHeader} expandIcon={<ExpandMoreIcon />}><Typography style={{fontWeight: 'bold'}} variant="subtitle2">Rules applied</Typography></AccordionSummary>
							<AccordionDetails>
								<Grid container className={classes.ruleGridContainer}>
									<Grid item xs={4} className={classes.ruleGrid}>
											Rule name
										</Grid>
										<Grid item xs={8} className={classes.ruleGrid}>
											Score
										</Grid>
								 	{this.state.txnFraudInfo.rules_triggered.map((m) =>  
										<>
										<Grid item xs={4} className={classes.ruleGrid}>
											{m.name}
										</Grid>
										<Grid item xs={8} className={classes.ruleGrid}>
											{m.score}
										</Grid>
									</>
								 )} 
								</Grid>
							</AccordionDetails>
						</Accordion>
						}
						{this.state.txnFraudInfo.third_party.address_verification && 
						<Accordion style={{paddingTop: '1em'}}>
							<AccordionSummary className={classes.accordHeader} expandIcon={<ExpandMoreIcon />}><Typography style={{fontWeight: 'bold'}} variant="subtitle2">Address verification</Typography></AccordionSummary>
							<AccordionDetails>
								<Grid container className={classes.ruleGridContainer}>
									<Grid item xs={4} className={classes.ruleGrid}>
										Address code
									</Grid>
									<Grid item xs={8} className={classes.ruleGrid}>
										{this.state.txnFraudInfo.third_party.address_verification.qas_address_code}
									</Grid>
									<Grid item xs={4} className={classes.ruleGrid}>
										Address Match
									</Grid>
									<Grid item xs={8} className={classes.ruleGrid}>
										{this.state.txnFraudInfo.third_party.address_verification.match_type === 'N'? "No" : "Yes"}
									</Grid>
									<Grid item xs={4} className={classes.ruleGrid}>
										Years at address
									</Grid>
									<Grid item xs={8} className={classes.ruleGrid}>
										{this.state.txnFraudInfo.third_party.address_verification.qas_length_residence}
									</Grid>
									
								</Grid>
							</AccordionDetails>
						</Accordion>
						}
						
						{this.state.txnFraudInfo.third_party.email_intel && 
						<Accordion style={{paddingTop: '1em'}}>
							<AccordionSummary className={classes.accordHeader} expandIcon={<ExpandMoreIcon />}><Typography style={{fontWeight: 'bold'}} variant="subtitle2">Email Intel</Typography></AccordionSummary>
							<AccordionDetails>
								<Grid container className={classes.ruleGridContainer}>
									<Grid item xs={4} className={classes.ruleGrid}>
										Email status ({this.state.txnFraudInfo.third_party.email_intel.email})
									</Grid>
									<Grid item xs={8} className={classes.ruleGrid}>
										{this.state.txnFraudInfo.third_party.email_intel.status}
									</Grid>
									<Grid item xs={4} className={classes.ruleGrid}>
										Fraud risk
									</Grid>
									<Grid item xs={8} className={classes.ruleGrid}>
										{this.state.txnFraudInfo.third_party.email_intel.fraudrisk}
									</Grid>
									<Grid item xs={4} className={classes.ruleGrid}>
										Risk band
									</Grid>
									<Grid item xs={8} className={classes.ruleGrid}>
										{this.state.txnFraudInfo.third_party.email_intel.eariskband}
									</Grid>
									<Grid item xs={4} className={classes.ruleGrid}>
										Email exists
									</Grid>
									<Grid item xs={8} className={classes.ruleGrid}>
										{this.state.txnFraudInfo.third_party.email_intel.emailexists}
									</Grid>
									
								</Grid>
							</AccordionDetails>
						</Accordion>
						}
						
						{this.state.txnFraudInfo.bin_information && 
						<Accordion style={{paddingTop: '1em'}}>
							<AccordionSummary className={classes.accordHeader} expandIcon={<ExpandMoreIcon />}><Typography style={{fontWeight: 'bold'}} variant="subtitle2">Bin information</Typography></AccordionSummary>
							<AccordionDetails>
								<Grid container className={classes.ruleGridContainer}>
									<Grid item xs={4} className={classes.ruleGrid}>
										Bank name
									</Grid>
									<Grid item xs={8} className={classes.ruleGrid}>
										{this.state.txnFraudInfo.bin_information.bank_name}
									</Grid>
									<Grid item xs={4} className={classes.ruleGrid}>
										Bank location
									</Grid>
									<Grid item xs={8} className={classes.ruleGrid}>
										{this.state.txnFraudInfo.bin_information.bank_location}
									</Grid>
									<Grid item xs={4} className={classes.ruleGrid}>
										Card level
									</Grid>
									<Grid item xs={8} className={classes.ruleGrid}>
										{this.state.txnFraudInfo.bin_information.card_level}
									</Grid>
									
								</Grid>
							</AccordionDetails>
						</Accordion>
						}
						
						
						</>
						}
					</DialogContent>
				</Dialog>
			</Paper>
			</React.Fragment>
		);
	}
}	

export default withStyles(useStyles)(ChargebackReportComponent);