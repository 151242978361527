import React, { Component } from 'react'
import { Formik } from 'formik'
import { object, ref, string } from 'yup'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withStyles } from "@material-ui/styles";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ApiService, { showToast, Status } from '../../ApiService/ApiService'
import Spinner from '../../../components/Spinner'
import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'
import Button from '../../../components/Custom/Button/Button';

const useStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  tablewraptext: {
	textOverflow: 'ellipsis', 
    overflow: 'hidden', 
    whiteSpace: 'nowrap',
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(4),
  },
  medium: {
    width: '45px',
    height: '15px', 
  },
 searchbar: {
	margin: theme.spacing(2),
  },
 form: {

  width: 60,
},
formWrapper: {
  margin: theme.spacing(4),
  width: 200,
},
formError: {
  width: 200,
},
});

class LimitFormComponent extends Component {
    constructor(props) {
        super(props)
		 this.state = {
			hasTxnFailed: false,
			acquirerAccounts: []
		}
		this.reloadData = this.reloadData.bind(this);
	}

	reloadData() {	 
	}
 	 
	_handleSubmit = ({ 
		terminalId, minTxnNotionalLimit, txnNotionalLimit, dailyNotionalLimit, weeklyNotionalLimit, 
		monthlyNotionalLimit, dailyTxnVolumeLimit, monthlyTxnVolumeLimit, setSubmitting, resetForm,
  	}) => {
		const formData = {
			'terminalId' : terminalId,
			'minTxnNotionalLimit': minTxnNotionalLimit,
			'txnNotionalLimit': txnNotionalLimit,
			'dailyNotionalLimit' : dailyNotionalLimit,
			'weeklyNotionalLimit': weeklyNotionalLimit,
			'monthlyNotionalLimit': monthlyNotionalLimit,
			'dailyTxnVolumeLimit': dailyTxnVolumeLimit,
			'monthlyTxnVolumeLimit': monthlyTxnVolumeLimit,
		};

	 	ApiService.editTerminal(this.props.merchantId, formData).then((res) => {
			if (res != null) {
				if(res.data.status == 'OK') {
					showToast({ type: Status.SUCCESS, title: "Status", description: "Terminal saved successfully!" });
					setSubmitting(false);
					this.props.reloadTerminals(true, res.data.responseMessage );
				} else {
					showToast({ type: Status.ERROR, title: "Error", description: res.data.responseMessage });
					setSubmitting(false);
				}
			}
		}).catch((e) => {
			showToast({ type: Status.ERROR, title: "Error", description: 'Error occured' });
			setSubmitting(false);
		});
  	}

	render() {
		const { classes, selectedTerminal } = this.props;
	    return (!selectedTerminal ? <></> 
			: <>
			<GridContainer>			
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<Formik
						enableReinitialize={true}
				        initialValues={{
							terminalId: selectedTerminal.terminalDetail != null ? selectedTerminal.terminalDetail.terminalId : null,
							minTxnNotionalLimit: selectedTerminal.terminalDetail != null? selectedTerminal.terminalDetail.minTxnNotionalLimit : null,
							txnNotionalLimit: selectedTerminal.terminalDetail != null? selectedTerminal.terminalDetail.txnNotionalLimit : null,
							dailyNotionalLimit: selectedTerminal.terminalDetail != null? selectedTerminal.terminalDetail.dailyNotionalLimit: null,
							weeklyNotionalLimit: selectedTerminal.terminalDetail != null? selectedTerminal.terminalDetail.weeklyNotionalLimit: null,
							monthlyNotionalLimit: selectedTerminal.terminalDetail != null? selectedTerminal.terminalDetail.monthlyNotionalLimit: null,
							dailyTxnVolumeLimit: selectedTerminal.terminalDetail != null? selectedTerminal.terminalDetail.dailyTxnVolumeLimit: null,
							monthlyTxnVolumeLimit: selectedTerminal.terminalDetail != null? selectedTerminal.terminalDetail.monthlyTxnVolumeLimit: null
				        }}
				        validationSchema={object().shape({
				          minTxnNotionalLimit: string().required('Min Tx amount is required'),
						  txnNotionalLimit: string().required('Max Tx amount is required'),
				        })}
				        onSubmit={({ 
							terminalId, minTxnNotionalLimit, txnNotionalLimit, dailyNotionalLimit, 
							weeklyNotionalLimit, monthlyNotionalLimit, dailyTxnVolumeLimit, monthlyTxnVolumeLimit
						},
				          { setSubmitting, resetForm }
				        ) =>
				          this._handleSubmit({
								terminalId, minTxnNotionalLimit, txnNotionalLimit, dailyNotionalLimit, 
								weeklyNotionalLimit, monthlyNotionalLimit, dailyTxnVolumeLimit, 
								monthlyTxnVolumeLimit, setSubmitting, resetForm,
				          })
				        }
						render={props => {
							const {
								values, touched, errors, 
								handleChange, handleBlur, handleSubmit,
					            isValid, isSubmitting
							} = props;

							return isSubmitting ? (
					            <Spinner />
							) : (
								<form onSubmit={handleSubmit} >									
									<FormControl margin="dense" fullWidth size="small">
										<InputLabel id="minTxnNotionalLimitDesc">Min Transaction amount</InputLabel>
										<Input 
											id="minTxnNotionalLimit"
											name="minTxnNotionalLimit"
											placeholder="Min Transaction amount"
											value={values.minTxnNotionalLimit}
											onChange={handleChange}
											onBlur={handleBlur}
											labelId="minTxnNotionalLimitDesc"
											error={Boolean(touched.minTxnNotionalLimit && errors.minTxnNotionalLimit)}
										/>
									</FormControl>
									<FormControl margin="dense" fullWidth size="small">
										<InputLabel id="maxTxnNotionalLimitDesc">Max Transaction amount</InputLabel>
										<Input 
											id="txnNotionalLimit"
											name="txnNotionalLimit"
											placeholder="Max Transaction amount"
											value={values.txnNotionalLimit}
											onChange={handleChange}
											onBlur={handleBlur}
											error={Boolean(touched.txnNotionalLimit && errors.txnNotionalLimit)}
										/>
									</FormControl>
									<FormControl margin="dense" fullWidth size="small">
										<InputLabel id="dailyNotionalLimitDesc">Daily max transaction notional</InputLabel>
										<Input 
											id="dailyNotionalLimit"
											name="dailyNotionalLimit"
											placeholder="Daily max transaction notional"
											value={values.dailyNotionalLimit}
											onChange={handleChange}
											onBlur={handleBlur}
											error={Boolean(touched.dailyNotionalLimit && errors.dailyNotionalLimit)}
										/>
										</FormControl>
									<FormControl margin="dense" fullWidth size="small">
										<InputLabel id="weeklyNotionalLimitDesc">Weekly Max transaction notional</InputLabel>
										<Input 
											id="weeklyNotionalLimit"
											name="weeklyNotionalLimit"
											placeholder="Weekly Max transaction notional"
											value={values.weeklyNotionalLimit}
											onChange={handleChange}
											onBlur={handleBlur}
											error={Boolean(touched.weeklyNotionalLimit && errors.weeklyNotionalLimit)}
										/>
									</FormControl>
									<FormControl margin="dense" fullWidth size="small">
										<InputLabel id="monthlyNotionalLimitDesc">Monthly max transaction notional</InputLabel>
										<Input 
											id="monthlyNotionalLimit"
											name="monthlyNotionalLimit"
											placeholder="Monthly max transaction notional"
											value={values.monthlyNotionalLimit}
											onChange={handleChange}
											onBlur={handleBlur}
											error={Boolean(touched.monthlyNotionalLimit && errors.monthlyNotionalLimit)}
										/>
									</FormControl>
									<FormControl margin="dense" fullWidth size="small">
										<InputLabel id="dailyTxnVolumeLimitDesc">Daily max no of transaction</InputLabel>
										<Input 
											id="dailyTxnVolumeLimit"
											name="dailyTxnVolumeLimit"
											placeholder="Daily max no of transaction"
											value={values.dailyTxnVolumeLimit}
											onChange={handleChange}
											onBlur={handleBlur}
											error={Boolean(touched.dailyTxnVolumeLimit && errors.dailyTxnVolumeLimit)}
										/>
									</FormControl>
									<FormControl margin="dense" fullWidth size="small">
										<InputLabel id="monthlyTxnVolumeLimitDesc">Monthly max no of transaction</InputLabel>
										<Input 
											id="monthlyTxnVolumeLimit"
											name="monthlyTxnVolumeLimit"
											placeholder="Monthly max no of transaction"
											value={values.monthlyTxnVolumeLimit}
											onChange={handleChange}
											onBlur={handleBlur}
											error={Boolean(touched.monthlyTxnVolumeLimit && errors.monthlyTxnVolumeLimit)}
										/>
									</FormControl>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disabled={Boolean(!isValid || isSubmitting)} fullWidth
										style={{marginTop: '2em'}}
									>
										{'Save Changes'}
									</Button> 
								</form>
							)
					 }}
					/>
				</GridItem>
			</GridContainer>
		</>
		);
	}
}
export default withStyles(useStyles)(LimitFormComponent);