import React, { Component, Fragment } from 'react'
import { withStyles } from "@material-ui/styles";
import { Link, withRouter } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box'; 
import MaterialTable, { MTableToolbar } from 'material-table';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import GridContainer from '../../../components/Custom/Grid/GridContainer';
import GridItem from '../../../components/Custom/Grid/GridItem';
import PageTitle from '../../../components/PageTitle/PageTitle';
import TableIconsComponent from '../../../components/TableLogo/TableIconsComponent';
import ApiService, { showToast, Status } from '../../ApiService/ApiService';
import ObjectUtils from '../../../util/objectUtil';
import CustomInput from '../../../components/Custom/CustomInput/CustomInput';
import Button from '../../../components/Custom/Button/Button';

const useStyles = theme => ({ 
	theme: theme,
  searchbar: {
	margin: theme.spacing(2),
  },
  formControl: {
    minWidth: 200,
  },
});

class MerchantURLWhiteListComponent extends Component {

	constructor(props) {
		 super(props)
		 this.state = {
			merchantUrls : [],
			url: null,
			urlValidation: {}
		}
	 	this.handleChange = this.handleChange.bind(this);
		this.reloadData = this.reloadData.bind(this);
		this.addMerchantUrl = this.addMerchantUrl.bind(this);
	}

	handleChange(event) {
        this.setState({[event.target.name]: event.target.value, critChanged: true})
    }

	componentDidMount() {
		this.reloadData();
	}

	reloadData() {
		ApiService.fetchMerchantUrls(this.props.merchantId).then((res) => {
			//alert("re.data" + res.data);
			if (res != null) this.setState({merchantUrls: res.data});
		});	
	}
	
	addMerchantUrl() {
		if (ObjectUtils.isNullorEmpty(this.state.url)) {
			this.setState ({urlValidation : {helperType : Status.ERROR, helperText: "URL is required"}});
			return;
		}
		ApiService.addMerchantUrl(this.props.merchantId, this.state.url).then((res) => {
			if (res != null) {
				if(res.data.status === "OK") {
					showToast({ type: Status.SUCCESS, title: "Add", description: res.data.responseMessage });
					this.setState ({url: "", urlValidation : {}}, () => this.reloadData());
				} else {
					showToast({ type: Status.ERROR, title: "Error", description: res.data.responseMessage });
				}
			}
		}).catch((e) => {
			showToast({ type: Status.ERROR, title: "Error", description: e });
		});
	}
	
	removeMerchantUrl(data) {
		ApiService.removeMerchantUrl(this.props.merchantId, data.id).then((res) => {
			if (res != null) {
				this.reloadData();
				showToast({ type: Status.SUCCESS, title: "Delete", description: res.data.responseMessage });
			}
		}).catch((e) => {
			 showToast({ type: Status.ERROR, title: "Error", description: e });
	    });
	}
	
    render() {
        const { classes } = this.props; 
		const { url, urlValidation, merchantUrls } = this.state; 

        return (<>
				<GridContainer>
				<GridItem xs={8} sm={9} md={5} lg={4}>
					<CustomInput title="Merchant URL" field="url"
						formControlProps={{ fullWidth: true }}
						inputProps={{
							value: url,
							name: "url",
							onChange: this.handleChange
						}}
						helperType={urlValidation.helperType}
						helperText={urlValidation.helperText}
					/>
					</GridItem>
					<GridItem xs={4} sm={3} md={7} lg={8}>
						<Button variant="contained" className={classes.searchbar} color="primary" size="sm" onClick={this.addMerchantUrl}>
							Add Url
						</Button>
					</GridItem>
				</GridContainer>
				<Divider/>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<>
							<MaterialTable 
								title="Whitelisted URLs"
								icons={TableIconsComponent.icons}
								columns={[ 
									{ field: 'url',numeric: false, title: 'Url'},
								]}
								data={merchantUrls}
								actions={[						
									{
										tooltip: 'Delete url',
										icon: (props)=> <DeleteIcon {...props} />,
										onClick: (evt, data) => this.removeMerchantUrl(data),
									}
								]}
								options={{
									headerStyle: { 
										whiteSpace: 'nowrap', backgroundColor: "#fff", 
										color: "#000", fontSize: 12
									},
									search:false,
									exportButton: false,
									pageSize: 10,
									pageSizeOptions: [10,20],
									padding: 'dense',
									showEmptyDataSourceMessage: true,
									emptyRowsWhenPaging: false,
								}}				     
							/>
						</>
				 	</GridItem>					
				</GridContainer>
			 </>)
    }
}

export default withStyles(useStyles)(MerchantURLWhiteListComponent)
