import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography';
import InputAdornment from "@material-ui/core/InputAdornment";
import { AddOutlined, Save, Delete } from "@material-ui/icons";

import GridContainer from '../../../components/Custom/Grid/GridContainer';
import GridItem from '../../../components/Custom/Grid/GridItem';

import CustomInput from '../../../components/Custom/CustomInput/CustomInput';
import Card from '../../../components/Custom/Card/Card'
import CardHeader from '../../../components/Custom/Card/CardHeader'
import CardBody from '../../../components/Custom/Card/CardBody'
import Button from '../../../components/Custom/Button/Button';
import { Divider } from '@material-ui/core';

import ObjectUtils from '../../../util/objectUtil';

import { showToast, Status } from "../../ApiService/ApiService";
import ApiService from '../../ApiService/ApiService';
import { INVOICE_CONFIG_PRODUCT_GET_ALL, INVOICE_CONFIG_PRODUCT_ADD, INVOICE_CONFIG_PRODUCT_DELETE } from '../../ApiService/URLs';

const newItem = {
	productName: "",
	productDescription: "",
	productUnitId: "",
	tax: ""
};

class InvoiceConfigProduct extends Component {
	constructor(props) {
		super(props);
		this.state = {
			productList: [],
			productToAdd: ObjectUtils.DeepClone(newItem),
			productValidation: {},
		};
		this.onAddClick = this.onAddClick.bind(this);
		this.onSaveClick = this.onSaveClick.bind(this);
		this.onNewTextChange = this.onNewTextChange.bind(this);
		this.onIndexTextChange = this.onIndexTextChange.bind(this);		
	}

	componentDidMount(){
		this.onProductRefresh();	
	}

	onProductRefresh () {
		//console.log("Product Refresh");
		this.setState({productToAdd: ObjectUtils.DeepClone(newItem)});
		ApiService.getAPI(INVOICE_CONFIG_PRODUCT_GET_ALL, null, false).then((res)=> {
			if (res != null) this.setState({productList: res.data}) 
		})		
	};

	onAddClick() {
		const validObj = this.validate("_1", this.state.productToAdd);
		//console.log("validObj", validObj);
		if (validObj != null)	{
			this.setState({productValidation: validObj});
			return;
		}
		//console.log("this.state.productToAdd", this.state.productToAdd);
		ApiService.postAPI(INVOICE_CONFIG_PRODUCT_ADD, this.state.productToAdd).then((res)=> { 
			if (res != null) {
				showToast({ type: Status.SUCCESS, title: "Status", description: "Product added successfully!" });
				this.setState({productToAdd: ObjectUtils.DeepClone(newItem), productValidation: {}}, 
					() => this.onProductRefresh());
			}
		})	
	}

	onSaveClick(index) {
		const validObj = this.validate(index, this.state.productList[index]);
		//console.log("validObj", validObj);
		if (validObj != null)	{
			this.setState({productValidation: validObj});
			return;
		}

		const data = {
			id: this.state.productList[index].id,
			productName: this.state.productList[index].productName,
			productDescription: this.state.productList[index].productDescription,
			productUnitId: this.state.productList[index].productUnitId,
			tax: this.state.productList[index].tax
		};
		//console.log("onSaveClick", index, data);
		ApiService.postAPI(INVOICE_CONFIG_PRODUCT_ADD, data).then((res)=> {
			if (res != null) {
				showToast({ type: Status.SUCCESS, title: "Status", description: "Product saved successfully!" });
				this.setState({productValidation: {}}, () => this.onProductRefresh());
			}
		})	
	}

	onDeleteClick(index) {
		const data = { id: this.state.productList[index].id	};
		ApiService.postAPI(INVOICE_CONFIG_PRODUCT_DELETE, data).then((res)=> { 
			if (res != null) {
				showToast({ type: Status.SUCCESS, title: "Status", description: "Product deleted successfully!" });
				this.onProductRefresh();
			}
		})
	}
	
	validate (fieldName, product)	{
		if (ObjectUtils.isNullorEmpty(product.productName)) 
			return { ['COMP' + fieldName] : { productName: { helperType: Status.ERROR, helperText: "Name is required"} } };
		else if (ObjectUtils.isNullorEmpty(product.productDescription)) 
			return { ['COMP' + fieldName] : { productDescription: { helperType: Status.ERROR, helperText: "Description is required"} } };
		else if (ObjectUtils.isNullorEmpty(product.productUnitId)) 
			return { ['COMP' + fieldName] : { productUnitId: { helperType: Status.ERROR, helperText: "Unit is required"} } };
		else if (!ObjectUtils.isNullorEmpty(product.productUnitId) && !ObjectUtils.isNumber(product.productUnitId))
			return { ['COMP' + fieldName] : { productUnitId: { helperType: Status.ERROR, helperText: "Invalid Unit value"} } };
		else if (!ObjectUtils.isNullorEmpty(product.tax) && !ObjectUtils.isNumber(product.tax))
			return { ['COMP' + fieldName] : { tax: { helperType: Status.ERROR, helperText: "Invalid Tax value"} } };
		else return null;
	}
	
	getHelperText(index, fieldName, helperField) {
		return this.state.productValidation[`COMP${index}`] && this.state.productValidation[`COMP${index}`][fieldName] 
		? this.state.productValidation[`COMP${index}`][fieldName][helperField] : "";
	}


	onNewTextChange(evt) {
		//console.log(evt);
		const newProductToAdd = {...this.state.productToAdd};
		newProductToAdd[evt.target.name] = evt.target.value;
		this.setState({productToAdd: newProductToAdd});
	}

	onIndexTextChange(e, index) {
		var newProductList = [...this.state.productList];
		newProductList[index][e.target.name] = e.target.value;
		newProductList.splice(index, 1, newProductList[index]);
		this.setState({productList: newProductList});
	}

	renderItem(item, idx) {
		const compIdx = idx === -1 ? "_1" : idx;
		//console.log("Product render:", item, compIdx);
		return (<>
		<GridItem xs={12} sm={12} md={4} lg={2}>
			<CustomInput
				title="Name"
				field="productName"
				formControlProps={{
					fullWidth: true,
				}}
				inputProps={{
					value: item.productName,
					name: "productName",
					id: "productName",
					onChange: (e) => idx === -1 ? this.onNewTextChange(e) : this.onIndexTextChange(e, idx),
				}}				
				helperType={this.getHelperText(compIdx, "productName", "helperType") }
				helperText={this.getHelperText(compIdx, "productName", "helperText") }
			/>
			</GridItem>
			<GridItem xs={8} sm={12} md={8} lg={5}>
				<CustomInput
					title="Description"
					field="productDescription"
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: item.productDescription,
						name: "productDescription",
						id: "productDescription",
						onChange: (e) => idx === -1 ? this.onNewTextChange(e) : this.onIndexTextChange(e, idx),
					}}
					helperType={this.getHelperText(compIdx, "productDescription", "helperType") }
					helperText={this.getHelperText(compIdx, "productDescription", "helperText") }
				/>
			</GridItem>
			<GridItem xs={4} sm={4} md={4} lg={1}>
				<CustomInput
					title="Unit"
					field="productUnitId"
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: item.productUnitId,
						name: "productUnitId",
						id: "productUnitId",
						onChange: (e) => idx === -1 ? this.onNewTextChange(e) : this.onIndexTextChange(e, idx),
					}}
					helperType={this.getHelperText(compIdx, "productUnitId", "helperType") }
					helperText={this.getHelperText(compIdx, "productUnitId", "helperText") }					
				/>
			</GridItem>
			<GridItem xs={4} sm={4} md={4} lg={1}>
				<CustomInput
					title="Tax"
					field="tax"
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: item.tax,
						name: "tax",
						id: "tax",
						onChange: (e) => idx === -1 ? this.onNewTextChange(e) : this.onIndexTextChange(e, idx),
					}}
					helperType={this.getHelperText(compIdx, "tax", "helperType") }
					helperText={this.getHelperText(compIdx, "tax", "helperText") }
				/>
			</GridItem>
		</>);
	}

	render() {
		const { productToAdd, productList } = this.state;

		return <Card color="white">
				<CardBody>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<Typography variant="h5">Product(s)</Typography>
						</GridItem>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<GridContainer>
								{this.renderItem(productToAdd, -1)}
								<GridItem xs={4} sm={4} md={4} lg={2} style={{marginTop: "18px"}} >
									<Button color="primary" size="sm" onClick={() => this.onAddClick()} toolTipTitle="Add Unit">
										<AddOutlined /> Add
									</Button>
								</GridItem>
							</GridContainer>
						</GridItem>
						{!ObjectUtils.isNullObject(productList) > 0 && <GridItem><Divider light/></GridItem>}
						{productList.map((product, idx) => (
							<React.Fragment key={product.id}>
								{this.renderItem(product, idx)}
								<GridItem xs={2} sm={2} md={2} lg={1}>
									<Button color="white" size="lg" justIcon round onClick={() => this.onSaveClick(idx)} toolTipTitle="Save">
										<Save style={{color: "#9AAFFB"}} />
									</Button>
								</GridItem>
								<GridItem xs={2} sm={2} md={2} lg={1}>
									<Button color="white" size="lg" justIcon round onClick={() => this.onDeleteClick(idx)} toolTipTitle="Delete">
										<Delete style={{color: "#FF0000"}}/>
									</Button>
								</GridItem>
							</React.Fragment>
						))}
					</GridContainer>
				</CardBody>
			</Card>
		}
}

export default InvoiceConfigProduct;