import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import moment from 'moment';
import _ from 'lodash';

import { CancelOutlined, RemoveRedEyeOutlined, InfoOutlined, PictureAsPdfSharp, MoreVert } from "@material-ui/icons";
import ClickAwayListener from '@mui/base/ClickAwayListener';

import MenuIcon from "@material-ui/icons/Menu";
import { TableRow, TableCell } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'

import {Status, showToast} from '../../ApiService/ApiService';

import ApiService from '../../ApiService/ApiService';
import { INVOICE_GET_ALL, INVOICE_DOWNLOAD, INVOICE_DELETE } from '../../ApiService/URLs';
import Button from '../../../components/Custom/Button/Button';
import CustomDialog from '../../../components/Custom/Dialog/CustomDialog';
import { Typography } from '@material-ui/core';
import CustomMenu from '../../../components/Custom/Menu/CustomMenu';
import InvoiceView from './InvoiceView';

// const columns = [
//     { title: "Id", field: "id", sorting: false },
// 	{ title: "Description", field: "description", sorting: true },
//     { title: "Name", field: "customerName", sorting: true },
//     { title: "Tax", field: "taxAmount", sorting: false },
// 	{ title: "Amount", field: "totalAmount", sorting: false },
// 	{ title: "Currency", field: "currency", sorting: false },
// 	// { title: "Email", field: "customerEmail", sorting: true },	
// 	// { title: "Phone", field: "customerPhone", sorting: true },
//     { title: "Status", field: "status", lookup: { NEW: 'New', PROCESSED: "Processed", CANCELLED: "Cancelled" }, sorting: true }
// ];

//const columns = ["Id", "Description", "Name",  "Tax",  "Amount",  "Currency",  "Status"];
let menuIdx = 1;
const columns = [
	{ label: " ", name: "action", options: { filter: false, sort: false } },
	{ label: "Id", name: "id", options: { filter: true, sort: true, 
		customHeadRender: (columnMeta, updateDirection) => (
		<th key={"th" + menuIdx} onClick={() => updateDirection(2)} style={{cursor: 'pointer', align: "center"}}>{columnMeta.label}</th>
	)} },
	{ label: "Description", name: "description", options: { filter: true, sort: true, } },
	{ label: "Name", name: "customerName", options: { filter: true, sort: true, } },
	{ label: "Tax", name: "taxAmount", options: { filter: true, sort: true, } },
	{ label: "Amount", name: "totalAmount", options: { filter: true, sort: true, } },
	{ label: "Currency", name: "currency", options: { filter: true, sort: true, } },
	{ label: "Email", name: "customerEmail", options: { filter: true, sort: true, } },
	{ label: "Phone", name: "customerPhone", options: { filter: true, sort: true, } },
	{ name:'created', label:'Created', options: { filter: true, sort: true }},	
	{ label: "Status", name: "status", options: { filter: true, sort: true, filterList: ["NEW"] } },
];
   
class InvoiceHistory extends Component {
  	constructor(props) {
        super(props)
		 this.state = {
			data: [],
			showWarning: false,
			selectedRow: null,
			mode: null,
			openMenu: false,
			menuEvent: null
		}
		this.onCancelClick = this.onCancelClick.bind(this);
  	}
		  
	componentDidMount(){
		this.getInvoiceData();
	}

	getInvoiceData() {
		if (!this.state.isNew) {
			ApiService.getAPI(INVOICE_GET_ALL).then(res=> {
				const data = res.data;
				data.map((row, key) => {
					const dropdownList = [
						{
							icon: <RemoveRedEyeOutlined style={{color: "#000000"}}/>,
							title: "View",
							onClick: () => this.onViewClick(row),
							toolTip: "View Invoice"
						},
						{
							icon: <PictureAsPdfSharp style={{color: "#000000"}}/>,
							title: "Download",
							onClick: () => this.onDownloadClick(row.id),
							toolTip: "Download Invoice (PDF)"
						},
						{ divider: true }
					];

					if ("CANCELLED" !== row.status) {
							dropdownList.push ({
							icon: <CancelOutlined style={{color: "#000000"}}/>,
							title: "Cancel",
							onClick: () => this.setState({selectedRow: row, showWarning: true, mode: "D"}),
							toolTip: "Cancel Invoice"
						});
					}

					row["action"] = (
						<CustomMenu left hoverColor="primary"
							caret= {false}
							buttonIcon={<MoreVert />}
							buttonProps={{
								color: "transparent",
								size: "lg"
							}}
							dropdownList={dropdownList}
						/>);				
				});
				this.setState({data: data});
			})
		}
	}

	onDownloadClick(invoiceId) {
		const data = { "merchantInvoiceId" : invoiceId };
		const options = { fileName: invoiceId, isDownload : true }
		//console.log(INVOICE_DOWNLOAD, data, options);
		ApiService.downloadPdf(INVOICE_DOWNLOAD, data, options);
	}

	onRowClick(row) {
		//console.log("Row: ", row);
		this.props.history.push({pathname: '/app/invoice-generate', state: { invoice: row, mode: "E" } });
	}

	onViewClick(row) {
		//console.log("Row: ", row);
		this.setState({selectedRow: row, showWarning: false, mode: "V"});
	}

	onCancelClick(row) {
		//console.log("Row: ", row);
		this.setState({selectedRow: null, showWarning: false, mode: null});
		ApiService.postAPI(INVOICE_DELETE, { id: row.id }).then((res)=> { 
			if (res != null) {
				showToast({ type: Status.SUCCESS, title: "Status", description: "Invoice canceled successfully!" });
				this.getInvoiceData();
			}
		});
	}

	render() {
		const { data, showWarning, selectedRow, mode } = this.state;
		return ("V" === mode
		? <InvoiceView invoice={selectedRow} 
			onBack={() => 
				this.setState({selectedRow: null, showWarning: false, mode: null}) 
			} />
		: <>
			<MUIDataTable
				title={"Invoice History"}
				data={data}
				columns={columns}
				options={{
					padding: 'dense',
					selectableRows: false, 
					SelectableRows: 'single',
					customRowRender:(data, dataIndex, rowIndex) => {
						//console.log("data, dataIndex, rowIndex", data, dataIndex, rowIndex);
						return <TableRow key={rowIndex + "-" + dataIndex}>
						{columns.map((col, idx) => {
							const cellValue = data[idx];
							return <TableCell key={rowIndex + idx} >
							{/* <TableCell key={rowIndex + idx} style={{ verticalAlign: 'top' }} ></TableCell> */}
							{
							(col.name === "id") ?
								<Button simple color="transparent" size="lg" justIcon round 
									toolTipTitle={cellValue}><InfoOutlined /></Button>
							// : col.name === "action" ?
							// 	<Button simple color="transparent" size="lg" justIcon round 
							// 		toolTipTitle={cellValue} onClick={(event) => {
							// 			this.setState({openMenu: true, menuEvent: event});
							// 		}}><MoreVert /></Button>
							: (col.name === "created") ? 
									moment(cellValue).format("YYYY-MM-DD")
							: cellValue
							}
							</TableCell>
						})} 
						</TableRow>;
					},
					// onRowClick: (rowData) => {
					// 	console.log("rowData Edit ", rowData);
					// 	this.onRowClick(rowData)
					// }
				}}
			/>				
			{"D" === mode && selectedRow &&
			<CustomDialog key="warningDialog"
				open={showWarning}
				title={`Cancel Invoice Confirmation`}
				childNode={
					<Typography variant='h5' sx={{ fontSize: { xs: 12, sm: 15, md: 15 } }}>
						Do you wish to delete Invoice - {selectedRow.id} ?
					</Typography>
				}
				handleConfirm={() => this.onCancelClick(selectedRow)}
				handleCancel={() => this.setState({showWarning: false})}
				type={Status.NOTIFY}
				actionButtonColor={"primary"}
				// showFullWidth={true}
			/>}
		</>)
  }
} 
export default withRouter(InvoiceHistory);
