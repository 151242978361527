import React, { useState, useEffect } from 'react';
import PageTitle from "../../components/PageTitle";
import "./App.css";
import { makeStyles } from '@material-ui/styles';
import DoughnutChart from './DoughnutChart';
import BarChart from './BarChart';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { red, green, blue, orange } from '@material-ui/core/colors';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import RefundIcon from '@material-ui/icons/RemoveShoppingCart';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiService from '../ApiService/ApiService';
import GridContainer from '../../components/Custom/Grid/GridContainer';
import GridItem from '../../components/Custom/Grid/GridItem';
import Widget from "../../components/Widget";
import { Avatar, CardHeader, Card, Typography, Paper, CircularProgress,Backdrop } from '@material-ui/core/';
import { Button } from '@mui/material';

const useStyles = makeStyles(theme => ({
	
	avatarCheck: {
		backgroundColor: 'rgb(99, 117, 98)' ,

	},
	avataSett: {
		backgroundColor: 'rgb(173, 206, 171)',

	},
	avataChbk: {
		backgroundColor: 'rgb(153, 65, 65)',

	},
	avataRefund: {
		backgroundColor: 'rgba(239, 192, 4, 0.91)',

	},
	avataDecline: {
		backgroundColor: ' rgb(35, 75, 119)',

	},
	backdrop: {
    	zIndex: theme.zIndex.drawer + 1,
    	color: '#fff',
  	},
}));

export default function Dashboard() {
	const classes = useStyles();
	const [txnSummary, setTxnSummary] = useState();
	const [monthSummary, setMonthSummary] = useState();
	const [open, setOpen] = React.useState(false);
	
	const handleClose = () => {
	    setOpen(false);
	};
	const handleToggle = () => {
	    setOpen(!open);
	};
	useEffect(() => { 
			handleToggle();
			const txnFn = async () =>  { 
				const t1 = await ApiService.fetchMerchantTxnStatusSummary();				
				const t2 = await ApiService.fetchMonthsMerchantTxnSummary();
				if (t1 != null) setTxnSummary(t1.data);
				if (t2 != null) setMonthSummary(t2.data);
				handleClose();
			};
			txnFn(); 
			
	 }, []);
	 
	return (<Paper style={{ margin: "1em", padding: "1em" }}>
		<PageTitle title=" Merchant Dashboard" />
		<Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
			<CircularProgress color="inherit" />
		</Backdrop>
		<GridContainer style={{ margin: '.5em', padding: '.5em' }}>
				<GridItem xs={12} sm={6} md={4} lg={2} style={{ marginTop: "14px", padding: 4 }}>
					<Card>
						<CardHeader
							avatar={<Avatar aria-label="Successful" className={classes.avatarCheck}><CheckCircleIcon /></Avatar>}
							title="Successful"
							subheader={
								<>
									<Typography variant="caption"> ${txnSummary && txnSummary.successfulTxnAmount.toFixed(2)}</Typography><br/>
									<Typography noWrap variant="caption"> Count: {txnSummary && txnSummary.successfulTxnCount}</Typography>
								</>
							} 
						/>
					</Card >
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={2} style={{ marginTop: "14px", padding: 4 }}>
					<Card>
						<CardHeader
							avatar={<Avatar aria-label="Settled" className={classes.avataSett}><ReceiptIcon /></Avatar>}
							title="Settled"
							subheader={
								<>
									<Typography variant="caption"> ${txnSummary && txnSummary.successfulSettledAmount.toFixed(2)}</Typography><br/>
									<Typography noWrap variant="caption"> Count: {txnSummary && txnSummary.successfulSettledCount}</Typography>
								</>
							} 
						/>
					</Card >

				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={2} style={{ marginTop: "14px", padding: 4 }}>
					<Card>
						<CardHeader
							avatar={<Avatar aria-label="Decline" className={classes.avataDecline}><PaymentIcon /></Avatar>}
							title="Decline"
							subheader={
								<>
									<Typography variant="caption"> ${txnSummary && txnSummary.declinedTxnAmount.toFixed(2)}</Typography><br/>
									<Typography noWrap variant="caption"> Count: {txnSummary && txnSummary.declinedTxnCount}</Typography>
								</>
							} 
						/>
					</Card > 
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={2} style={{ marginTop: "14px", padding: 4 }}>
					<Card>
						<CardHeader
							avatar={<Avatar aria-label="Chargeback" className={classes.avataChbk}><RefundIcon /></Avatar>}
							title="Chargeback"
							subheader={
								<>
									<Typography variant="caption"> ${txnSummary && txnSummary.chbkTxnAmount.toFixed(2)}</Typography><br/>
									<Typography noWrap variant="caption"> Count: {txnSummary && txnSummary.chbkTxnCount}</Typography>
								</>
							} 
						/>
					</Card >
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={2} style={{ marginTop: "14px", padding: 4 }}>
					<Card>
						<CardHeader
							avatar={<Avatar aria-label="Refund" className={classes.avataRefund}><RestoreIcon /></Avatar>}
							title="Refund"
							subheader={
								<>
									<Typography variant="caption"> ${txnSummary && txnSummary.refundTxnAmount.toFixed(2)}</Typography><br/>
									<Typography noWrap variant="caption"> Count: {txnSummary && txnSummary.refundTxnCount}</Typography>
								</>
							} 
						/>
					</Card > 
				</GridItem>
				<GridItem xs={12} sm={12} md={6} lg={5} style={{ marginTop: "30px" }}>
					<BarChart monthSummary={monthSummary} />
				</GridItem>
				<GridItem xs={12} sm={12} md={6} lg={5} style={{ marginTop: "30px" }}>
					<DoughnutChart txnSummary={txnSummary}/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: "1em" }}>	
				{/* <Widget
					title="Payment Methods"
					upperTitle
					disableWidgetMenu={true}
					bodyClass={classes.fullHeightBody}
					className={classes.card}
				>
					<div className={classes.visitsNumberContainer}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={6} lg={6} style={{ marginTop: "1em" }}>	
							<Typography>
								<a><img src="https://i.ibb.co/bFqJjFc/Social-Icon.png" alt="Social-Icon" border="0" /></a>
							</Typography>
						</GridItem>
						<GridItem xs={12} sm={12} md={6} lg={6} style={{ marginTop: "1em" }}>	
							<Typography>
								<a><img src="https://i.ibb.co/hc4mW80/Social-Icon-1.png" alt="Social-Icon-1" border="0" /></a>
							</Typography>
						</GridItem>                
					</GridContainer>
					</div>
					<GridContainer style={{alignItems:"right"}}>
						<GridItem xs={12} sm={12} md={12} lg={12} style={{ marginTop: "1em" }}>
							<Typography color="secondary" noWrap>
								<Button variant="contained" color="primary">View More</Button>
							</Typography><br/>
						</GridItem>
					</GridContainer>
				</Widget> */}
			</GridItem>
			</GridContainer>
		</Paper>
	)
}