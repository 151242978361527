import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import { TextField } from "@material-ui/core";
import useStyles from "./customTextAreaStyle.js";

export default function CustomTextArea(props) {
  const classes = useStyles();
  const {
    formControlProps,
    title,
    field,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    rows,
  } = props;

  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });

  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      <TextField
        classes={{
          root: marginTop
        }}
        label={title}
        id={field}
        multiline
        variant="outlined"
        rows={rows}
        rowsMax={rows}
        {...inputProps}
        inputProps={{
          className: classNames([classes.textarea, classes.notchedOutline])
        }}
        InputProps={{
          classes: {
            notchedOutline: classes.notchedOutline
          }
        }}
      />
    </FormControl>
  );
}

CustomTextArea.propTypes = {
  title: PropTypes.node,
  field: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  rows: PropTypes.number,
};
