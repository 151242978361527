import React, { useState, useEffect } from "react";
import { Avatar, Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  Dashboard as DashboardIcon,
  Receipt as ReceiptIcon,
  AccountBalance as AccountBalanceIcon ,
  LockOpen as LockOpenIcon ,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  Security as SecurityIcon,
  OfflineBolt as OfflineBoltIcon,
  Grading as GradingIcon,  
  Store as StoreIcon,
  Settings as SettingsIcon,
  Payment as PaymentIcon,
  Rule as RuleIcon,
  ReceiptLong as ReceiptLongIcon,
  BarChart as BarChartIcon,
  Payments as PaymentsIcon,
  OpenInNewOff as OpenInNewOffIcon
} from "@mui/icons-material";
import AssessmentIcon from '@material-ui/icons/Assessment';

import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  { id: 0, label: "Home", link: "/app/home", icon: <HomeIcon /> },
  { id: 1, label: "Dashboard", link: "/app/dashboard", icon: <DashboardIcon /> },
  { id: 2, label: "Wire", link: "/app/wire", icon: <GradingIcon />, },  
  { id: 3, label: "Quick Pay", link: "/app/quickpay", icon: <OfflineBoltIcon />, },    
  // { id: 4, label: "Security Settings", link: "/app/security", icon: <SecurityIcon />, },
  { id: 4, label: "Transactions", link: "/app/txn", icon: <PaymentIcon /> },
  // { id: 5, label: "Pay Out", link: "/app/payout", icon: <PaymentsIcon /> },
  { id: 6, label: "Invoice", icon: <ReceiptIcon />,
    children: [
      { id: 61, label: "Generate", link: "/app/invoice-generate", icon: <ReceiptIcon /> },
      { id: 62, label: "History", link: "/app/invoice-history", icon: <ReceiptIcon /> },
      { id: 63, label: "Configuration", link: "/app/invoice-config", icon: <ReceiptIcon /> },
    ]
  },
  { id: 7, label: "Report", icon: <BarChartIcon />,
    children: [
      { id: 71, label: "Chargebacks", link: "/app/chargebackReport", icon: <ReceiptIcon /> },
      { id: 72, label: "Configured Charges", link: "/app/chargesReport", icon: <ReceiptIcon /> },      
      // { id: 73, label: "Notifications", link: "/app/notificationReport", icon: <ReceiptIcon /> },
    ]
  },
  { id: 8, label: "Settings", link: "/app/settings", icon: <SettingsIcon />, },
  { id: 9, label: "Password", link: "/app/password", icon: <LockOpenIcon /> },
  // {
  //   id: 7,
  //   label: "Report",
  //   link: "/app/report",
  //   icon: <AssessmentIcon />,    
  // },
  // {
  //   id: 8,
  //   label: "Terminal",
  //   link: "/app/terminal",
  //   icon: <PaymentIcon />,    
  // },
  // {
  //   id: 10,
  //   label: "Store management",
  //   link: "/app/store",
  //   icon: <StoreIcon />,    
  // },  
  // {
  //   id: 11,
  //   label: "Fraud Rules",
  //   link: "/app/fraudmanagement",
  //   icon: <RuleIcon />,
  // },  
];

function Sidebar({ location }) {
  var classes = useStyles();
  
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  return (
    <Drawer 
      variant={"permanent"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <List className={classes.sidebarList}>                   
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>      
    </Drawer>
  );
}

export default withRouter(Sidebar);
