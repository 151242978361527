import React, {Component} from 'react';
import { withStyles } from "@material-ui/styles";
import { Link, Grid, Select, MenuItem, InputLabel, FormControl, Avatar, 
	CircularProgress, Table, TableBody,TableCell, TableHead, TableRow, TableSortLabel, TablePagination,
	Paper, Typography, Chip, Box, Divider, TextField, Input, Dialog, DialogTitle, DialogContent,
	FormControlLabel, Backdrop, Checkbox, Accordion, AccordionSummary, AccordionDetails,
	FormHelperText } from '@material-ui/core';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ApiService from '../ApiService/ApiService';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import MaterialTable from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import {subMonths,subDays, format} from 'date-fns';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from '../../components/Custom/Button/Button';

import {
  MuiPickersUtilsProvider,
  DatePicker,
  DateTimePicker ,
  TimePicker 
} from '@material-ui/pickers';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);


const useStyles = theme => ({
	theme: theme,
  button: {
    margin: theme.spacing(1),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  detailhead: {
	 fontSize: 12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  searchbar: {
	margin: theme.spacing(2),
  },
  formControl: { 
    marginLeft: 20,
    minWidth: 120,
    maxWidth: 300,
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
	marginTop: 8,
    marginLeft: -65,
  },
});

class ChargesReportComponent extends Component {
	constructor(props) {
        super(props)
        this.state = {
            merchantId: null,
			fromDate: null, 
			toDate: null,
			fromDateObj: null, 
			toDateObj: null,
			loading: false,
			showCharges: false,
			failed: false,
			terminalId: null,
			merchantTerminals: [],
			entityChargeConfigs: [],
			responsemessage: null,
			progressOpen: false,
			previousBalance: '0.00',
			success: false,
			fromTime: (new Date()).setHours(0,0,0),	
			toTime: (new Date()).setHours(23,59,59),
			fromTimeStr: "00:00:00",	
			toTimeStr: "23:59:59",
			entityType: 'MERCHANT',
		}
		this.reloadData = this.reloadData.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }
	reloadData() { 
		if(this.state.terminalId == null) {
			this.setState({responsemessage:"Please select a Merchant Terminal ",failed: true});
			return;
		}
		this.fetchEntityMasterCharges();
    }
	fetchMerchantTerminals() {
		ApiService.fetchMerchantTerminals()
			.then((res)=> {
				if (res != null)  this.setState({merchantTerminals: res.data})			
			}).catch((e)=> {
				
			});
	}
	
	fetchEntityMasterCharges(){
		const {fromDate, toDate, terminalId} = this.state;
		ApiService.fetchEntityChargeDetails(fromDate,toDate, terminalId)
			.then((res)=> {
				if (res != null && res.data != null)
					this.setState({entityChargeConfigs: res.data, failed: false, showCharges: true})
			})
	}

 	componentDidMount() {
        this.fetchMerchantTerminals();
    }

	MenuProps = {
	  PaperProps: {
	    style: {
	      maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
	      width: 380,
	    },
	  },
	};
	tableIcons = {
	    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
	};
	render(){
		const { classes } = this.props; 
		return (
				<React.Fragment>
				<Paper style={{margin: '1em', padding: '1em'}}>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Configured Charges
					</Typography>					
					<Divider light className={classes.searchbar}/>
					{this.state.failed &&  <Alert severity="error" elevation="0"> {this.state.responsemessage}</Alert> }
					{this.state.success &&  <Alert severity="success" elevation="0"> {this.state.responsemessage}</Alert> }
					&nbsp;
					<Grid container  spacing={1}>		
					  <Grid >
					  <MuiPickersUtilsProvider utils={DateFnsUtils} >
							<DatePicker autoOk 
								label="From"  format="dd MMM yyyy"							
								value={this.state.fromDateObj} 
								onChange={(val)=> this.setState({fromDateObj: val,fromDate: format(val,'yyyy-MM-dd')}) } />							
							<TimePicker 
								label="From Time"
								 views={['hours', 'minutes', 'seconds']}
								value={this.state.fromTime} 
								format="HH:mm:ss"
								onChange={(val)=> this.setState({fromTime: val, fromTimeStr: format(val,'HH:mm:ss') })}> 
							</TimePicker>
							&nbsp;&nbsp;&nbsp;
							<DatePicker autoOk
						          label="To"  format="dd MMM yyyy"					          
						          value={this.state.toDateObj}
						          onChange={(val) =>this.setState({toDateObj: val, toDate : format(val,'yyyy-MM-dd') })}
						        /> 
							<TimePicker 
								label="To Time"
								 views={['hours', 'minutes', 'seconds']}
								value={this.state.toTime} 
								format="HH:mm:ss"
								onChange={(val)=> this.setState({toTime: val, toTimeStr: format(val,'HH:mm:ss') })}> 
							</TimePicker> 
					  </MuiPickersUtilsProvider>
					 </Grid>
					 <Grid>
					   <FormControl className={classes.formControl}>
						  <InputLabel id="merchantTerminalSelectLabel">Terminals</InputLabel>
						  <Select value={this.state.terminalId}  MenuProps={this.MenuProps}
						  		onChange={this.handleChange} name="terminalId"
						  		labelId="merchantTerminalSelectLabel" id="merchantTerminalSelect"
		 					>
							<MenuItem key="" value="">&nbsp; </MenuItem>	
							 {	this.state.merchantTerminals && this.state.merchantTerminals.map((mt) =>
							  	 <MenuItem key={mt.terminalId} value={mt.terminalId} dense>{mt.label + "-" + mt.terminalCardType}</MenuItem>
							)}
						  </Select>
						 </FormControl>
						
				 	 </Grid>					 
					</Grid>
					<Grid justify="center" alignItems="center" style={{marginTop: '2em'}} container xs={12}>
			  	  	 <Grid item xs={2}>
					 <Button
				        variant="contained"
				        color="primary"
				        size="sm"
				        className={classes.button}
						onClick={this.reloadData}
				      >
				        Go
				    </Button>
 					{this.state.loading && <CircularProgress size={25} className={classes.buttonProgress} />}
				    </Grid>
			  	</Grid>
				{this.state.showCharges &&
				<>
					<MaterialTable
						style={{margin: '1em 0 0 0'}}
						title="Configured charges"
						icons={this.tableIcons}
						columns={[
							{ field: 'chargeDescription', title: 'Name'},
							{ field: 'chargeValue',type: 'datetime', title: 'value'},
							{ field: 'charge.type' , title: 'Type'},
							{ field: 'charge.direction' , title: 'Direction'},
							{ field: 'charge.frequency' , title: 'Frequency'},
							{ field: 'validFrom',title: 'From',render: rowData => <Typography noWrap variant="caption">{format(new Date(rowData.validFrom),'dd MMM yyyy') }</Typography> },
							{ field: 'validTo',title: 'To',render: rowData => <Typography noWrap variant="caption">{format(new Date(rowData.validTo),'dd MMM yyyy') }</Typography>},
							{field: 'chargeCurrency', title: 'Currency'},					
						]}
						data={this.state.entityChargeConfigs}
						actions={[]}
						options={{
							headerStyle: { 
								whiteSpace: 'nowrap', backgroundColor: "#fff", 
								color: "#000", fontSize: 12
							},
							search:false,
							exportButton: false,
							pageSize: 10,
							pageSizeOptions: [10,20,30],
							padding: 'dense',
							showEmptyDataSourceMessage: true,
							paginationType: 'stepped',
							selection: true,
							emptyRowsWhenPaging: false,
							tableLayout: 'fixed',
						}}
					/>
				</>
				}
				</Paper>
				 <Backdrop open={this.state.progressOpen} className={classes.backdrop}>
			        <CircularProgress color="inherit" />
			      </Backdrop>
				</React.Fragment>
		);
	}
}
export default withStyles(useStyles)(ChargesReportComponent);