import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography';
import InputAdornment from "@material-ui/core/InputAdornment";
import { EmailOutlined } from "@material-ui/icons";

import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'

import CustomInput from '../../../components/Custom/CustomInput/CustomInput';
import Card from '../../../components/Custom/Card/Card'
import CardHeader from '../../../components/Custom/Card/CardHeader'
import CardBody from '../../../components/Custom/Card/CardBody'
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

class InvoiceGenerateCustomerDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	render() {
		const { invoice, refData, onChange } = this.props;
		//console.log("Invoice ", invoice);
		return <Card color="white">
				<CardBody>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<Typography variant="h5">Customer Details</Typography>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} lg={3}>
							<CustomInput
								title="Customer Name"
								field="customerName"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									defaultValue: invoice.customerName,
									name: "customerName",
									id: "customerName",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"Customer Name"}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} lg={3}>
							<CustomInput
								title="Customer Email *"
								field="customerEmail"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									// startAdornment: (
									// 	<InputAdornment position="start">
									// 		<EmailOutlined style={{color: "#495057"}} />
									// 	</InputAdornment>
									// 	),
									defaultValue: invoice.customerEmail,
									name: "customerEmail",
									id: "customerEmail",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"Customer Email"}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} lg={3}>
							<CustomInput
								title="Address"
								field="customerAddress"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									defaultValue: invoice.customerAddress,
									name: "customerAddress",
									id: "customerAddress",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"Address"}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} lg={3}>
							<CustomInput
								title="Phone Number"
								field="customerPhone"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									defaultValue: invoice.customerPhone,
									name: "customerPhone",
									id: "customerPhone",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"Phone Number"}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} lg={3}>
							<CustomInput
								title="Country"
								field="customerCountry"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									defaultValue: invoice.customerCountry,
									name: "customerCountry",
									id: "customerCountry",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"Country"}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} lg={3}>
							<CustomInput
								title="State"
								field="customerState"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									defaultValue: invoice.customerState,
									name: "customerState",
									id: "customerState",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"State"}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} lg={3}>
							<CustomInput
								title="City"
								field="customerCity"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									defaultValue: invoice.customerCity,
									name: "customerCity",
									id: "customerCity",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"City"}
							/>
						</GridItem>						
					</GridContainer>
				</CardBody>
			</Card>
		}
}

export default InvoiceGenerateCustomerDetails;