import React, { Component } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@material-ui/core/Box';
import { withStyles, styled } from '@material-ui/styles';
import { isMobile } from "react-device-detect";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'; 
import Grid from '@material-ui/core/Grid'; 
import IconButton from '@material-ui/core/IconButton';

import { 
  CreditCardOff as CreditCardOffIcon,
  GpsOff as GpsOffIcon,
  Unsubscribe as UnsubscribeIcon,
  PublicOff as PublicOffIcon
 } from "@mui/icons-material";

import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import 'react-tabs/style/react-tabs.css';

import PageTitle from "../../../components/PageTitle/PageTitle";
import CustomTabPanel from '../../../components/Custom/CustomTabs/CustomTabPanel';
import CardBlockComponent from './CardBlockComponent';
import CountryBlockComponent from './CountryBlockComponent';
import EmailBlockComponent from './EmailBlockComponent';
import IPBlockComponent from './IPBlockComponent';


import Spinner from '../../../components/Spinner';
import GridContainer from '../../../components/Custom/Grid/GridContainer';
import GridItem from '../../../components/Custom/Grid/GridItem';

const useStyles = theme => ({
	button: {
		margin: theme.spacing(1),
	},
	searchbar: {
		margin: theme.spacing(2),
	},
	testMode: {
		backgroundColor: '#ffd67d',
	},	
	liveMode: {
		backgroundColor: '#a4cc89',
	},
	tabs: { "& .MuiTabs-indicator": { backgroundColor: theme.palette.primary.main, height: 3 }, },
	tab: { "&.MuiButtonBase-root.MuiTab-root": { backgroundColor: "#FFF", color:"#000" }, }
});
  
function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}
  

class BlockComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      tabIndex: 0
    };

	this.handleTabChange = this.handleTabChange.bind(this);
	this.handleTabChangeIndex = this.handleTabChangeIndex.bind(this);
  }

  handleTabChange(event, newValue) {
    this.setState({ tabIndex: newValue });
  }

  handleTabChangeIndex(index) {
    this.setState({ tabIndex: index });
  }

  componentDidMount() {
  }

  render() {
    const { classes, merchantId } = this.props; 
    const { tabIndex } = this.state;
    
    const tabIndicatorProps  = isMobile ? {} : { style: { backgroundColor: "#0D47A1", width: "8px", borderRadius: "8px" } }

    return (
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }} >
            <GridContainer>
            <GridItem xs={12} sm={12} md={3} lg={2}>
                <Tabs
                    orientation={isMobile ? "horizontal" : "vertical"}
                    variant="scrollable"
                    value={tabIndex}
                    onChange={this.handleTabChange}
                    TabIndicatorProps={tabIndicatorProps}
                    indicatorColor="primary"
                    textColor="inherit"
                    aria-label="Whitelist tabs"
                    className={classes.tabs}
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    <Tab icon={<CreditCardOffIcon />} iconPosition="start" label="Card" {...a11yProps(1)} />
                    <Tab icon={<UnsubscribeIcon />} iconPosition="start" label="Email" {...a11yProps(2)} />
                    <Tab icon={<GpsOffIcon />} iconPosition="start" label="IP Address" {...a11yProps(3)} />
                    <Tab icon={<PublicOffIcon />} iconPosition="start" label="Country" {...a11yProps(4)} />
                </Tabs>
            </GridItem>
            <GridItem xs={12} sm={12} md={9} lg={10}>
                <SwipeableViews
                    axis={"x"}
                    index={tabIndex}
                    onChangeIndex={this.handleTabChangeIndex} >
                    <CustomTabPanel value={tabIndex} index={0}>                    
                    {merchantId == null ? <Spinner /> : <CardBlockComponent merchantId={merchantId} />}
                    </CustomTabPanel>
                    <CustomTabPanel value={tabIndex} index={1}>
                    {merchantId == null ? <Spinner /> : <EmailBlockComponent merchantId={merchantId} />}
                    </CustomTabPanel>
                    <CustomTabPanel value={tabIndex} index={2}>
                    {merchantId == null ? <Spinner /> : <IPBlockComponent merchantId={merchantId} />}
                    </CustomTabPanel>
                    <CustomTabPanel value={tabIndex} index={3}>
                    {merchantId == null ? <Spinner /> : <CountryBlockComponent merchantId={merchantId} />}
                    </CustomTabPanel>
                </SwipeableViews>
            </GridItem>
            </GridContainer>
        </Box>
    );
  }
}

export default withStyles(useStyles)(BlockComponent)
