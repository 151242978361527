import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography';
import InputAdornment from "@material-ui/core/InputAdornment";
import { AddOutlined, Save, Delete } from "@material-ui/icons";

import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'

import CustomInput from '../../../components/Custom/CustomInput/CustomInput';
import Card from '../../../components/Custom/Card/Card'
import CardHeader from '../../../components/Custom/Card/CardHeader'
import CardBody from '../../../components/Custom/Card/CardBody'
import Button from '../../../components/Custom/Button/Button';
import { Divider, Select, MenuItem, FormControl, InputLabel, FormControlLabel, FormHelperText, Checkbox } from '@material-ui/core';

import { Status } from "../../ApiService/ApiService";

import ObjectUtils from '../../../util/objectUtil';

const newProduct = {
	unitId: "",
	merchantProductsId: "",
	tax : "",
	taxAmt : "0.0",
	quantity: "",
	totalAmount: "",
	grandTotalAmount: "0.0"
};

const CalculateGrantTotal = (product) => {	
	const amt = ObjectUtils.multipleNumber(ObjectUtils.NumVal(product.totalAmount), ObjectUtils.NumVal(product.quantity));
	product.taxAmt = ObjectUtils.multipleNumber(ObjectUtils.divideNumber(amt, 100), ObjectUtils.NumVal(product.tax));
	product.grandTotalAmount = ObjectUtils.addNumber(amt, product.taxAmt);
	return product;
}

class InvoiceGenerateProduct extends Component {
	constructor(props) {
		super(props);
		this.state = {
			onChange: props.onChange,
			onDeleteProduct: props.onDeleteProduct,
			onProductChange: props.onProductChange,
			onAddProduct: props.onAddProduct,
			onProductValidation: props.onProductValidation,

			productList: (props.invoice && props.invoice.products) ? props.invoice.products : [],
			currentProductId: null,
			productToAdd: ObjectUtils.DeepClone(newProduct),
			productValidation: {},
			invoiceTotalTax: 0.0,
			invoiceTotalAmount: 0.0,
			invoiceTaxIncluded: false
		};
		this.onAddClick = this.onAddClick.bind(this);
		//this.onSaveClick = this.onSaveClick.bind(this);
		this.onTextChange = this.onTextChange.bind(this);
	}
		  
	componentDidMount()	{
		if (this.state.productList && this.state.productList.length > 0) {
			this.state.productList.map(p => CalculateGrantTotal(p));		
			this.calculateInvoiceAmountAndTax (this.props.refData, this.state.productList, this.state.invoiceTaxIncluded);
		}
	}

	onAddClick(refData) {
		const validObj = this.state.onProductValidation("_1", this.state.productToAdd);
		// console.log("validObj", validObj, this.state.productToAdd);
		if (validObj != null)	{
			this.setState({productValidation: validObj});
			return;
		}

		const product = ObjectUtils.DeepClone(this.state.productToAdd);
		const newListArr = [...this.state.productList, product];
		// console.log (newListArr);
		this.setState ({productToAdd: ObjectUtils.DeepClone(newProduct), productValidation: {}});
		this.calculateInvoiceAmountAndTax (refData, newListArr, this.state.invoiceTaxIncluded);
		this.state.onAddProduct(product);
	}

	getHelperText(index, fieldName, helperField) {
		return this.state.productValidation[`COMP${index}`] && this.state.productValidation[`COMP${index}`][fieldName] 
		? this.state.productValidation[`COMP${index}`][fieldName][helperField] : "";
	}

	onDeleteClick(refData, idx) {
		var newProductList = [...this.state.productList];
		newProductList.splice(idx, 1);
		this.calculateInvoiceAmountAndTax(refData, newProductList, this.state.invoiceTaxIncluded);
		this.state.onDeleteProduct(idx);
	}

	// onSaveClick(index) {
	// 	const data = {
	// 		unitId: this.state.productList[index].unitId,
	// 		merchantProductsId: this.state.productList[index].merchantProductsId,
	// 		tax: this.state.productList[index].tax,
	// 		quantity: this.state.productList[index].quantity,
	// 		totalAmount: this.state.productList[index].totalAmount,
	// 		grandTotalAmount: this.state.productList[index].totalAmount * this.state.productList[index].quantity
	// 	};
	// 	//save data api
	// }

	onTextChange(refData, index, name, value, shouldCalculateGrandTotal) {
		if (index === -1) {
			//console.log(name, value);
			let newProductToAdd = {...this.state.productToAdd};
			newProductToAdd[name] = value;
			if (shouldCalculateGrandTotal) {
				newProductToAdd = CalculateGrantTotal(newProductToAdd);
				this.calculateInvoiceAmountAndTax(refData, this.state.productList, this.state.invoiceTaxIncluded);
			}
			this.setState({productToAdd: newProductToAdd});
		}
		else {
			var newProductList = [...this.state.productList];
			newProductList[index][name] = value;
			newProductList.splice(index, 1, newProductList[index]);
			if (shouldCalculateGrandTotal) {
				newProductList[index] = CalculateGrantTotal(newProductList[index]);
				this.calculateInvoiceAmountAndTax(refData, this.state.productList, this.state.invoiceTaxIncluded);
			}
			this.setState({productList: newProductList});
			this.state.onProductChange(index, newProductList[index]);
		}
	}

	calculateInvoiceAmountAndTax (refData, productList, invoiceTaxIncluded) {
		let tax = 0;
		let amount = 0;
		if (!ObjectUtils.isNullObject(productList)) {
			productList.map((product) => {
				//console.log("--->", tax, product.taxAmt, ObjectUtils.NumVal(product.taxAmt));
				tax = ObjectUtils.addNumber(tax, ObjectUtils.NumVal(product.taxAmt));
				amount = ObjectUtils.addNumber(amount, ObjectUtils.NumVal(product.grandTotalAmount));
			})
		}
		if (invoiceTaxIncluded) {
			const configTax = 5;
			tax = ObjectUtils.addNumber(tax, ObjectUtils.multipleNumber(ObjectUtils.divideNumber(amount, 100), ObjectUtils.NumVal(configTax)));
			amount = ObjectUtils.addNumber(amount, tax);
		}
		this.setState({productList: productList, invoiceTotalTax: tax, invoiceTotalAmount: amount});


		//console.log("refData.invoiceConfig", refData.invoiceConfig);

		let dueDateAmount = 0;
		if (refData && refData.invoiceConfig && refData.invoiceConfig.lateFeeApplicable === true) {
			let configLateFee = ObjectUtils.NumVal(refData.invoiceConfig.lateFee);
			if (configLateFee > 0) {
				let lateFee = 0;
				if ("PCNT" === refData.invoiceConfig.lateFeeType) {
					lateFee = ObjectUtils.divideNumber(ObjectUtils.multipleNumber(amount, ObjectUtils.NumVal(configLateFee)), 100);
				}
				else {
					lateFee = configLateFee; 
				}
				// console.log("---> ", lateFee, amount);
				dueDateAmount = ObjectUtils.addNumber(lateFee, amount);
			}
		}
		this.state.onChange({dueDateAmount : dueDateAmount, taxAmount: tax, totalAmount: amount});
	}

	renderItem(refData, item, idx) {

		const compIdx = idx === -1 ? "_1" : idx;

		return (<>
		<GridItem xs={12} sm={12} md={3} lg={3}>
			<FormControl fullWidth error={!ObjectUtils.isNullorEmpty(this.getHelperText(compIdx, "merchantProductsId", "helperText"))}>
				<InputLabel id="product-select-label">Product</InputLabel>
				<Select style={{minWidth:"150px", marginTop: "30px", width: "100%"}}
					labelId="product-select-label"
					id="product-type-select"
					name="merchantProductsId"
					value={item.merchantProductsId}
					label="Product"
					onChange = {(e) => this.onTextChange(refData, idx, e.target.name, e.target.value, false)}
				>
					{refData.productList.map((item, idx) => {
						return <MenuItem key={idx} value={item.id}>{item.productName}-{item.productDescription}</MenuItem>;
					})}
				</Select>
				<FormHelperText>{this.getHelperText(compIdx, "merchantProductsId", "helperText")}</FormHelperText>
			</FormControl>
		</GridItem>
		<GridItem xs={12} sm={12} md={3} lg={2}>
			<FormControl fullWidth error={!ObjectUtils.isNullorEmpty(this.getHelperText(compIdx, "unitId", "helperText"))}>
				<InputLabel id="unit-select-label">Unit</InputLabel>
				<Select style={{minWidth:"150px", marginTop: "30px", width: "100%"}}
					labelId="unit-select-label"
					id="unit-type-select"
					name="unitId"
					value={item.unitId}
					label="unitId"
					onChange = {(e) => this.onTextChange(refData, idx, e.target.name, e.target.value, false)}
				>
					{refData.unitList.map((item, idx) => {
						return <MenuItem key={idx} value={item.id}>{item.unitName}</MenuItem>;
					})}
				</Select>
				<FormHelperText>{this.getHelperText(compIdx, "unitId", "helperText")}</FormHelperText>
			</FormControl>
		</GridItem>
		<GridItem xs={12} sm={12} md={4} lg={1}>
			<CustomInput
				title="Amount"
				field="totalAmount"
				formControlProps={{
					fullWidth: true,
				}}
				inputProps={{
					value: item.totalAmount,
					name: "totalAmount",
					id: "totalAmount",
					onChange: (e) => this.onTextChange(refData, idx, e.target.name, e.target.value, true)
				}}
				helperType={this.getHelperText(compIdx, "totalAmount", "helperType") }
				helperText={this.getHelperText(compIdx, "totalAmount", "helperText") }
			/>
			</GridItem>
			<GridItem xs={8} sm={12} md={2} lg={1}>
				<CustomInput
					title="Quantity"
					field="quantity"
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: item.quantity,
						name: "quantity",
						id: "quantity",
						onChange: (e) => this.onTextChange(refData, idx, e.target.name, e.target.value, true)
					}}
					helperType={this.getHelperText(compIdx, "quantity", "helperType") }
					helperText={this.getHelperText(compIdx, "quantity", "helperText") }
				/>
			</GridItem>
			<GridItem xs={4} sm={4} md={3} lg={1}>
				<CustomInput
					title="Tax (%)"
					field="tax"
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: item.tax,
						name: "tax",
						id: "tax",
						onChange: (e) => this.onTextChange(refData, idx, e.target.name, e.target.value, true)
					}}
					helperType={this.getHelperText(compIdx, "tax", "helperType") }
					helperText={this.getHelperText(compIdx, "tax", "helperText") }
				/>
			</GridItem>
			<GridItem xs={4} sm={4} md={4} lg={2} style={{ marginTop:"10px" }} >
				<Typography variant="body1" weight="light">Total</Typography>
				<Typography variant="subtitle2" weight="bold">{item.grandTotalAmount}</Typography>
			</GridItem>
		</>);
	}

	render() {
		const { invoice, refData } = this.props;
		const { productToAdd, productList, invoiceTotalTax, invoiceTotalAmount, invoiceTaxIncluded } = this.state;
		// console.log ("Generate productList", invoice, productList);

		return <Card color="white">
				<CardBody>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<Typography variant="h5">Product Details</Typography>
						</GridItem>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<GridContainer>
								{this.renderItem(refData, productToAdd, -1)}
								<GridItem xs={4} sm={4} md={4} lg={2} style={{marginTop: "18px"}} >
									<Button color="primary" size="sm" onClick={() => this.onAddClick(refData)} toolTipTitle="Add Unit">
										<AddOutlined /> Add
									</Button>
								</GridItem>
							</GridContainer>
						</GridItem>
						{!ObjectUtils.isNullObject(productList) > 0 && <GridItem><Divider light/></GridItem>}
						{productList.map((product, idx) => (
							<React.Fragment key={product.id || idx}>
								{this.renderItem(refData, product, idx)}
								<GridItem xs={2} sm={2} md={2} lg={1}>
									<Button color="white" size="lg" justIcon round 
										onClick={() => this.onDeleteClick(refData, idx)} toolTipTitle="Delete">
										<Delete style={{color: "#FF0000"}}/>
									</Button>
								</GridItem>
							</React.Fragment>
						))}
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<GridContainer>
								<GridItem xs={1} sm={1} md={7} lg={8}></GridItem>
								<GridItem xs={11} sm={11} md={5} lg={4}>
									<GridContainer>
										<GridItem xs={12} sm={12} md={12} lg={12}>
											<FormControlLabel control={
												<Checkbox checked={invoiceTaxIncluded} onChange={() => {
													this.calculateInvoiceAmountAndTax(refData, productList, !invoiceTaxIncluded);
													this.setState({invoiceTaxIncluded: !invoiceTaxIncluded});
													}
												} />
											} label={`Apply GST/VAT on Total Amount (${refData.invoiceConfig.tax}%) ?` }/>
										</GridItem>	
										<GridItem xs={6} sm={6} md={4} lg={4}>
											<Typography variant="body1" weight="light">Tax Amount</Typography>
										</GridItem>	
										<GridItem xs={6} sm={6} md={8} lg={8}>
											<Typography variant="h6" weight="bold">{invoiceTotalTax}</Typography>
										</GridItem>	
										<GridItem xs={6} sm={6} md={8} lg={4}>
											<Typography variant="h6" weight="bold">Grand Total</Typography>
										</GridItem>	
										<GridItem xs={6} sm={6} md={8} lg={8}>
											<Typography variant="h6" weight="bold">{invoiceTotalAmount}</Typography>
										</GridItem>	
									</GridContainer>
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		}
}

export default InvoiceGenerateProduct;