import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography';
import InputAdornment from "@material-ui/core/InputAdornment";
import { LanguageOutlined } from "@material-ui/icons";

import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'
import ObjectUtils from '../../../util/objectUtil';

import CustomInput from '../../../components/Custom/CustomInput/CustomInput';
import Card from '../../../components/Custom/Card/Card'
import CardHeader from '../../../components/Custom/Card/CardHeader'
import CardBody from '../../../components/Custom/Card/CardBody'
import { Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText, OutlinedInput } from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = { PaperProps: { style: {maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250, }, },};

class InvoiceGenerateDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	render() {
		const { invoice, onChange, onTerminalChange, refData } = this.props;
		let currencyList = refData.terminalList.filter((value, index, self) => 
			self.findIndex((m) => m.terminalCurrency === value.terminalCurrency) === index);
		if (currencyList == null) currencyList = [];
		//console.log("currencyList", currencyList);

		//console.log("Invoice ", invoice);
		return <Card color="white">
				<CardBody>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<Typography variant="h5">Invoice Details</Typography>
						</GridItem>
						<GridItem xs={12} sm={12} md={5} lg={5}>
							<CustomInput
								title="Order Description"
								field="description"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									defaultValue: invoice.description,
									name: "description",
									id: "description",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"Order Description"}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={3} lg={3}>
							<FormControl fullWidth>
								<InputLabel id="ccy-select-label" style={{marginTop: "10px"}}>Select Currency</InputLabel>
								<Select style={{minWidth:"150px", marginTop: "30px", width: "100%"}}
									labelId="ccy-select-label"
									id="ccy-type-select"
									name="currency"
									value={invoice.currency}
									label="Currency"
									onChange={(e) => onChange(e.target.name, e.target.value)}
									>
									{currencyList.map((ccyItem, idx) => { 
										return <MenuItem key={idx} value={ccyItem.terminalCurrency}>{ccyItem.terminalCurrency}</MenuItem>; 
									})}
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} lg={4}>
							<FormControl fullWidth>
								<InputLabel id="terminal-select-label" style={{marginTop: "10px"}}>Select Terminal</InputLabel>
								<Select style={{minWidth:"150px", marginTop: "30px", width: "100%"}}
									labelId="terminal-select-label"
									id="terminal-type-select"
									multiple
									name="terminals"
									value={invoice.terminals}
									MenuProps={MenuProps}
									renderValue={(selected) => {
										// console.log(selected);
										if (selected.length > 1) {
											return "Multiple Terminals Selected";
										}
										const terminalNames = [];
										const selectedItems = "," + selected.join(",") + ",";
										refData.terminalList.map((terminal, idx) => {
											if (selectedItems.indexOf(terminal.terminalId) > -1) 
												terminalNames.push(terminal.label);
										});
										return terminalNames.join(', ');
									}}
									onChange={(e) => onTerminalChange(e.target.name, e.target.value)}
									>
									{refData.terminalList.map((terminal, idx) => {
										return <MenuItem key={idx} value={terminal.terminalId}>
											<Checkbox checked={
												!ObjectUtils.isNullorEmpty(invoice.terminals) && 
												invoice.terminals.indexOf(terminal.terminalId) > -1} />
											<ListItemText primary={terminal.label} />											
											</MenuItem>
									})}
								</Select>
							</FormControl>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		}
}

export default InvoiceGenerateDetails;