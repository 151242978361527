import React, { Component } from 'react'

import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import InputAdornment from "@material-ui/core/InputAdornment";

import GridContainer from '../../../components/Custom/Grid/GridContainer'
import GridItem from '../../../components/Custom/Grid/GridItem'

import CustomInput from '../../../components/Custom/CustomInput/CustomInput';
import Card from '../../../components/Custom/Card/Card'
import CardHeader from '../../../components/Custom/Card/CardHeader'
import CardBody from '../../../components/Custom/Card/CardBody'
import Button from '../../../components/Custom/Button/Button';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

class InvoiceGenerateConfigurationDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	render() {
		const { invoice, onChange, refData } = this.props;
		//console.log("invoice ", invoice);
		return <Card color="white">
				<CardBody>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom: "15px"}}>
							<Typography variant="h5">Configuration Details</Typography>
						</GridItem>
						{/* <GridItem xs={12} sm={12} md={4} lg={3}>
							<CustomInput
								title="Invoice expiry after* (Days)"
								field="expirationPeriodDays"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									defaultValue: invoice.expirationPeriodDays,
									name: "expirationPeriodDays",
									id: "expirationPeriodDays",
									onChange: (e) => onChange(e.target.name, e.target.value),
								}}
								helperText={"Expiration Period Days"}
							/>
						</GridItem> */}
						<GridItem xs={12} sm={12} md={4} lg={3}>
							<Typography variant="h6" weight="bold">Invoice expiry after* (Days)</Typography>
							<Typography variant="body1" weight="light">{refData.invoiceConfig.dueDays}</Typography>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} lg={3}>
							<Typography variant="h6" weight="bold">Due Date</Typography>
							<Typography variant="body1" weight="light">{ moment(invoice.dueDate).format("YYYY-MM-DD")}</Typography>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} lg={3}>
							{/* <Typography variant="h6" weight="bold">Redirect URL</Typography>
							<Typography variant="body1" weight="light">{refData.invoiceConfig.redirectUrl}</Typography> */}
						</GridItem>						
					</GridContainer>
				</CardBody>
			</Card>
		}
}

export default InvoiceGenerateConfigurationDetails;